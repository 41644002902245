import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { QUIZ_SENTENCES } from '../../constants';
import { HOLOPLAYER_PATH } from '../../route/route';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ReactComponent as SkullIcon } from '../../img/skull.svg';
import { ReactComponent as OnigiriIcon } from '../../img/onigiri.svg';
import { ReactComponent as YubiMiniIcon } from '../../img/yubi_mini.svg';
import SportsEsportsRoundedIcon from '@mui/icons-material/SportsEsportsRounded';

const Quiz = () => {
    const theme = useTheme();
    const fill = theme.palette.text.primary;
    return (
        <>
            <Box sx={{ m: 2, textAlign: 'center' }}>
                <SportsEsportsRoundedIcon />
                <Typography color={theme.palette.text.secondary}>
                    {QUIZ_SENTENCES.INDEX.LEAD}
                </Typography>
            </Box>
            <List>
                <Divider />
                <ListItem button component={Link} to={HOLOPLAYER_PATH.QUIZ_AENBIEN}>
                    <ListItemIcon
                        sx={{
                            minWidth: 40,
                        }}
                    >
                        <SkullIcon width={24} height={24} fill={fill} />
                    </ListItemIcon>
                    <ListItemText
                        primary={QUIZ_SENTENCES.INDEX.TITLE.AENBIEN}
                        secondary={QUIZ_SENTENCES.INDEX.DESCRIPSION.AENBIEN}
                    />
                    <NavigateNextIcon />
                </ListItem>
                <Divider />
                <ListItem button component={Link} to={HOLOPLAYER_PATH.QUIZ_YUBIYUBI}>
                    <ListItemIcon
                        sx={{
                            minWidth: 40,
                        }}
                    >
                        <YubiMiniIcon width={24} height={24} fill={fill} />
                    </ListItemIcon>
                    <ListItemText
                        primary={QUIZ_SENTENCES.INDEX.TITLE.YUBIYUBI}
                        secondary={QUIZ_SENTENCES.INDEX.DESCRIPSION.YUBIYUBI}
                    />
                    <NavigateNextIcon />
                </ListItem>
                <Divider />
                <ListItem button component={Link} to={HOLOPLAYER_PATH.QUIZ_ZENKOUTEI}>
                    <ListItemIcon
                        sx={{
                            minWidth: 40,
                        }}
                    >
                        <OnigiriIcon width={24} height={24} fill={fill} />
                    </ListItemIcon>
                    <ListItemText
                        primary={QUIZ_SENTENCES.INDEX.TITLE.ZENKOUTEI}
                        secondary={QUIZ_SENTENCES.INDEX.DESCRIPSION.ZENKOUTEI}
                    />
                    <NavigateNextIcon />
                </ListItem>
                <Divider />
            </List>
        </>
    );
};

export default Quiz;
