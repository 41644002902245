import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import FullscreenExitRoundedIcon from '@mui/icons-material/FullscreenExitRounded';

import Handle from '../../ui/BottomSheet/Handle';
import { Box, IconButton } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
const SongSheetHandle = (props: {
    isFull: boolean;
    doExitFullscreen: () => void;
    doFullscreen: () => void;
    doClose: () => void;
    draggable: boolean;
}) => {
    const { isFull, doExitFullscreen, doFullscreen, doClose, draggable } = props;
    const song = useAppSelector((state) => state.song.current.song);
    return (
        <Handle draggable={draggable}>
            <Box
                sx={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    flexGrow: 1,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                }}
            >
                {song?.title}
            </Box>
            {isFull ? (
                <IconButton
                    onClick={() => {
                        doExitFullscreen();
                    }}
                >
                    <FullscreenExitRoundedIcon />
                </IconButton>
            ) : (
                <IconButton
                    onClick={() => {
                        doFullscreen();
                    }}
                >
                    <FullscreenRoundedIcon />
                </IconButton>
            )}

            <IconButton
                onClick={() => {
                    doClose();
                }}
            >
                <CloseRoundedIcon />
            </IconButton>
        </Handle>
    );
};

export default SongSheetHandle;
