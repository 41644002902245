import React from 'react';
import Login from '../ui/Login';
import { useAppSelector } from '../../app/hooks';
import { Box } from '@mui/material';

import Logined from './Logined';

const Account = () => {
    const account = useAppSelector((state) => state.account);
    return (
        <>
            {account.isLogin ? (
                <Logined />
            ) : (
                <Box sx={{ padding: '24px', textAlign: 'center' }}>
                    <Login />
                </Box>
            )}
        </>
    );
};

export default Account;
