import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UnitItemType } from '../../type/hololive.types';

const initialState: {
    isLoaded: boolean;
    hasError: boolean;
    items: UnitItemType[];
    loadStatus: 'ready' | 'loading' | 'loaded';
    computeStatus: 'ready' | 'computing' | 'computed';
} = {
    isLoaded: false,
    hasError: false,
    loadStatus: 'ready',
    computeStatus: 'ready',
    items: [],
};

const unitSlice = createSlice({
    name: 'unit',
    initialState,
    reducers: {
        startLoadUnit: (state, action: PayloadAction) => {
            state.loadStatus = 'loading';
        },
        completeLoadUnit: (state, action: PayloadAction<UnitItemType[]>) => {
            state.isLoaded = true;
            state.loadStatus = 'loaded';
            const items = action.payload.map((item): UnitItemType => {
                return {
                    ...item,
                    songs: {
                        status: 'ready',
                    },
                };
            });
            state.items = [...items];
        },
        startComputeSongCount: (state, action: PayloadAction) => {
            state.computeStatus = 'computing';
        },
        completecComputeSongCount: (state, action: PayloadAction<UnitItemType[]>) => {
            if (state.computeStatus !== 'computed') {
                state.computeStatus = 'computed';
                state.items = [...action.payload];
            }
        },
        startFetchPeronSong: (state, action: PayloadAction<number>) => {
            const unitId = action.payload;
            const unit = state.items.find((item) => item.id === unitId);
            if (unit) {
                unit.songs = {
                    status: 'loading',
                };
            }
        },
        completeFetchUnitSong: (
            state,
            action: PayloadAction<{
                unit: UnitItemType;
                songs: UnitItemType['songs'];
            }>,
        ) => {
            const { unit, songs } = action.payload;
            const currentTalent = state.items.find((item) => item.id === unit.id);
            if (!currentTalent) return;
            currentTalent.songs = songs;
        },
    },
});

export const {
    startLoadUnit,
    completeLoadUnit,
    startComputeSongCount,
    completecComputeSongCount,
    startFetchPeronSong,
    completeFetchUnitSong,
} = unitSlice.actions;

export default unitSlice.reducer;
