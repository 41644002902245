import { Avatar, Box, Button, Divider, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { shuffleArray } from '../../../lib/misc';
import { answer, doPause, doPlay, firstPlay } from '../../../slice/quiz/quizSlice';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import useQuiz from '../../../slice/quiz/useQuiz';
import { QUIZ_SENTENCES } from '../../../constants';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DoDisturbAltRoundedIcon from '@mui/icons-material/DoDisturbAltRounded';
import YubiyubiDisplay from './YubiyubiDisplay';

const Question = () => {
    const song = useAppSelector((state) => state.quiz.current.song);
    const player = useAppSelector((state) => state.quiz.player);
    const playlist = useAppSelector((state) => state.quiz.current.playlist);
    const dispatch = useAppDispatch();
    const Quiz = useQuiz();
    const theme = useTheme();
    const counter = Quiz.GetQuizStatus();

    const mode_description = useMemo(() => {
        const mode = Quiz.current.mode;
        const obj = QUIZ_SENTENCES.QUESTION_SHEET.DESCRIPTION;
        if (mode === 'aenbien') return obj.AENBIEN;
        if (mode === 'yubiyubi') return obj.YUBIYUBI;
        if (mode === 'zenkoutei') return obj.ZENKOUTEI;
        return 'error';
    }, [Quiz]);

    const choices = useMemo(() => {
        return shuffleArray([
            song?.title || '',
            song?.quiz?.[0] || '',
            song?.quiz?.[1] || '',
            song?.quiz?.[2] || '',
        ]);
    }, [song?.title, song?.quiz]);

    if (!song) {
        return <Box></Box>;
    }

    const handleAnswer = (choice: string) => {
        if (song.title === choice) {
            dispatch(answer({ song, correct: 'correct', answer: choice }));
        } else {
            dispatch(answer({ song, correct: 'incorrect', answer: choice }));
        }
    };

    const PlayButton = () => {
        return (
            <Button
                startIcon={<PlayArrowRoundedIcon />}
                onClick={() => {
                    if (!Quiz.hadPlayed) {
                        dispatch(firstPlay());
                    }
                    dispatch(doPlay());
                }}
                variant="contained"
                color="error"
            >
                再生
            </Button>
        );
    };
    const PauseButton = () => {
        return (
            <Button
                startIcon={<PauseRoundedIcon />}
                onClick={() => {
                    dispatch(doPause());
                }}
                variant="outlined"
            >
                停止
            </Button>
        );
    };

    const Heading = () => {
        if (counter.correct.length > 1 && counter.done === counter.correct.length) {
            return `${counter.done}問連続正解中！`;
        }
        if (counter.correct.length > 0) {
            return `${counter.correct.length}問正解中！`;
        }
        return '流れている曲はどれ？';
    };

    const PrevSong = () => {
        const song = Quiz.GetBeforeQuiz();
        if (!song) return <></>;
        return (
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        backgroundColor: theme.palette.background.paper,
                        padding: '8px 10px',
                        borderRadius: '8px',
                        alignItems: 'center',
                        margin: '0 0 12px 0',
                    }}
                >
                    <Avatar
                        src={song.origin.thumbnail_url || ''}
                        variant="rounded"
                        sx={{ swidth: 40, height: 40, flexShrink: 0 }}
                    />
                    <Box sx={{ dispaly: 'flex', lineHeight: 1, flexGrow: 1, margin: '4px 0' }}>
                        <Box sx={{ marginBottom: '4px' }}>{song.origin.title}</Box>
                        <Box sx={{ fontSize: '12px', color: theme.palette.text.secondary }}>
                            {song.origin.artist?.name}
                        </Box>
                    </Box>
                    <Box sx={{ margin: '4px 4px 0 0' }}>
                        {song.correct === 'correct' ? (
                            <CheckCircleRoundedIcon color="success" />
                        ) : (
                            <DoDisturbAltRoundedIcon color="error" />
                        )}
                    </Box>
                </Box>
                <Divider sx={{ margin: '0 0 8px' }} />
            </Box>
        );
    };

    return (
        <Box sx={{ m: 2, overflow: 'auto' }}>
            <PrevSong />
            <Box
                sx={{
                    display: 'flex',
                    opacity: 0.6,
                    fontSize: '12px',
                    justifyContent: 'space-between',
                }}
            >
                <div>{mode_description}</div>
                <div>
                    {counter.current}問目 / 残り{playlist.length - counter.done}問
                </div>
            </Box>
            <Typography variant="h5" sx={{ m: '16px 0', textAlign: 'center' }}>
                {Heading()}
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', m: '16px 0' }}>
                {player.playing ? <PauseButton /> : <PlayButton />}
            </Box>
            <Box>{Quiz.current.mode === 'yubiyubi' && <YubiyubiDisplay />}</Box>

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {choices.map((choice, index) => (
                    <Button
                        key={index}
                        variant="contained"
                        color="info"
                        sx={{ m: '8px 0', textTransform: 'none' }}
                        onClick={() => {
                            handleAnswer(choice);
                        }}
                    >
                        {choice}
                    </Button>
                ))}
            </Box>
        </Box>
    );
};
export default Question;
