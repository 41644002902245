import { Box, Typography, useTheme } from '@mui/material';
import { getRandomFromArray } from '../../lib/misc';

const TextArray = [
    '余、表示まっしろ。',
    '余、なんも聞いとらんかった',
    'ゼロなのかッ！？って！',
    'ノットファウンヅ',
    '表示できるものはゼロのようだった。ゼロ？ゼロって・・・。一瞬記憶が錯乱するが、ゼロはゼロだ。',
    '表示冷えちゃった',
    'ナッシング太郎',
    'ゼロ件なんだワ',
    'しけてんねぇ',
    // 'あ〜あ',
    // 'じゃあ敵だね？',
];

const NoContent = (): JSX.Element => {
    const theme = useTheme();
    const text = getRandomFromArray(TextArray);
    return (
        <Box
            sx={{
                m: 2,
                padding: '40px 0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%',
                flexGrow: 1,
            }}
        >
            {/* <Box sx={{ width: '64px' }}>
                <SplashLogo />
            </Box> */}
            <Typography variant="subtitle1" color={theme.palette.text.primary}>
                {text}
            </Typography>
            <Typography variant="caption" color={theme.palette.text.secondary} sx={{ m: 1 }}>
                表示できるものがありません
            </Typography>
        </Box>
    );
};

export default NoContent;
