import React from 'react';
import { Button } from '@mui/material';
import useAccount from '../../lib/useAccount';

function Logout() {
    const Account = useAccount();
    const handleClick = () => {
        Account.logOut();
    };

    return (
        <Button variant="contained" onClick={handleClick}>
            ログアウト
        </Button>
    );
}

export default Logout;
