import {
    AlbumItemType,
    GenerationItemType,
    PersonItemType,
    SongCollectionFilterByCreatedIdType,
    SongItemType,
    TalentItemType,
    UnitItemType,
} from '../type/hololive.types';
import {
    axiosAlbum,
    axiosGeneration,
    axiosPerson,
    axiosSong,
    axiosSongFilterByCreator,
    axiosTalent,
    axiosUnit,
} from '../app/api';
import { parseSongCollection } from './parseSongCollection';

export const fetchAllSongFilterByCreator = async (
    created_by: number,
): Promise<SongCollectionFilterByCreatedIdType> => {
    return axiosSongFilterByCreator(created_by, {
        posts_per_page: -1,
    }).then((done) => {
        return {
            all: parseSongCollection(done.data.all.posts),
            composer: parseSongCollection(done.data.composer.posts),
            arranger: parseSongCollection(done.data.arranger.posts),
            lyricist: parseSongCollection(done.data.lyricist.posts),
            talent: parseSongCollection(done.data.talent.posts),
        };
    });
};

export const fetchAllSongFilterByUnit = async (unit: number): Promise<SongItemType[]> => {
    return axiosSong({
        unit,
        posts_per_page: -1,
    }).then((done) => {
        return parseSongCollection(done.data.posts);
    });
};

export const fetchAllSongFilterByGeneration = async (
    generation: number,
): Promise<SongItemType[]> => {
    return axiosSong({
        generation,
        posts_per_page: -1,
    }).then((done) => {
        return parseSongCollection(done.data.posts);
    });
};

export const fetchAllPerson = async (): Promise<PersonItemType[] | null> => {
    return axiosPerson({
        posts_per_page: -1,
    }).then((res) => {
        if (res.status === 200) {
            return res.data.posts;
        }
        return null;
    });
};

export const fetchAllTalent = async (): Promise<TalentItemType[] | null> => {
    return axiosTalent({
        posts_per_page: -1,
    }).then((res) => {
        if (res.status === 200) {
            return res.data.posts;
        }
        return null;
    });
};

export const fetchAllAlbum = async (): Promise<AlbumItemType[] | null> => {
    return axiosAlbum({
        posts_per_page: -1,
    }).then((res) => {
        if (res.status === 200) {
            return res.data.posts;
        }
        return null;
    });
};

export const fetchAllUnit = async (): Promise<UnitItemType[] | null> => {
    return axiosUnit({
        posts_per_page: -1,
    }).then((res) => {
        if (res.status === 200) {
            return res.data.posts;
        }
        return null;
    });
};

export const fetchAllGeneration = async (): Promise<GenerationItemType[] | null> => {
    return axiosGeneration({
        posts_per_page: -1,
    }).then((res) => {
        if (res.status === 200) {
            return res.data.posts;
        }
        return null;
    });
};

export const fetchAllSong = async (): Promise<SongItemType[] | null> => {
    return await axiosSong({
        posts_per_page: -1,
    }).then((res) => {
        if (res.status === 200) {
            return parseSongCollection(res.data.posts);
        }
        return null;
    });
};

export const fetchData = async (): Promise<{
    song: SongItemType[];
    talent: TalentItemType[];
    generation: GenerationItemType[];
} | null> => {
    return await Promise.all([
        axiosSong({
            posts_per_page: -1,
        }),
        axiosTalent({
            posts_per_page: -1,
        }),
        axiosGeneration(),
    ]).then((values) => {
        const is_valid_status =
            values[0]?.status === 200 && values[1]?.status === 200 && values[2]?.status === 200;
        if (!is_valid_status) {
            return null;
        }
        const song = parseSongCollection(values[0].data.posts);
        const talent = values[1].data.posts;
        const generation = values[2].data.posts;
        return {
            song,
            talent,
            generation,
        };
    });
};
