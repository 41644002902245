import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type IsOpenDialogType = boolean;
export type DialogState = {
    incompleteFunctionDialog: IsOpenDialogType;
};

const initialState: DialogState = {
    incompleteFunctionDialog: false,
};

const dialogSlice = createSlice({
    name: 'dialog',
    initialState,
    reducers: {
        setIncompleteFunctionDialog: (state, action: PayloadAction<IsOpenDialogType>) => {
            state.incompleteFunctionDialog = action.payload;
        },
    },
});

export const { setIncompleteFunctionDialog } = dialogSlice.actions;

export default dialogSlice.reducer;
