import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const ResultItem = ({ title, answer }: { title: string; answer: string | null }) => {
    return (
        <ListItem>
            <ListItemIcon color="error">
                {title === answer ? (
                    <CheckCircleRoundedIcon color="success" />
                ) : (
                    <CancelRoundedIcon color="error" />
                )}
            </ListItemIcon>
            <ListItemText primary={title} secondary={answer} />
        </ListItem>
    );
};
export default ResultItem;
