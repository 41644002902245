import { GoogleAuthProvider, User, UserCredential, signOut } from 'firebase/auth';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { loadStart, loaded, setLogIn, setLogOut } from '../slice/accountSlice';
import { auth } from '../app/firebase';

const useAccount = () => {
    const dispatch = useAppDispatch();
    const account = useAppSelector((state) => state.account);

    const logIn = (result: UserCredential) => {
        console.log(result);
        const user = result.user;
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken;
        dispatch(
            setLogIn({
                uid: user.uid,
                photoURL: user.photoURL,
                displayName: user.displayName,
                token,
            }),
        );
        dispatch(loaded());
    };

    const continueSession = async (user: User) => {
        // const id_token = await user.getIdToken();
        // const token = GoogleAuthProvider.credential(id_token);
        dispatch(
            setLogIn({
                uid: user.uid,
                photoURL: user.photoURL,
                displayName: user.displayName,
                token: undefined,
            }),
        );
        dispatch(loaded());
    };

    const continueGuest = () => {
        dispatch(loaded());
    };

    const logOut = () => {
        signOut(auth);
        dispatch(setLogOut());
        dispatch(loadStart());
    };

    return {
        account,
        logIn,
        logOut,
        continueSession,
        continueGuest,
    };
};

export default useAccount;
