import dayjs from 'dayjs';
import { SongApiResponseItemType, SongItemType, TalentItemType } from '../type/hololive.types';

const CustomArrayJoin = (val: string[] | string | null | undefined): string | null => {
    if (val === null) return null;

    if (Array.isArray(val)) {
        const arr = val.filter((_) => _);
        if (arr.length) {
            return arr.join('\n');
        }
    } else if (val?.length) {
        return val;
    }

    return null;
};

const talentNameJoin = (talentCollection: TalentItemType[] | null): string | null => {
    if (talentCollection === null) return null;
    return talentCollection
        .map((talent) => {
            return [talent.jp_formal, talent.jp_kana, talent.en_formal, talent.en_kebab].join('\n');
        })
        .join('\n');
};

const getSearchAll = (data: SongApiResponseItemType): string | null => {
    const fields = [
        data.title,
        data.video_id.mv,
        data.video_id.topic,
        data.artist?.name,
        data.composer?.map((_) => _.name),
        data.arranger?.map((_) => _.name),
        data.lyricist?.map((_) => _.name),
        data.guest?.map((_) => _.name),
        data.lyric,
        talentNameJoin(data.talent),
    ]
        .map((item) => CustomArrayJoin(item))
        .filter((_) => _ !== null) as string[];

    return CustomArrayJoin(fields);
};

const getPublishedDayjs = (str: string | null): dayjs.Dayjs | null => {
    if (str === null) return null;

    const re = /^(\d\d\d\d)\/?(\d?\d)\/?(\d?\d)$/;
    const _ = String(str).match(re);

    let date = null;
    if (_?.length === 4) {
        date = `${_[1]}/${_[2]}/${_[3]}`;
    }

    let dayjs_date;
    if (date) {
        dayjs_date = dayjs(date, 'YYYY/MM/DD', 'Asia/Tokyo');
    }

    if (dayjs_date?.isValid()) {
        return dayjs_date;
    } else {
        console.error(str);
    }

    return null;
};

export const parseSongCollection = (songCollection: SongApiResponseItemType[]): SongItemType[] => {
    return songCollection.map((data) => {
        const _published = getPublishedDayjs(data.published_raw);
        return {
            ...data,
            search_all: getSearchAll(data),
            search_talent: talentNameJoin(data.talent),
            published_unix: _published?.unix() || null,
            published_YYYYMMDD: _published?.format('YYYY/MM/DD') || null,
        };
    });
};
