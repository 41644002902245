import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { SongItemType } from '../../../type/hololive.types';
import NavigationBar from '../../ui/NavigationBar/NavigationBar';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { FilterByQuery, SortSongCollection } from '../../../lib/songCollectionFilterAndSorter';
import CustomFixedSizeList from './CustomFixedSizeListForSongCollection';
import { CreatorFilterType } from './CreatorFilter';
import {
    SearchOrderByType,
    SearchOrderType,
    addCondition,
    changeSort,
    setQuery,
} from '../../../slice/searchSlice';
import { shuffleArray } from '../../../lib/misc';

const SongCollection = ({
    collection,
    roleCollection = undefined,
    navigation,
    searchId,
    isUseOriginalCollection = false,
    isAlbumSongPage = false,
    lead,
}: {
    collection: 'loading' | SongItemType[];
    roleCollection?: {
        composerCollection?: 'loading' | SongItemType[];
        arrangerCollection?: 'loading' | SongItemType[];
        lyricistCollection?: 'loading' | SongItemType[];
        talentCollection?: 'loading' | SongItemType[];
    };
    navigation?: {
        title: string;
        to?: string;
        onChange?: (value: string) => void;
    };
    searchId: string;
    isUseOriginalCollection?: boolean;
    isAlbumSongPage?: boolean;
    lead?: {
        element: JSX.Element;
        height: number;
    };
}) => {
    const ref = useRef<HTMLElement>(null);
    const sheet = useAppSelector((state) => state.sheet);
    const dispatch = useAppDispatch();
    const searchState = useAppSelector((state) =>
        state.search.items.find((item) => item.id === searchId),
    );
    const query = searchState?.query;

    const [countDisplay, setCountDisplay] = useState<string>('');
    const [targetHeight, setHeight] = useState<number>(0);
    const [filter, setFilter] = useState<CreatorFilterType>('all');
    const [resultCollection, setResultCollection] = useState<'loading' | SongItemType[]>('loading');

    const handleChangeHeight = (): void => {
        const raw = ref?.current?.clientHeight;
        const clienghtHeight = parseInt(String(raw), 10) || 0;
        if (clienghtHeight > 0) {
            setHeight(clienghtHeight);
        }
    };

    useEffect(() => {
        dispatch(addCondition(searchId));
    }, [dispatch, searchId]);

    useEffect(() => {
        handleChangeHeight();
    }, [sheet.isOpen, sheet.isFull, sheet.tab]);

    /**
     * シャッフル以外の処理はここでやる
     */
    const collectionMemo = useMemo((): 'loading' | SongItemType[] => {
        if (isUseOriginalCollection) {
            return collection;
        }
        let usingCollection = collection;

        if (roleCollection) {
            if (filter === 'composer') usingCollection = roleCollection.composerCollection || [];
            if (filter === 'arranger') usingCollection = roleCollection.arrangerCollection || [];
            if (filter === 'lyricist') usingCollection = roleCollection.lyricistCollection || [];
            if (filter === 'talent') usingCollection = roleCollection.talentCollection || [];
        }

        if (usingCollection === 'loading') {
            return 'loading';
        }

        let filtered = usingCollection;
        if (query) {
            filtered = FilterByQuery(filtered, query);
        }

        let sorted = filtered;
        if (searchState && !isAlbumSongPage) {
            sorted = SortSongCollection(sorted, searchState.order, searchState.orderBy);
        }

        return sorted;
    }, [
        collection,
        filter,
        isUseOriginalCollection,
        query,
        roleCollection,
        searchState,
        isAlbumSongPage,
    ]);

    /**
     * シャッフルのためにresultCollectionがあるよ
     */
    useEffect(() => {
        setResultCollection(collectionMemo);

        if (collectionMemo === 'loading') {
        } else {
            setCountDisplay(` ( ${collectionMemo.length} )`);
        }
    }, [collectionMemo]);

    const SongCollectionMemo = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflow: 'hidden',
            }}
        >
            {navigation && (
                <NavigationBar
                    title={`${navigation.title}${countDisplay}`}
                    to={navigation.to}
                    query={query}
                    setQuery={(query: string) => {
                        dispatch(
                            setQuery({
                                id: searchId,
                                query,
                            }),
                        );
                    }}
                    sort={
                        searchState && !isAlbumSongPage
                            ? {
                                  order: searchState.order,
                                  orderBy: searchState.orderBy,
                                  handleChangeSort: (
                                      order: SearchOrderType,
                                      orderBy: SearchOrderByType,
                                  ) => {
                                      if (searchState.order === 'shuffle' && order === 'shuffle') {
                                          if (resultCollection !== 'loading') {
                                              setResultCollection(shuffleArray(resultCollection));
                                          }
                                      }
                                      dispatch(changeSort({ id: searchId, order, orderBy }));
                                  },
                              }
                            : undefined
                    }
                />
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    overflow: 'hidden',
                }}
            >
                <Box
                    ref={ref}
                    sx={{
                        flexGrow: 1,
                        overflow: 'hidden',
                    }}
                >
                    <CustomFixedSizeList
                        collection={resultCollection}
                        height={targetHeight}
                        creator_filter={
                            roleCollection
                                ? {
                                      all:
                                          collection !== 'loading' && Array.isArray(collection)
                                              ? collection.length
                                              : 0,
                                      composer:
                                          roleCollection?.composerCollection !== 'loading' &&
                                          Array.isArray(roleCollection?.composerCollection)
                                              ? roleCollection?.composerCollection?.length
                                              : 0,
                                      arranger:
                                          roleCollection?.arrangerCollection !== 'loading' &&
                                          Array.isArray(roleCollection?.arrangerCollection)
                                              ? roleCollection?.arrangerCollection?.length
                                              : 0,
                                      lyricist:
                                          roleCollection?.lyricistCollection !== 'loading' &&
                                          Array.isArray(roleCollection?.lyricistCollection)
                                              ? roleCollection?.lyricistCollection?.length
                                              : 0,
                                      talent:
                                          roleCollection?.talentCollection !== 'loading' &&
                                          Array.isArray(roleCollection?.talentCollection)
                                              ? roleCollection?.talentCollection?.length
                                              : 0,
                                      selected: filter,
                                      setFilter: setFilter,
                                  }
                                : undefined
                        }
                        lead={lead}
                    />
                </Box>
            </Box>
        </Box>
    );
    return SongCollectionMemo;
};

export default SongCollection;
