import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GenerationItemType } from '../../type/hololive.types';

const initialState: {
    isLoaded: boolean;
    hasError: boolean;
    items: GenerationItemType[];
    loadStatus: 'ready' | 'loading' | 'loaded';
    computeStatus: 'ready' | 'computing' | 'computed';
} = {
    isLoaded: false,
    hasError: false,
    loadStatus: 'ready',
    computeStatus: 'ready',
    items: [],
};

const generationSlice = createSlice({
    name: 'generation',
    initialState,
    reducers: {
        startLoadGeneration: (state, action: PayloadAction) => {
            state.loadStatus = 'loading';
        },
        completeLoadGeneration: (state, action: PayloadAction<GenerationItemType[]>) => {
            state.isLoaded = true;
            state.loadStatus = 'loaded';
            const items = action.payload.map((item): GenerationItemType => {
                return {
                    ...item,
                    songs: {
                        status: 'ready',
                    },
                };
            });
            state.items = [...items];
        },
        startComputeSongCount: (state, action: PayloadAction) => {
            state.computeStatus = 'computing';
        },
        completecComputeSongCount: (state, action: PayloadAction<GenerationItemType[]>) => {
            if (state.computeStatus !== 'computed') {
                state.computeStatus = 'computed';
                state.items = [...action.payload];
            }
        },
        startFetchPeronSong: (state, action: PayloadAction<number>) => {
            const generationId = action.payload;
            const generation = state.items.find((item) => item.id === generationId);
            if (generation) {
                generation.songs = {
                    status: 'loading',
                };
            }
        },
        completeFetchGenerationSong: (
            state,
            action: PayloadAction<{
                generation: GenerationItemType;
                songs: GenerationItemType['songs'];
            }>,
        ) => {
            const { generation, songs } = action.payload;
            const currentGeneration = state.items.find((item) => item.id === generation.id);
            if (!currentGeneration) return;
            currentGeneration.songs = songs;
        },
    },
});

export const {
    startLoadGeneration,
    completeLoadGeneration,
    startComputeSongCount,
    completecComputeSongCount,
    startFetchPeronSong,
    completeFetchGenerationSong,
} = generationSlice.actions;

export default generationSlice.reducer;
