import { isStringUnionType, isNumberUnionType } from './misc';

/**
 * ローカルストレージの読み書き関数を返す
 * (文字列リテラルのUnion型)
 * @param key localStorageで使うキー
 * @param array 文字列リテラル型の配列
 * @returns localStorageのsetとload関数
 */
const StringUnion = <T extends string>(
    key: string,
    array: T[],
): {
    load: () => T;
    set: (value: T) => void;
} => {
    const load = (): T => {
        const value = localStorage.getItem(key);

        if (isStringUnionType<T>(value, array)) {
            return value;
        }
        return array[0];
    };
    const set = (value: T): void => {
        localStorage.setItem(key, String(value));
    };

    return {
        load,
        set,
    };
};

/**
 * ローカルストレージの読み書き関数を返す
 * (numberのUnion型)
 * @param key localStorageで使うキー
 * @param array 文字列リテラル型の配列
 * @returns localStorageのsetとload関数
 */
const NumberUnion = <T extends number>(
    key: string,
    array: T[],
): {
    load: () => T;
    set: (value: T) => void;
} => {
    const load = (): T => {
        const raw = localStorage.getItem(key);
        if (raw) {
            const value = parseInt(raw, 10);

            if (isNumberUnionType<T>(value, array)) {
                return value;
            }
        }
        return array[0];
    };
    const set = (value: T): void => {
        localStorage.setItem(key, String(value));
    };

    return {
        load,
        set,
    };
};

/**
 * ローカルストレージの読み書き関数を返す
 * (boolean)
 * @param key localStorageで使うキー
 * @returns localStorageのsetとload関数
 */
const Boolean = (
    key: string,
): {
    load: (initialState: boolean) => boolean;
    set: (value: boolean) => void;
} => {
    const load = (initialState: boolean): boolean => {
        const raw = localStorage.getItem(key);
        return raw === 'true' ? true : raw === 'false' ? false : initialState;
    };
    const set = (value: boolean): void => {
        localStorage.setItem(key, value ? 'true' : 'false');
    };

    return {
        load,
        set,
    };
};

const LocalStorageClient = {
    StringUnion,
    NumberUnion,
    Boolean,
};
export default LocalStorageClient;
