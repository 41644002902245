import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Link,
    Radio,
    RadioGroup,
} from '@mui/material';
import { HOLOPLAYER_PATH } from '../../route/route';
import NavigationBar from '../ui/NavigationBar/NavigationBar';
import CustomTable from '../ui/CustomTable';
import useSong from '../feature/collection/useSong';
import usePlayer from '../feature/player/usePlayer';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import React, { useMemo, useState } from 'react';
import YouTubeLinkButton from '../feature/collection/detail/YouTubeLinkButton';
import { hiraganaToKatakana, isDevEnv, katakanaToHiragana, parseSabi } from '../../lib/misc';
import MagicSongChipGroup from '../feature/collection/detail/MagicSongChipGroup';
import FilterSheet from '../feature/collection/filterSheet/FilterSheet';

const columns_origin = [
    {
        width: 60,
        label: 'No.',
        dataKey: 'no',
        numeric: true,
        sortable: true,
    },
    {
        width: 120,
        label: '再生',
        dataKey: 'play',
    },
    {
        width: 190,
        label: 'YouTube',
        dataKey: 'youtube',
    },
    {
        width: 220,
        label: '曲名',
        dataKey: 'title',
        sortable: true,
    },
    {
        width: 130,
        label: '音源公開日',
        dataKey: 'published_YYYYMMDD',
        sortable: true,
    },
    {
        width: 200,
        label: 'アーティスト',
        dataKey: 'artist',
    },
    {
        width: 360,
        label: 'タレント',
        dataKey: 'talent',
    },
    {
        width: 200,
        label: '作曲者',
        dataKey: 'composer',
    },
    {
        width: 200,
        label: '編曲者',
        dataKey: 'arranger',
    },
    {
        width: 200,
        label: '作詞者',
        dataKey: 'lyricist',
    },
    {
        width: 200,
        label: 'サビ区間',
        dataKey: 'sabi',
    },
    {
        width: 200,
        label: '歌唱ゲスト',
        dataKey: 'guest',
    },
];

const DataPage = () => {
    const Song = useSong();
    const Player = usePlayer();
    const [query, setQuery] = useState<string>('');

    const [isOpenFilterDialog, setIsOpenFilterDialog] = useState<boolean>(false);
    const [isFullFilterDialog, setIsFullFilterDialog] = useState<boolean>(false);

    type ArtistFilterType = 'default' | 'OnlyUnregistered' | 'OnlyRegistered';
    const [artistFilter, setArtistFilter] = useState<ArtistFilterType>('default');

    type YouTubeIdFilterType = 'default' | 'OnlyMv' | 'OnlyTopic' | 'NoData' | 'Both';
    const [YouTubeIdFilter, setYouTubeIdFilter] = useState<YouTubeIdFilterType>('default');

    const data = useMemo(() => {
        return (
            Song.items
                // .filter((item) => item.artist === null)
                .map((item, index) => {
                    return {
                        ...item,
                        _: item,
                        no: index,
                        sabi: parseSabi(item.sabi),
                        artist: MagicSongChipGroup([item.artist], Song.navigateTo),
                        talent: MagicSongChipGroup(item.talent, Song.navigateTo),
                        composer: MagicSongChipGroup(item.composer, Song.navigateTo),
                        arranger: MagicSongChipGroup(item.arranger, Song.navigateTo),
                        lyricist: MagicSongChipGroup(item.lyricist, Song.navigateTo),
                        guest: MagicSongChipGroup(item.guest, Song.navigateTo),
                        edit: isDevEnv() && (
                            <Link
                                href={`${process.env.REACT_APP_HOLOPLAYER_CMS_BASE}${item.id}`}
                                target="_blank"
                            >
                                {item.id}
                            </Link>
                        ),
                        youtube: (
                            <Box sx={{ display: 'flex' }}>
                                <YouTubeLinkButton video_id={item.video_id.mv} label={'MV'} />
                                <YouTubeLinkButton video_id={item.video_id.topic} label={'フル'} />
                            </Box>
                        ),
                        play: (
                            <Button
                                href=""
                                variant="contained"
                                startIcon={<PlayArrowIcon />}
                                target="_blank"
                                disabled={item.video_id.active ? false : true}
                                sx={{ margin: '0 8px 0 0' }}
                                size="small"
                                onClick={() => {
                                    if (Song.current.song) {
                                        Player.HandleClickChangeSongButton({
                                            item,
                                            collection: Song.items,
                                        });
                                    }
                                }}
                            >
                                再生
                            </Button>
                        ),
                    };
                })
        );
        // 再生秒数の更新で再描画するのを抑制
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Song.isLoaded]);

    const columns = useMemo(() => {
        if (isDevEnv()) {
            return [
                {
                    width: 40,
                    label: '',
                    dataKey: 'edit',
                    numeric: true,
                    sortable: false,
                },
                ...columns_origin,
            ];
        }
        return columns_origin;
    }, []);

    const rows = useMemo(() => {
        let rows = data;

        if (query) {
            const hiragana = katakanaToHiragana(query);
            const katakana = hiraganaToKatakana(query);
            rows = rows.filter((song) => {
                if (!song.search_all) return false;
                return new RegExp(`(${hiragana}|${katakana})`, 'igms').test(song.search_all);
            });
        }

        if (artistFilter === 'OnlyRegistered') {
            rows = rows.filter((item) => item._.artist);
        } else if (artistFilter === 'OnlyUnregistered') {
            rows = rows.filter((item) => !item._.artist);
        }

        if (YouTubeIdFilter === 'OnlyMv') {
            rows = rows.filter((item) => item.video_id.mv && item.video_id.topic === null);
        } else if (YouTubeIdFilter === 'OnlyTopic') {
            rows = rows.filter((item) => item.video_id.topic && item.video_id.mv === null);
        } else if (YouTubeIdFilter === 'NoData') {
            rows = rows.filter((item) => item.video_id.mv === null && item.video_id.topic === null);
        } else if (YouTubeIdFilter === 'Both') {
            rows = rows.filter((item) => item.video_id.mv !== null && item.video_id.topic !== null);
        }

        return rows;
    }, [artistFilter, data, query, YouTubeIdFilter]);

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <NavigationBar
                title={`データテーブル ( ${rows.length} )`}
                to={HOLOPLAYER_PATH.LIBRARY}
                query={query}
                setQuery={setQuery}
                handleClickSetting={() => {
                    setIsOpenFilterDialog(true);
                }}
                settingCount={(() => {
                    let count: number = 0;
                    if (artistFilter !== 'default') {
                        count++;
                    }
                    if (YouTubeIdFilter !== 'default') {
                        count++;
                    }
                    return count;
                })()}
            />
            <Box sx={{ flexGrow: 1 }}>
                {Song.isLoaded && <CustomTable rows={rows} columns={columns} />}
            </Box>

            <FilterSheet
                isOpen={isOpenFilterDialog}
                isFull={isFullFilterDialog}
                doClose={() => setIsOpenFilterDialog(false)}
                doFullscreen={() => {
                    setIsFullFilterDialog(true);
                }}
                doExitFullscreen={() => setIsFullFilterDialog(false)}
            >
                <Box
                    sx={{
                        overflow: 'auto',
                        // padding: '16px 16px 56px',
                    }}
                >
                    <Box sx={{ m: 2 }}>
                        <FormControl>
                            <FormLabel>アーティスト</FormLabel>
                            <RadioGroup
                                value={artistFilter}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const value = e.target.value as ArtistFilterType;
                                    if (
                                        value === 'default' ||
                                        value === 'OnlyUnregistered' ||
                                        value === 'OnlyRegistered'
                                    ) {
                                        setArtistFilter(value);
                                    }
                                }}
                            >
                                <FormControlLabel
                                    label="すべて"
                                    value="default"
                                    control={<Radio />}
                                />
                                <FormControlLabel
                                    label="未登録のみ"
                                    value="OnlyUnregistered"
                                    control={<Radio />}
                                />
                                <FormControlLabel
                                    label="登録済みのみ"
                                    value="OnlyRegistered"
                                    control={<Radio />}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box sx={{ m: 2 }}>
                        <FormControl>
                            <FormLabel>YouTube</FormLabel>
                            <RadioGroup
                                value={YouTubeIdFilter}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const value = e.target.value as YouTubeIdFilterType;
                                    if (
                                        value === 'default' ||
                                        value === 'OnlyMv' ||
                                        value === 'OnlyTopic' ||
                                        value === 'NoData' ||
                                        value === 'Both'
                                    ) {
                                        setYouTubeIdFilter(value);
                                    }
                                }}
                            >
                                <FormControlLabel
                                    label="すべて"
                                    value="default"
                                    control={<Radio />}
                                />
                                <FormControlLabel
                                    label="✅ MV有・✅ フル有"
                                    value="Both"
                                    control={<Radio />}
                                />
                                <FormControlLabel
                                    label="✅ MV有・❌ フル無"
                                    value="OnlyMv"
                                    control={<Radio />}
                                />
                                <FormControlLabel
                                    label="❌ MV無・✅ フル有"
                                    value="OnlyTopic"
                                    control={<Radio />}
                                />
                                <FormControlLabel
                                    label="❌ MV無・❌ フル無"
                                    value="NoData"
                                    control={<Radio />}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box>
            </FilterSheet>
        </Box>
    );
};

export default DataPage;
