import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchAllSongFilterByCreator, fetchAllTalent } from '../../lib/fetch';
import { webWorkerAsync } from '../../lib/webWorker';
import { SongItemType, TalentItemType } from '../../type/hololive.types';
import {
    completecComputeTalentRelationSongs,
    loadAllTalent,
    completeFetchTalentRelationSongs,
    startComputeTalentRelationSongs,
    startFetchTalentRelationSongs,
} from './talentSlice';

const task = (
    message: MessageEvent<{
        talents: TalentItemType[];
        songs: SongItemType[];
    }>,
): TalentItemType[] => {
    const talents = message.data.talents;
    const songs = message.data.songs;
    return talents.map((talent: any) => ({
        ...talent,
        count: songs.filter((song) => {
            return song.talent?.some((item) => talent.id === item.id);
        }).length,
    }));
};

const useTalent = () => {
    const { isLoaded, items, computeRelationSongsStatus } = useAppSelector((state) => state.talent);
    const SongState = useAppSelector((state) => state.song);
    const dispatch = useAppDispatch();

    const fetchTalent = () => {
        if (isLoaded) return;

        fetchAllTalent().then((res) => {
            if (res) {
                dispatch(loadAllTalent(res));
            }
        });
    };

    const computeSongCount = () => {
        if (computeRelationSongsStatus !== 'ready') return;
        if (!isLoaded) return;
        if (!SongState.isLoaded) return;
        dispatch(startComputeTalentRelationSongs());
        webWorkerAsync<
            {
                talents: TalentItemType[];
                songs: SongItemType[];
            },
            TalentItemType[]
        >(task, { talents: items, songs: SongState.items }).then((done) => {
            if (done.length > 0) {
                dispatch(completecComputeTalentRelationSongs(done));
            }
        });
    };

    const fetchTalentSong = (talent: TalentItemType) => {
        if (talent?.songs?.status === 'ready') {
            dispatch(startFetchTalentRelationSongs(talent.id));

            fetchAllSongFilterByCreator(talent.id).then((songs) => {
                dispatch(
                    completeFetchTalentRelationSongs({
                        talent,
                        songs: {
                            ...songs,
                            status: 'loaded',
                        },
                    }),
                );
            });
        }
    };

    return {
        isLoaded,
        items,
        fetchTalent,
        computeSongCount,
        fetchTalentSong,
    };
};
export default useTalent;
