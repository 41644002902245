import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AlbumItemType } from '../../type/hololive.types';

const initialState: {
    isLoaded: boolean;
    hasError: boolean;
    items: AlbumItemType[];
    loadStatus: 'ready' | 'loading' | 'loaded';
    searchStringStatus: 'ready' | 'computing' | 'computed';
} = {
    isLoaded: false,
    hasError: false,
    items: [],
    loadStatus: 'ready',
    searchStringStatus: 'ready',
};

const albumSlice = createSlice({
    name: 'album',
    initialState,
    reducers: {
        startLoadAlbum: (state, action: PayloadAction) => {
            state.loadStatus = 'loading';
        },
        completeLoadAlbum: (state, action: PayloadAction<AlbumItemType[]>) => {
            state.isLoaded = true;
            state.loadStatus = 'loaded';
            state.items = [...action.payload];
        },
        startComputeSearchString: (state, action: PayloadAction) => {
            state.searchStringStatus = 'computing';
        },
        completeComputeSearchString: (state, action: PayloadAction<AlbumItemType[]>) => {
            if (state.searchStringStatus !== 'computed') {
                state.searchStringStatus = 'computed';
                state.items = [...action.payload];
            }
        },
    },
});

export const {
    startLoadAlbum,
    completeLoadAlbum,
    startComputeSearchString,
    completeComputeSearchString,
} = albumSlice.actions;

export default albumSlice.reducer;
