import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TalentItemType } from '../../type/hololive.types';

const initialState: {
    isLoaded: boolean;
    hasError: boolean;
    items: TalentItemType[];

    // unitごとの曲の数をWebWorkerで計算している。その処理の状況。
    computeRelationSongsStatus: 'ready' | 'computing' | 'computed';
} = {
    isLoaded: false,
    hasError: false,
    computeRelationSongsStatus: 'ready',
    items: [],
};

const personSlice = createSlice({
    name: 'talent',
    initialState,
    reducers: {
        loadAllTalent: (state, action: PayloadAction<TalentItemType[]>) => {
            state.isLoaded = true;
            const items = action.payload.map((item): TalentItemType => {
                return {
                    ...item,
                    songs: {
                        status: 'ready',
                    },
                };
            });
            state.items = [...items];
        },
        startComputeTalentRelationSongs: (state, action: PayloadAction) => {
            state.computeRelationSongsStatus = 'computing';
        },
        completecComputeTalentRelationSongs: (state, action: PayloadAction<TalentItemType[]>) => {
            if (state.computeRelationSongsStatus !== 'computed') {
                state.computeRelationSongsStatus = 'computed';
                state.items = [...action.payload];
            }
        },
        startFetchTalentRelationSongs: (state, action: PayloadAction<number>) => {
            const talentId = action.payload;
            const talent = state.items.find((item) => item.id === talentId);
            if (talent) {
                talent.songs = {
                    status: 'loading',
                };
            }
        },
        completeFetchTalentRelationSongs: (
            state,
            action: PayloadAction<{
                talent: TalentItemType;
                songs: TalentItemType['songs'];
            }>,
        ) => {
            const { talent, songs } = action.payload;
            const currentTalent = state.items.find((item) => item.id === talent.id);
            if (!currentTalent) return;
            currentTalent.songs = songs;
        },
    },
});

export const {
    loadAllTalent,
    startComputeTalentRelationSongs,
    completecComputeTalentRelationSongs,
    startFetchTalentRelationSongs,
    completeFetchTalentRelationSongs,
} = personSlice.actions;

export default personSlice.reducer;
