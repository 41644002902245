import { Box, List } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import { HOLOPLAYER_PATH } from '../../../route/route';
import CustomListItem from '../../ui/CustomListItem';
import NavigationBar from '../../ui/NavigationBar/NavigationBar';
import { useEffect, useMemo, useState } from 'react';
import { hiraganaToKatakana, katakanaToHiragana } from '../../../lib/misc';
import { UnitItemType } from '../../../type/hololive.types';
import NoContent from '../../ui/NoContent';
import useUnit from '../../../slice/unit/useUnit';
import { DEFAULT_LOADING_SKELETON_COUNT } from '../../../constants';

const UnitCollectionPage = () => {
    const Unit = useUnit();

    const songState = useAppSelector((state) => state.song);
    const [query, setQuery] = useState<string>('');

    useEffect(() => {
        Unit.fetchUnit();
    }, [Unit]);

    useEffect(() => {
        Unit.computeSongCount();
    }, [Unit, songState.isLoaded, Unit.loadStatus]);

    const units = useMemo(() => {
        let unitOriginalItems = Unit.items;
        if (!query) return unitOriginalItems;
        const hiragana = katakanaToHiragana(query);
        const katakana = hiraganaToKatakana(query);

        return unitOriginalItems.filter((unit) => {
            return new RegExp(`(${hiragana}|${katakana})`, 'igms').test(unit.name);
        });
    }, [query, Unit.items]);

    const displayCount = useMemo(() => {
        if (Unit.isLoaded) {
            return ` ( ${units.length} )`;
        }
        return '';
    }, [Unit.isLoaded, units]);

    return (
        <Box>
            <NavigationBar
                title={`ユニット${displayCount}`}
                to={HOLOPLAYER_PATH.LIBRARY}
                query={query}
                setQuery={setQuery}
                placeholder="UMISEA、miComet、不知火建設..."
            />
            <List>
                {Unit.isLoaded && units.length === 0 ? (
                    <NoContent />
                ) : (
                    (units.length > 0
                        ? units
                        : Array(DEFAULT_LOADING_SKELETON_COUNT).fill(null)
                    ).map((unit: UnitItemType | null, index) => {
                        const primary = unit === null ? '' : `${units[index].name}`;
                        const to =
                            unit === null
                                ? undefined
                                : HOLOPLAYER_PATH.LIBRARY_UNIT_COLLECTION + `/${unit.id}`;
                        const tertiary =
                            units !== null && units[index]?.count
                                ? `${units[index].count}`
                                : undefined;
                        return (
                            <CustomListItem
                                key={index}
                                isLoading={unit === null}
                                primary={primary}
                                to={to}
                                tertiary={tertiary}
                            />
                        );
                    })
                )}
            </List>
        </Box>
    );
};

export default UnitCollectionPage;
