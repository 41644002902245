import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PersonItemType } from '../../type/hololive.types';

const initialState: {
    isLoaded: boolean;
    hasError: boolean;
    items: PersonItemType[];
    loadStatus: 'ready' | 'loading' | 'loaded';
    computeStatus: 'ready' | 'computing' | 'computed';
} = {
    isLoaded: false,
    hasError: false,
    loadStatus: 'ready',
    computeStatus: 'ready',
    items: [],
};

const personSlice = createSlice({
    name: 'person',
    initialState,
    reducers: {
        startLoadPerson: (state, action: PayloadAction) => {
            state.loadStatus = 'loading';
        },
        completeLoadPerson: (state, action: PayloadAction<PersonItemType[]>) => {
            state.isLoaded = true;
            state.loadStatus = 'loaded';
            const items = action.payload.map((item): PersonItemType => {
                return {
                    ...item,
                    songs: {
                        status: 'ready',
                    },
                };
            });
            state.items = [...items];
        },
        startComputeSongCount: (state, action: PayloadAction) => {
            state.computeStatus = 'computing';
        },
        completecComputeSongCount: (state, action: PayloadAction<PersonItemType[]>) => {
            if (state.computeStatus !== 'computed') {
                state.computeStatus = 'computed';
                state.items = [...action.payload];
            }
        },
        startFetchPeronSong: (state, action: PayloadAction<number>) => {
            const personId = action.payload;
            const person = state.items.find((item) => item.id === personId);
            if (person) {
                person.songs = {
                    status: 'loading',
                };
            }
        },
        completeFetchPersonSong: (
            state,
            action: PayloadAction<{
                person: PersonItemType;
                songs: PersonItemType['songs'];
            }>,
        ) => {
            const { person, songs } = action.payload;
            const currentTalent = state.items.find((item) => item.id === person.id);
            if (!currentTalent) return;
            currentTalent.songs = songs;
        },
    },
});

export const {
    startLoadPerson,
    completeLoadPerson,
    startComputeSongCount,
    completecComputeSongCount,
    startFetchPeronSong,
    completeFetchPersonSong,
} = personSlice.actions;

export default personSlice.reducer;
