import dayjs from 'dayjs';

export const getSafeAreaInsetBottom = (): number => {
    const val = getComputedStyle(document.documentElement).getPropertyValue(
        '--safeareainsetbottom',
    );
    if (!val) return 0;
    return parseInt(val, 10);
};

export const shuffleArray = <T>(array: T[]): T[] => {
    const cloneArray = [...array];

    for (let i = cloneArray.length - 1; i >= 0; i--) {
        let rand = Math.floor(Math.random() * (i + 1));
        let tmpStorage = cloneArray[i];
        cloneArray[i] = cloneArray[rand];
        cloneArray[rand] = tmpStorage;
    }

    return cloneArray;
};

export const isTouchDevice = (): boolean => {
    // @ts-ignore`
    const msPointerEnabled: boolean = navigator?.msPointerEnabled;
    return 'ontouchstart' in window || msPointerEnabled ? true : false;
};
export const katakanaToHiragana = (str: string): string => {
    return str.replace(/[\u30a1-\u30f6]/g, function (match) {
        var chr = match.charCodeAt(0) - 0x60;
        return String.fromCharCode(chr);
    });
};

export const hiraganaToKatakana = (str: string) => {
    return str.replace(/[\u3041-\u3096]/g, function (match) {
        var chr = match.charCodeAt(0) + 0x60;
        return String.fromCharCode(chr);
    });
};

/**
 * 値が、指定のstringのUnion型であるか判定する
 */
export const isStringUnionType = <T extends string>(
    value: string | null,
    array: T[],
): value is T => {
    return array.some((item) => item === value);
};

/**
 * 値が、指定のnumberのUnion型であるか判定する
 */
export const isNumberUnionType = <T extends number>(
    value: number | null,
    array: T[],
): value is T => {
    return array.some((item) => item === value);
};

/**
 *
 */
export const toSeconds = (
    seconds: string | undefined,
    minutes?: string | undefined,
    hours?: string | undefined,
) => {
    const _s = seconds ? parseInt(seconds, 10) : 0;
    const _m = minutes ? parseInt(minutes, 10) * 60 : 0;
    const _h = hours ? parseInt(hours, 10) * 60 * 60 : 0;
    return _s + _m + _h;
};

/**
 * 環境を返す
 */
export const getEnv = () => {
    return process.env.REACT_APP_ENV as 'develop' | 'production';
};
export const isDevEnv = () => {
    return getEnv() === 'develop';
};
export const isProdEnv = () => {
    return getEnv() === 'production';
};

export const isAfterDays = (unixtime: number, days: number): boolean => {
    const target_dayjs = dayjs.unix(unixtime);
    const period = dayjs().subtract(30, 'day');
    const isAfter = target_dayjs.isAfter(period);
    return isAfter;
};

export const MathFloorDigit = (value: number, digit: number) => {
    return Math.floor(value * Math.pow(10, digit)) / Math.pow(10, digit);
};

function secToDayTime(seconds: number) {
    const day = Math.floor(seconds / 86400);
    const hour = Math.floor((seconds % 86400) / 3600);
    const min = Math.floor((seconds % 3600) / 60);
    const sec = seconds % 60;
    return {
        day,
        hour,
        min,
        sec,
    };
}

export const zeroPadding = (value: number, digit: number) => {
    return (String(Array(digit).fill(0).join('')) + value).slice(digit * -1);
};

export const seconds2TimeCode = (s: number | null): string => {
    if (s === null) return `0:00`;
    const { min, sec } = secToDayTime(s);
    return `${min}:${zeroPadding(sec, 2)}`;
};

export const parseSabi = (
    sabi: {
        start: number;
        end: number;
    } | null,
) => {
    if (!sabi) return null;

    const start = seconds2TimeCode(sabi.start);
    const end = seconds2TimeCode(sabi.end);
    return `${start}-${end}`;
};

export const getRandomFromArray = <T>(items: T[]): T | undefined => {
    if (items.length === 0) return undefined;

    const index = Math.floor(Math.random() * items.length);
    return items[index];
};
