import React from 'react';
import { useAppSelector } from '../../app/hooks';
import SongCollection from '../feature/collection/SongCollection';

const SongCollectionTab = () => {
    const songState = useAppSelector((state) => state.song);

    return (
        <SongCollection
            collection={songState.isLoaded ? songState.items : 'loading'}
            navigation={{
                title: 'オリジナル曲',
            }}
            searchId="index"
        />
    );
};

export default SongCollectionTab;
