import BottomSheet from '../../../ui/BottomSheet/BottomSheet';
import FilterSheetHandle from './FilterSheetHandle';
import { ReactNode } from 'react';

const FilterDialog = ({
    isOpen,
    isFull,
    doClose,
    doFullscreen,
    doExitFullscreen,
    children,
}: {
    isOpen: boolean;
    isFull: boolean;
    doClose: () => void;
    doFullscreen: () => void;
    doExitFullscreen: () => void;
    children?: ReactNode;
}) => {
    const draggable = true;

    return (
        <BottomSheet
            isOpen={isOpen}
            isFull={isFull}
            doClose={doClose}
            doFullscreen={doFullscreen}
            doExitFullscreen={doExitFullscreen}
            handle={
                <FilterSheetHandle
                    isFull={isFull}
                    doFullscreen={doFullscreen}
                    doExitFullscreen={doExitFullscreen}
                    doClose={doClose}
                    draggable={draggable}
                />
            }
            draggable={draggable}
        >
            {children}
        </BottomSheet>
    );
};
export default FilterDialog;
