import { Box, List } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import { HOLOPLAYER_PATH } from '../../../route/route';
import CustomListItem from '../../ui/CustomListItem';
import NavigationBar from '../../ui/NavigationBar/NavigationBar';
import { useEffect, useMemo, useState } from 'react';
import { GenerationItemType } from '../../../type/hololive.types';
import { hiraganaToKatakana, katakanaToHiragana } from '../../../lib/misc';
import useGeneration from '../../../slice/generation/useGeneration';
import NoContent from '../../ui/NoContent';
import { DEFAULT_LOADING_SKELETON_COUNT } from '../../../constants';

const GenerationCollectionPage = () => {
    const Generation = useGeneration();

    const [countDisplay, setCountDisplay] = useState<string>('');
    const [query, setQuery] = useState<string>('');
    const songState = useAppSelector((state) => state.song);

    useEffect(() => {
        Generation.fetchGeneration();
    }, [Generation]);

    useEffect(() => {
        Generation.computeSongCount();
    }, [Generation, songState.isLoaded]);

    const generations: GenerationItemType[] = useMemo(() => {
        let generationItems = Generation.items;
        if (!query) return generationItems;
        const hiragana = katakanaToHiragana(query);
        const katakana = hiraganaToKatakana(query);

        return generationItems.filter((generation) => {
            return new RegExp(`(${hiragana}|${katakana})`, 'igms').test(
                `${generation.name}${generation.alias}${generation.title}`,
            );
        });
    }, [query, Generation.items]);

    useEffect(() => {
        if (Generation.isLoaded) {
            setCountDisplay(` ( ${generations.length} )`);
        } else {
            setCountDisplay('');
        }
    }, [Generation.isLoaded, generations.length]);

    return (
        <Box>
            <NavigationBar
                title={`ジェネレーション${countDisplay}`}
                to={HOLOPLAYER_PATH.LIBRARY}
                query={query}
                setQuery={setQuery}
                placeholder="0期生、ホロライブファンタジー..."
            />
            <List>
                {generations.length > 0 ? (
                    generations.map((generation, index) => {
                        let primary = generation.title;
                        if (generation.alias) {
                            primary = `${primary} ( ${generation.alias} )`;
                        }
                        return (
                            <CustomListItem
                                key={index}
                                primary={primary}
                                isLoading={!Generation.isLoaded}
                                to={
                                    HOLOPLAYER_PATH.LIBRARY_GENERATION_COLLECTION +
                                    `/${generation.id}`
                                }
                                tertiary={
                                    generations[index].count
                                        ? `${generations[index].count}`
                                        : undefined
                                }
                            />
                        );
                    })
                ) : Generation.isLoaded ? (
                    <NoContent />
                ) : (
                    Array(DEFAULT_LOADING_SKELETON_COUNT)
                        .fill(null)
                        .map((item, index) => (
                            <CustomListItem key={index} primary={''} isLoading={true} />
                        ))
                )}
            </List>
        </Box>
    );
};

export default GenerationCollectionPage;
