export const HOLOPLAYER_PATH = {
    HOME: '/',
    SONG: '/song',
    LIBRARY: '/library',
    LATEST_30_SONGS: '/library/latest30songs',
    IN_30_DAYS_SONGS: '/library/30days',
    LIBRARY_TALENT_COLLECTION: '/library/talent',
    LIBRARY_TALENT_ITEM: '/library/talent/:id',
    LIBRARY_PERSON_COLLECTION: '/library/person',
    LIBRARY_PERSON_ITEM: '/library/person/:id',
    LIBRARY_ALBUM_COLLECTION: '/library/album',
    LIBRARY_ALBUM_ITEM: '/library/album/:id',
    LIBRARY_UNIT_COLLECTION: '/library/unit',
    LIBRARY_UNIT_ITEM: '/library/unit/:id',
    LIBRARY_GENERATION_COLLECTION: '/library/generation',
    LIBRARY_GENERATION_ITEM: '/library/generation/:id',
    LIBRARY_DATA: '/library/data/',
    YOUTUBE: '/youtube',
    QUIZ: '/quiz',
    QUIZ_AENBIEN: '/quiz/aenbien',
    QUIZ_YUBIYUBI: '/quiz/yubiyubi',
    QUIZ_ZENKOUTEI: '/quiz/zenkoutei',
    OTHER: '/other',
    ACCOUNT: '/other/account',
    SETTING: '/other/setting',
    ABOUT: '/other/about',
};
