import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    useTheme,
    Box,
    Skeleton,
} from '@mui/material';

import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';

const CustomListItem = ({
    primary,
    secondary,
    tertiary,
    icon,
    divider = true,
    to = '',
    isExternal = false,
    isLoading,
}: {
    primary: string;
    secondary?: string;
    tertiary?: string;
    icon?: ReactNode;
    divider?: boolean;
    to?: string;
    isExternal?: boolean;
    isLoading: boolean;
}) => {
    let props = {};
    if (isExternal) {
        props = {
            component: 'a',
            href: to,
            target: '_blank',
        };
    } else {
        props = {
            button: true,
            component: Link,
            to,
        };
    }
    const theme = useTheme();
    return (
        <>
            <ListItem
                {...props}
                sx={[
                    {
                        padding: 0,
                        color: theme.palette.text.primary,
                    },
                    {
                        '.MuiListItemButton-root': { padding: '16px' },
                    },
                ]}
                dense
            >
                <ListItemButton>
                    <ListItemIcon sx={{ minWidth: '44px' }}>
                        <>{icon ? icon : <MusicNoteRoundedIcon />}</>
                    </ListItemIcon>
                    <Box>
                        {isLoading ? (
                            <Skeleton width="120px" sx={{ marginBottom: '4px' }} />
                        ) : (
                            <ListItemText primary={primary} secondary={secondary} />
                        )}
                    </Box>
                    <ListItemText
                        secondary={tertiary}
                        sx={{ alignItems: 'end', textAlign: 'end', marginRight: 1 }}
                    />
                    <NavigateNextRoundedIcon color="disabled" />
                </ListItemButton>
            </ListItem>
            {divider && <Divider variant="inset" component="li" sx={{ marginLeft: '60px' }} />}
        </>
    );
};
export default CustomListItem;
