import { SongItemType, TalentItemType } from '../../type/hololive.types';

export const prepareQuizBaseSongCollectionWebWorkerTask = (
    message: MessageEvent<SongItemType[]>,
): SongItemType[] => {
    /**
     * 配列をシャッフルする
     */
    const shuffleArray = <T>(array: T[]): T[] => {
        const cloneArray = [...array];

        for (let i = cloneArray.length - 1; i >= 0; i--) {
            let rand = Math.floor(Math.random() * (i + 1));
            let tmpStorage = cloneArray[i];
            cloneArray[i] = cloneArray[rand];
            cloneArray[rand] = tmpStorage;
        }

        return cloneArray;
    };

    /**
     * タスクに渡ってきた全曲リストを消毒しておく
     * - 重複排除
     * - video_idの存在確認
     * - シャッフル
     */
    const collection = shuffleArray(
        Array.from(new Map(message.data.map((song) => [song.id, song])).values()).filter(
            (song) => song && song.video_id.active && (song.video_id.topic || song.video_id.mv),
        ),
    );

    /**
     * 渡されたタレントが歌唱している曲を返す
     */
    const talentSongs = (talents: TalentItemType[]): SongItemType[] => {
        const talentIds = talents.map((talent) => talent.id);
        return collection.filter((item) =>
            item.talent?.some((t) => {
                return talentIds.some((id) => t.id === id);
            }),
        );
    };

    /**
     * 受け取った曲の配列を、重複排除・除外処理・シャッフル処理をして返す
     * @param songs 処理前の曲の配列
     * @param excludeItems 除外したい配列
     * @returns
     */
    const sanitizeSongCollection = (
        songs: SongItemType[],
        excludeItems: SongItemType[],
    ): SongItemType[] => {
        const unique = Array.from(new Map(songs.map((song) => [song.id, song])).values());
        const excluded = unique.filter((song) => excludeItems.every((i) => i.id !== song.id));
        const shuffled = shuffleArray(excluded);

        return shuffled;
    };

    /**
     * 渡された曲にできるだけ関連する曲を、下記の優先順に３つ返す
     * - 渡された曲を歌唱しているタレント、が歌っている曲
     * 関連曲が見つからなければ全曲からランダムに埋めて３つ必ず返す
     */
    const relatedSongs = (original: SongItemType): [SongItemType, SongItemType, SongItemType] => {
        // 候補の曲配列を用意しておく
        const songs: SongItemType[] = [];

        // タレント関連曲を候補にぶちこむ
        if (original.talent?.length && original.talent.length > 0) {
            songs.push(...talentSongs(original.talent));
        }

        // 候補曲を重複排除
        const sanitizedChoices = sanitizeSongCollection(songs, [original]);

        // ３つ以上なら全部候補曲から
        if (sanitizedChoices.length >= 3) {
            return [sanitizedChoices[0], sanitizedChoices[1], sanitizedChoices[2]];
        }

        // 足りない場合、その分だけ全曲から抜いてくる
        const sanitizedOriginalCollection = collection.filter((song) => song.id !== original.id);
        if (sanitizedChoices.length === 2) {
            return [sanitizedChoices[0], sanitizedChoices[1], sanitizedOriginalCollection[0]];
        }
        if (sanitizedChoices.length === 1) {
            return [
                sanitizedChoices[0],
                sanitizedOriginalCollection[0],
                sanitizedOriginalCollection[1],
            ];
        }
        return [
            sanitizedOriginalCollection[0],
            sanitizedOriginalCollection[1],
            sanitizedOriginalCollection[2],
        ];
    };

    return collection.map((song) => {
        // 選択肢が指定されてる場合はそれでいこう
        if (song.quiz && song.quiz[0] && song.quiz[1] && song.quiz[2]) return song;

        // 無かったらうまいこと作る
        return {
            ...song,
            quiz: relatedSongs(song).map((song) => song?.title),
        };
    });
};
