import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import React, { useState } from 'react';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';

const CustomTable = ({
    rows,
    columns,
}: {
    rows: any[];
    columns: {
        width: number;
        label: string;
        dataKey: string;
        numeric?: boolean;
        sortable?: boolean;
    }[];
}) => {
    const [orderBy, setOrderBy] = useState<number>(0);
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');

    const VirtuosoTableComponents: TableComponents = {
        Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
            <TableContainer component={Paper} {...props} ref={ref} />
        )),
        Table: (props) => (
            <Table
                {...props}
                size="small"
                sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }}
            />
        ),
        TableHead,
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
            <TableBody {...props} ref={ref} />
        )),
    };

    const sorted = rows.sort((a, b) => {
        let val = 0;

        const key = columns[orderBy].dataKey;
        const av = a[key];
        const bv = b[key];

        if (bv < av) val = -1;
        if (bv > av) val = 1;

        if (order === 'asc') val *= -1;

        return val;
    });

    const fixedHeaderContent = () => {
        return (
            <TableRow>
                {columns.map((column, index) => (
                    <TableCell
                        key={column.dataKey}
                        variant="head"
                        align={column.numeric || false ? 'right' : 'left'}
                        style={{ width: column.width }}
                        sx={{
                            backgroundColor: 'background.paper',
                        }}
                    >
                        {column.sortable ? (
                            <TableSortLabel
                                active={orderBy === index}
                                direction={order}
                                onClick={() => {
                                    if (orderBy === index) {
                                        setOrder(order === 'asc' ? 'desc' : 'asc');
                                    } else {
                                        setOrderBy(index);
                                    }
                                }}
                            >
                                {column.label}
                            </TableSortLabel>
                        ) : (
                            column.label
                        )}
                    </TableCell>
                ))}
            </TableRow>
        );
    };

    const rowContent = (_index: number, row: any) => {
        return (
            <React.Fragment>
                {columns.map((column) => (
                    <TableCell
                        key={column.dataKey}
                        align={column.numeric || false ? 'right' : 'left'}
                    >
                        {row[column.dataKey]}
                    </TableCell>
                ))}
            </React.Fragment>
        );
    };

    return (
        <TableVirtuoso
            data={sorted}
            components={VirtuosoTableComponents}
            fixedHeaderContent={fixedHeaderContent}
            itemContent={rowContent}
        />
    );
};

export default CustomTable;
