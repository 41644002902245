import { Box, useTheme } from '@mui/material';
import React from 'react';

const WeatherHackers = () => {
    const theme = useTheme();
    const Line = ({ children }: { children: React.ReactNode }) => {
        return <Box sx={{ marginBottom: 2 }}>{children}</Box>;
    };
    const Strike = ({ children }: { children: React.ReactNode }) => {
        return (
            <Box
                sx={{
                    display: 'inline',
                    textDecoration: 'line-through',
                    color: theme.palette.text.disabled,
                    marginRight: '4px',
                }}
            >
                {children}
            </Box>
        );
    };
    const Fixed = ({ children }: { children: React.ReactNode }) => {
        return (
            <Box
                sx={{
                    display: 'inline',
                    color: theme.palette.error.main,
                    fontWeight: 'bold',
                }}
            >
                {children}
            </Box>
        );
    };
    return (
        <Box
            sx={{
                padding: '16px 16px 56px',
            }}
        >
            <Line>負けそうなときは　勇気をあげるよ</Line>
            <Line>
                今日も<Strike>眩しい笑顔で☆</Strike>
                <Fixed>ハメるあさココで☆</Fixed>
            </Line>
            <Line>
                <Strike>初めて出会ったときの</Strike>
                <Fixed>お前どこの組のもんや？</Fixed>
            </Line>
            <Line>
                <Strike>ことを覚えていますか？</Strike>
                <Fixed>いい眼をしとるやないけ</Fixed>
            </Line>
            <Line>
                <Strike>夢と希望あふれた目で</Strike>
                <Fixed>そんなにボロボロになって</Fixed>
            </Line>
            <Line>
                <Strike>空の向こう　いつも眺めていたよね</Strike>
                <Fixed>どうしたんや？ とりあえずまあ上がれや</Fixed>
            </Line>
            <Line>
                <Strike>きっと特別なものなんていらないよ</Strike>
                <Fixed>チャカも　ロケランも　格闘もいらないよ</Fixed>
            </Line>
            <Line>前に進む　勇気があれば</Line>
            <Line>君が今踏み出した力強い一歩で</Line>
            <Line>どんな天気も　晴れてゆくよ</Line>
            <Line>(飛ぶぞ～！　１，２，３，Jump！！)</Line>
            <Line>
                Good morning,
                <Strike>Weather Hackers☆</Strike>
                <Fixed>＊＊ther＊＊ckers☆</Fixed>
            </Line>
            <Line>
                <Strike>朝の光を浴びた</Strike>
                <Fixed>朝から　ガンギマった</Fixed>
            </Line>
            <Line>みんなの　ハートに</Line>
            <Line>
                <Strike>元気を届けるんだ</Strike>
                <Fixed>カチコミをかけるんや</Fixed>
            </Line>
            <Line>
                Good morning,
                <Strike>Weather Hackers☆</Strike>
                <Fixed>＊＊ther＊＊ckers☆</Fixed>
            </Line>
            <Line>
                <Strike>夢へと走り出した</Strike>
                <Fixed>シノギで　疲れはてた</Fixed>
            </Line>
            <Line>
                <Strike>みんなの　心に</Strike>
                <Fixed>みんなの　ケツから</Fixed>
            </Line>
            <Line>
                <Strike>青空を届けるよ</Strike>
                <Fixed>気合いを叩き込むよ</Fixed>
            </Line>
            <Line>負けそうなときは　勇気をあげるよ</Line>
            <Line>
                今日も　
                <Strike>眩しい笑顔で☆</Strike>
                <Fixed>ハメるあさココで☆</Fixed>
            </Line>
            <Line>
                <Strike>少しだけ頑張ったら</Strike>
                <Fixed>これでもうちはアイドルの</Fixed>
            </Line>
            <Line>
                <Strike>足を止めて休んでね</Strike>
                <Fixed>代紋掲げとるんじゃ</Fixed>
            </Line>
            <Line>
                <Strike>ムダなことなんてないから</Strike>
                <Fixed>全然あやしくないから</Fixed>
            </Line>
            <Line>
                <Strike>振り返れば全てがつながってるよ</Strike>
                <Fixed>安心して　さぁガチ恋になってね</Fixed>
            </Line>
            <Line>
                <Strike>輝いた想いを遥かな風にのせて</Strike>
                <Fixed>合法で　安全な　あさココグッズキメて</Fixed>
            </Line>
            <Line>自由な空　羽広げたら</Line>
            <Line>
                <Strike>「いつだって前向いて歩いていけばいい！」って</Strike>
                <Fixed>「飯食って　クソをして　寝るだけでえらい！」って</Fixed>
            </Line>
            <Line>君の心も　晴れてゆくよ</Line>
            <Line>(いくよ～！　１，２，３，Jump！！)</Line>
            <Line>
                Good morning,
                <Strike>Weather Hackers☆</Strike>
                <Fixed>＊＊ther＊＊ckers☆</Fixed>
            </Line>
            <Line>
                <Strike>朝の光を浴びた</Strike>
                <Fixed>朝から　ガンギマった</Fixed>
            </Line>
            <Line>みんなの　ハートに</Line>
            <Line>
                <Strike>元気を届けるんだ</Strike>
                <Fixed>カチコミをかけるんや</Fixed>
            </Line>
            <Line>
                Good morning,
                <Strike>Weather Hackers☆</Strike>
                <Fixed>＊＊ther＊＊ckers☆</Fixed>
            </Line>
            <Line>
                <Strike>夢へと走り出した</Strike>
                <Fixed>シノギで　疲れはてた</Fixed>
            </Line>
            <Line>
                <Strike>みんなの　心に</Strike>
                <Fixed>みんなの　ケツから</Fixed>
            </Line>
            <Line>
                <Strike>青空を届けるよ</Strike>
                <Fixed>気合いを叩き込むよ</Fixed>
            </Line>
            <Line>疲れたときには　癒しをあげるよ</Line>
            <Line>
                飛ぼう　
                <Strike>広げた翼で☆</Strike>
                <Fixed>ヤバいあの「草ｗ」で☆</Fixed>
            </Line>
            <Line>空に煌めく　雷光</Line>
            <Line>響き渡った　咆哮</Line>
            <Line>未来に輝く　栄光</Line>
            <Line>その夢を諦めるな</Line>
            <Line>困難　理不尽も　上等</Line>
            <Line>まとめて跳ね返す　眼光</Line>
            <Line>Go your own way！</Line>
            <Line>進め　Right now！</Line>
            <Line>まだ見ぬ　空の向こうへー</Line>
            <Line>
                Good morning,
                <Strike>Weather Hackers☆</Strike>
                <Fixed>＊＊ther＊＊ckers☆</Fixed>
            </Line>
            <Line>
                <Strike>朝の光を浴びた</Strike>
                <Fixed>朝から　ガンギマった</Fixed>
            </Line>
            <Line>みんなの　ハートに</Line>
            <Line>
                <Strike>元気を届けるんだ</Strike>
                <Fixed>カチコミをかけるんや</Fixed>
            </Line>
            <Line>
                Good morning,
                <Strike>Weather Hackers☆</Strike>
                <Fixed>＊＊ther＊＊ckers☆</Fixed>
            </Line>
            <Line>
                <Strike>夢へと走り出した</Strike>
                <Fixed>シノギで　疲れはてた</Fixed>
            </Line>
            <Line>
                <Strike>みんなの　心に</Strike>
                <Fixed>みんなの　ケツから</Fixed>
            </Line>
            <Line>
                <Strike>青空を届けるよ</Strike>
                <Fixed>気合いを叩き込むよ</Fixed>
            </Line>
            <Line>泣きそうなときは　元気をあげるよ</Line>
            <Line>
                行こう　<Strike>素敵な</Strike>
                <Fixed>ゲボかわ</Fixed>スマイルで☆
            </Line>
        </Box>
    );
};
export default WeatherHackers;
