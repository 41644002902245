import { Box, Chip, useTheme } from '@mui/material';
import {
    ArtistType,
    PersonItemType,
    SongCreater,
    TalentItemType,
} from '../../../../type/hololive.types';
import { HOLOPLAYER_PATH } from '../../../../route/route';

type Nullish = undefined | null;

type ChipsValidProps = ArtistType | TalentItemType | SongCreater | PersonItemType | Nullish;

const UnRegister = () => {
    const theme = useTheme();
    return <Box sx={{ color: theme.palette.text.disabled }}>(登録なし)</Box>;
};

const MagicSongChipGroup = (
    array: ChipsValidProps[] | Nullish,
    handleClick: (to: string) => void,
) => {
    if (!array) return <UnRegister />;
    array = array?.filter((item) => item && item.type && item.id && item.name);
    if (!array || array.length === 0) return <UnRegister />;

    const handleClickChip = (item: ChipsValidProps) => {
        if (!item?.type || !item?.id) return;
        const type = item.type;
        const id = item.id;
        let path;

        if (type === 'talent') {
            path = HOLOPLAYER_PATH.LIBRARY_TALENT_COLLECTION;
        }
        if (type === 'person') {
            path = HOLOPLAYER_PATH.LIBRARY_PERSON_COLLECTION;
        }
        if (type === 'unit') {
            path = HOLOPLAYER_PATH.LIBRARY_UNIT_COLLECTION;
        }
        if (type === 'generation') {
            path = HOLOPLAYER_PATH.LIBRARY_GENERATION_COLLECTION;
        }
        if (path) {
            handleClick(`${path}/${id}`);
        }
    };

    return (
        <Box>
            {array.map((item, index) => {
                return (
                    <Chip
                        key={index}
                        sx={{
                            marginRight: '8px',
                            marginBottom: '8px',
                        }}
                        variant="outlined"
                        size="small"
                        label={item?.name}
                        onClick={() => handleClickChip(item)}
                    />
                );
            })}
        </Box>
    );
};

export default MagicSongChipGroup;
