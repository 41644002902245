import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

const Wrap = styled.div`
    background: ${(props: any) => props.theme.palette.primary.main};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9999;
`;

const Error = () => {
    const theme = useTheme();

    return (
        <Wrap theme={theme}>
            <Box sx={{ m: 4 }}>
                <Typography>エラーが発生しました。</Typography>
                <Typography>
                    <a href="https://twitter.com/holoplayerjp" target="_blank" rel="noreferrer">
                        Twitter
                    </a>
                    をご確認ください。
                </Typography>
            </Box>
        </Wrap>
    );
};
export default Error;
