import { Box, Divider, useTheme } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import CustomPlayer from './component/feature/player/CustomPlayer';
import ControllBar from './component/ui/ControllBar';
import CustomBottomNavigation from './component/ui/CustomBottomNavigation';
import { useEffect } from 'react';
import { logEvent } from 'firebase/analytics';
import { analytics } from './app/firebase';
import { useAppSelector } from './app/hooks';
import QuizPlayer from './component/feature/quiz/QuizPlayer';

const Main = () => {
    const theme = useTheme();
    const quiz = useAppSelector((state) => state.quiz);

    const location = useLocation();
    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_path: location.pathname,
            page_title: location.pathname,
        });
    }, [location]);

    return (
        <>
            <Box sx={{ flexShrink: 0 }}>
                {quiz.current.song ? <QuizPlayer /> : <CustomPlayer />}
            </Box>
            <Box
                sx={{
                    flexGrow: 1,
                    flexShrink: 1,
                    overflowY: 'auto',
                    backgroundColor: theme.palette.background.default,
                }}
            >
                <Outlet />
            </Box>
            <Divider />
            <Box sx={{ flexShrink: 0 }}>
                <ControllBar />
            </Box>
            <Divider />
            <Box sx={{ flexShrink: 0 }}>
                <CustomBottomNavigation />
            </Box>
        </>
    );
};
export default Main;
