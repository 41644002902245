import { ListItemText, ListItemAvatar, Divider, Typography, ListItemButton } from '@mui/material';
import { SongItemType } from '../../../type/hololive.types';
import PlayingBar from '../../ui/PlayingBar';
import CustomAvator from '../../ui/CustomAvator';
import useSong from './useSong';
import { useAppSelector } from '../../../app/hooks';

const SongItem = ({ song, handleClick }: { song: SongItemType; handleClick: () => void }) => {
    const Song = useSong();
    const playing = useAppSelector((state) => state.player.playing);
    const selected = song?.id === Song.current.song?.id;

    return (
        <>
            <ListItemButton
                onClick={handleClick}
                dense={true}
                component="div"
                sx={{ height: '60px' }}
            >
                <ListItemAvatar sx={{ position: 'relative' }}>
                    <PlayingBar size={48} playing={playing} selected={selected} />
                    <CustomAvator
                        alt={song.title}
                        src={song.thumbnail_url || ''}
                        variant="rounded"
                        width={48}
                        height={48}
                    />
                </ListItemAvatar>
                <ListItemText
                    sx={{ overflow: 'hidden', marginLeft: '4px' }}
                    primary={
                        <Typography noWrap={true} variant="body2">
                            {song.title}
                        </Typography>
                    }
                    secondary={
                        <Typography
                            component="p"
                            noWrap={true}
                            variant="caption"
                            sx={{ opacity: 0.6 }}
                        >
                            {song.artist?.name}
                        </Typography>
                    }
                />
            </ListItemButton>
            <Divider
                variant="inset"
                component="div"
                sx={{ marginLeft: '74px', marginRight: 2, borderWidth: 0.5 }}
            />
        </>
    );
};

export default SongItem;
