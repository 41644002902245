import React from 'react';
import { Box, List, useTheme } from '@mui/material';
import CustomListItem from '../ui/CustomListItem';
import { HOLOPLAYER_PATH } from '../../route/route';
import { ReactComponent as SplashLogo } from '../../img/splash-logo.svg';
import { ReactComponent as TwitterLogo } from '../../img/twitter.svg';
import { TwitterShareButton } from 'react-share';

const Other = () => {
    const theme = useTheme();
    return (
        <>
            <Box
                sx={{
                    backgroundColor: theme.palette.primary.main,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                    p: 2,
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: '120px',
                        maxWidth: '240px',
                    }}
                >
                    <SplashLogo width="100%" height="100%" />
                </Box>
                <TwitterShareButton
                    url="https://holoplayer.jp/"
                    title="ホロプレイヤー / ホロライブ オリジナル楽曲 非公式プレイヤー"
                    hashtags={['ホロライブ', 'ホロプレイヤー']}
                >
                    <Box
                        sx={{
                            color: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                            border: 'solid 1px rgba(255,255,255,.4)',
                            borderRadius: '12px',
                            p: '4px 12px',
                        }}
                    >
                        <TwitterLogo width="32px" />
                        シェア
                    </Box>
                </TwitterShareButton>
            </Box>
            <List>
                {/* <CustomListItem primary="アカウント" icon={<AccountIcon />} to={HOLOPLAYER_PATH.ACCOUNT} /> */}
                {/* <CustomListItem
                    primary={HOLOPLAYER_EXTERNAL_URL.ABOUT_HOLOPLAYER.LABEL}
                    to={HOLOPLAYER_EXTERNAL_URL.ABOUT_HOLOPLAYER.URL}
                    isExternal={true}
                /> */}
                <CustomListItem
                    primary="holoPlayerについて"
                    to={HOLOPLAYER_PATH.ABOUT}
                    isLoading={false}
                />
                <CustomListItem primary="設定" to={HOLOPLAYER_PATH.SETTING} isLoading={false} />
            </List>
        </>
    );
};

export default Other;
