import React, { useMemo, useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider, useRouteError } from 'react-router-dom';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from './app/hooks';
import useMediaQuery from '@mui/material/useMediaQuery';
import generateTheme from './lib/generateTheme';
import { routes } from './route/RoutesController';
import { CssBaseline, Fade, Box, GlobalStyles } from '@mui/material';
import Splash from './component/page/Splash';

import { loadAllSong, loadedError } from './slice/songSlice';
import Main from './Main';
import Error from './component/page/Error';
import { fetchAllSong } from './lib/fetch';
import SnackbarStation from './component/feature/info/SnackBarStation';

function App() {
    const dispatch = useAppDispatch();
    const setting = useAppSelector((state) => state.setting);
    const isLoaded = useAppSelector((state) => state.song.isLoaded);
    const hasError = useAppSelector((state) => state.song.hasError);

    // 曲一覧をfetch
    useEffect(() => {
        fetchAllSong().then((res) => {
            if (res) {
                dispatch(loadAllSong(res));
            } else {
                dispatch(loadedError());
                return;
            }
        });
    }, [dispatch]);

    // ライトモードとダークモードを切り替え
    const isDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = useMemo(() => {
        const theme = generateTheme(setting.brightness, isDarkMode);
        return createTheme(theme);
    }, [setting.brightness, isDarkMode]);

    // 1秒以上経過かつ読み込みが完了している場合に処理開始
    const [isPassed, setPassed] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setPassed(true);
        }, 1000);
    }, []);

    // ログイン処理
    // useEffect(() => {
    //     onAuthStateChanged(auth, (user) => {
    //         if (user) {
    //             dispatch(
    //                 setLogIn({
    //                     uid: user.uid,
    //                     photoURL: user.photoURL,
    //                     displayName: user.displayName,
    //                 }),
    //             );
    //         } else {
    //             dispatch(setLogOut());
    //         }
    //     });
    // }, [dispatch]);

    const ErrorBoundary = () => {
        const error = useRouteError();
        console.log(error);
        return <Box>hoge</Box>;
    };

    const router = createBrowserRouter([
        {
            path: '/',
            element: (
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <GlobalStyles
                        styles={{
                            body: {
                                backgroundColor: theme.palette.background.paper,
                            },
                        }}
                    />
                    <Box className="HoloPlayer">
                        <Fade in={!(isPassed && isLoaded)} appear={false}>
                            <div>
                                <Splash />
                            </div>
                        </Fade>
                        {isLoaded && hasError && <Error />}
                        {isLoaded && !hasError && <Main />}
                    </Box>
                    <SnackbarStation />
                </ThemeProvider>
            ),
            children: [
                ...routes.map(({ path, element }, index) => {
                    return {
                        path,
                        element,
                    };
                }),
            ],
            errorElement: <ErrorBoundary />,
        },
    ]);

    return <RouterProvider router={router} />;
}

export default App;
