import { Button, ButtonProps, CircularProgress, useTheme } from '@mui/material';

const CustomButton: React.FC<
    ButtonProps & {
        loading?: boolean;
    }
> = (_props) => {
    const props = {
        ..._props,
        loading: undefined,
    };
    const loading = _props.loading;
    const theme = useTheme();
    return (
        <Button
            {...props}
            startIcon={
                loading ? (
                    <CircularProgress
                        size="20px"
                        thickness={4}
                        sx={{ color: theme.palette.action.disabled }}
                    />
                ) : (
                    props.startIcon
                )
            }
            disabled={loading || props.disabled}
        >
            {props.children}
        </Button>
    );
};
export default CustomButton;
