import axios, { AxiosResponse } from 'axios';
import {
    GenerationApiResponseType,
    PersonApiResponseType,
    SongFilterByCreatorApiResponseType,
    SongApiResponseType,
    TalentApiResponseType,
    AlbumApiResponseType,
    UnitApiResponseType,
} from '../type/api-response.type';

const API_BASE_URL = process.env.REACT_APP_HOLOPLAYER_API_BASE;

const apiClient = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
});

export const axiosSong = async (
    params: {
        posts_per_page?: number;
        page?: number;
        unit?: number;
        generation?: number;
    } = {
        posts_per_page: 20,
        page: 1,
    },
): Promise<AxiosResponse<SongApiResponseType>> => {
    return await apiClient.get(`${API_BASE_URL}/song`, {
        params,
    });
};

export const axiosSongFilterByCreator = async (
    created_by: number,
    params: {
        posts_per_page?: number;
        page?: number;
    } = {
        posts_per_page: 20,
        page: 1,
    },
): Promise<AxiosResponse<SongFilterByCreatorApiResponseType>> => {
    return await apiClient.get(`${API_BASE_URL}/song-filter-by-creator`, {
        params: {
            ...params,
            created_by,
        },
    });
};

export const axiosTalent = async (
    params: {
        posts_per_page?: number;
        page?: number;
        order?: 'ASC' | 'DESC';
        orderby?: 'ID' | 'title' | 'published';
    } = {
        posts_per_page: 20,
        page: 1,
        order: 'ASC',
        orderby: 'ID',
    },
): Promise<AxiosResponse<TalentApiResponseType>> => {
    return await apiClient.get(`${API_BASE_URL}/talent`, {
        params,
    });
};

export const axiosGeneration = async (
    params: {
        posts_per_page?: number;
        page?: number;
        order?: 'ASC' | 'DESC';
        orderby?: 'ID' | 'title';
    } = {
        posts_per_page: 20,
        page: 1,
        order: 'ASC',
        orderby: 'ID',
    },
): Promise<AxiosResponse<GenerationApiResponseType>> => {
    return await apiClient.get(`${API_BASE_URL}/generation`, {
        params,
    });
};

export const axiosPerson = async (
    params: {
        posts_per_page?: number;
    } = {
        posts_per_page: 20,
    },
): Promise<AxiosResponse<PersonApiResponseType>> => {
    return await apiClient.get(`${API_BASE_URL}/person`, {
        params,
    });
};

export const axiosUnit = async (
    params: {
        posts_per_page?: number;
    } = {
        posts_per_page: 20,
    },
): Promise<AxiosResponse<UnitApiResponseType>> => {
    return await apiClient.get(`${API_BASE_URL}/unit`, {
        params,
    });
};

export const axiosAlbum = async (
    params: {
        posts_per_page?: number;
    } = {
        posts_per_page: 20,
    },
): Promise<AxiosResponse<AlbumApiResponseType>> => {
    return await apiClient.get(`${API_BASE_URL}/album`, {
        params,
    });
};
