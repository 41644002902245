import SongSheetBody from './SongSheetBody';
import SongSheetTabBar from './SongSheetTabBar';
import SongSheetHandle from './SongSheetHandle';

import BottomSheet from '../../ui/BottomSheet/BottomSheet';

const SongSheet = (props: {
    isOpen: boolean;
    isFull: boolean;
    doClose: () => void;
    doFullscreen: () => void;
    doExitFullscreen: () => void;
}) => {
    const { isOpen, isFull, doClose, doFullscreen, doExitFullscreen } = props;
    const draggable = true;

    return (
        <BottomSheet
            isOpen={isOpen}
            isFull={isFull}
            doClose={doClose}
            doFullscreen={doFullscreen}
            doExitFullscreen={doExitFullscreen}
            handle={
                <SongSheetHandle
                    isFull={isFull}
                    doClose={doClose}
                    doFullscreen={doFullscreen}
                    doExitFullscreen={doExitFullscreen}
                    draggable={draggable}
                />
            }
            draggable={draggable}
        >
            <SongSheetBody />
            <SongSheetTabBar />
        </BottomSheet>
    );
};

export default SongSheet;
