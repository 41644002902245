import { Box, Button } from '@mui/material';
import Handle from '../../ui/BottomSheet/Handle';
import { useAppSelector } from '../../../app/hooks';
import useQuiz from '../../../slice/quiz/useQuiz';

const QuizSheetHandle = ({ draggable }: { draggable: boolean }) => {
    const quizSlice = useAppSelector((state) => state.quiz);
    const Quiz = useQuiz();

    const title = (() => {
        const ended = Quiz.Finished();
        if (ended) return '結果';

        const mode = Quiz.GetModeLabel();
        const start = Quiz.GetStartPositionLabel();
        const scope =
            quizSlice.current.mode === 'zenkoutei' ? ': ' + Quiz.GetScopeLabel() + 'の曲' : '';
        return `${mode}(${start})${scope}`;
    })();

    return (
        <Handle draggable={draggable}>
            <Box
                sx={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    flexGrow: 1,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    textAlign: 'left',
                }}
            >
                {title}
            </Box>
            <Box
                sx={[
                    {
                        width: '100px',
                        flexShrink: 0,
                        padding: 0,
                        textAlign: 'right',
                        justifyContent: 'end',
                        display: 'flex',
                    },
                ]}
            >
                {Quiz.ShownResult() ? (
                    <Button
                        onClick={() => {
                            Quiz.ExitQuiz();
                        }}
                    >
                        終了
                    </Button>
                ) : (
                    <Button
                        onClick={() => {
                            Quiz.DisplayScore();
                        }}
                    >
                        採点
                    </Button>
                )}
            </Box>
        </Handle>
    );
};

export default QuizSheetHandle;
