import { Box, Tabs, Tab, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { HOLOPLAYER_ZINDEX } from '../../../constants';
import { getSafeAreaInsetBottom } from '../../../lib/misc';
import { changeSheetTab, tabs_map } from '../../../slice/sheetSlice';

const SongSheetTabBar = () => {
    const dispatch = useAppDispatch();
    const sheet = useAppSelector((state) => state.sheet);
    const theme = useTheme();
    const safeMarginBottom = useMemo(() => getSafeAreaInsetBottom(), []);
    const margin = '8px';
    return (
        <Box
            sx={{
                position: 'fixed',
                left: margin,
                right: margin,
                bottom: sheet.isOpen ? margin : '-100%',
                zIndex: HOLOPLAYER_ZINDEX.SHEET_TAB,
                transition: theme.transitions.create(['bottom', 'backgroundColor'], {
                    duration: theme.transitions.duration.complex,
                }),
                marginBottom: `${safeMarginBottom}px`,
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Tabs
                scrollButtons="auto"
                variant="scrollable"
                value={sheet.tab}
                onChange={(e, value) => {
                    dispatch(changeSheetTab(value));
                }}
                TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
                sx={[
                    {
                        borderRadius: '16px',
                        backdropFilter: 'blur(16px)',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: theme.palette.divider,
                        display: 'inline-flex',
                    },
                    {
                        '.MuiTab-textColorPrimary.Mui-selected': {
                            color: theme.palette.text.primary,
                            fontWeight: 'bold',
                        },
                    },
                    {
                        '.MuiTabs-indicator': {
                            display: 'flex',
                            justifyContent: 'center',
                            backgroundColor: 'transparent',
                        },
                    },
                    {
                        '.MuiTabs-indicatorSpan': {
                            backgroundColor: theme.palette.text.primary,
                            maxWidth: '40px',
                            width: '100%',
                        },
                    },
                ]}
            >
                {tabs_map.map((tab, index) => (
                    <Tab key={index} label={tab.label} value={tab.value} />
                ))}
            </Tabs>
        </Box>
    );
};

export default SongSheetTabBar;
