import { Box, List } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import { HOLOPLAYER_PATH } from '../../../route/route';
import CustomListItem from '../../ui/CustomListItem';
import NavigationBar from '../../ui/NavigationBar/NavigationBar';
import { useEffect, useMemo, useRef, useState } from 'react';
import { hiraganaToKatakana, katakanaToHiragana } from '../../../lib/misc';
import { FixedSizeList } from 'react-window';
import usePerson from '../../../slice/person/usePerson';
import NoContent from '../../ui/NoContent';
import { DEFAULT_LOADING_SKELETON_COUNT } from '../../../constants';

const PersonCollectionPage = () => {
    const Person = usePerson();
    const ref = useRef<HTMLElement>(null);
    const songState = useAppSelector((state) => state.song);

    useEffect(() => {
        Person.fetchPerson();
    }, [Person]);

    useEffect(() => {
        Person.computeSongCount();
    }, [Person, songState.isLoaded, Person.loadStatus]);

    const [countDisplay, setCountDisplay] = useState<string>('');
    const [query, setQuery] = useState<string>('');
    const [targetHeight, setHeight] = useState<number>(0);

    const persons = useMemo(() => {
        let returnArray = Person.items;
        if (!query) return returnArray;
        const hiragana = katakanaToHiragana(query);
        const katakana = hiraganaToKatakana(query);

        return returnArray.filter((person) => {
            return new RegExp(`(${hiragana}|${katakana})`, 'igms').test(person.name);
        });
    }, [query, Person]);

    useEffect(() => {
        if (Person.isLoaded) {
            setCountDisplay(` ( ${persons.length} )`);
        } else {
            setCountDisplay('');
        }
    }, [query, Person.isLoaded, persons.length]);

    const handleChangeHeight = (): void => {
        const raw = ref?.current?.clientHeight;
        const clienghtHeight = parseInt(String(raw), 10) || 0;
        if (clienghtHeight > 0) {
            setHeight(clienghtHeight);
        }
    };

    useEffect(() => {
        handleChangeHeight();
    }, [Person.isLoaded]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflow: 'hidden',
            }}
        >
            <NavigationBar
                title={`作詞/作曲/編曲/箱外歌唱${countDisplay}`}
                to={HOLOPLAYER_PATH.LIBRARY}
                query={query}
                setQuery={setQuery}
                placeholder="畑亜貴、DECO*27、堀江晶太、戌亥とこ..."
            />
            <Box
                ref={ref}
                sx={{
                    flexGrow: 1,
                }}
            >
                <List>
                    {persons.length > 0 ? (
                        <FixedSizeList
                            itemData={persons}
                            itemCount={persons.length}
                            itemSize={61}
                            width="100%"
                            height={targetHeight}
                        >
                            {({ index, style }) => {
                                return (
                                    <div style={style}>
                                        <CustomListItem
                                            key={index}
                                            primary={`${persons[index].name}`}
                                            to={
                                                HOLOPLAYER_PATH.LIBRARY_PERSON_COLLECTION +
                                                `/${persons[index].id}`
                                            }
                                            tertiary={
                                                persons[index].count
                                                    ? `${persons[index].count}`
                                                    : undefined
                                            }
                                            isLoading={!Person.isLoaded}
                                        />
                                    </div>
                                );
                            }}
                        </FixedSizeList>
                    ) : Person.isLoaded ? (
                        <NoContent />
                    ) : (
                        Array(DEFAULT_LOADING_SKELETON_COUNT)
                            .fill(null)
                            .map((item, index) => (
                                <CustomListItem key={index} primary={''} isLoading={true} />
                            ))
                    )}
                </List>
            </Box>
        </Box>
    );
};

export default PersonCollectionPage;
