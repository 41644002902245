import React, { useEffect } from 'react';
import Login from '../ui/Login';
import { useAppSelector } from '../../app/hooks';
import { Box, LinearProgress } from '@mui/material';

import Logined from './Logined';
import { getAuth, getRedirectResult, onAuthStateChanged } from 'firebase/auth';
import useAccount from '../../lib/useAccount';
import { authenticate } from '../../app/youtube-api';

const YouTube = () => {
    const account = useAppSelector((state) => state.account);
    const Account = useAccount();

    const auth = getAuth();

    useEffect(() => {
        getRedirectResult(auth).then((result) => {
            if (result) {
                Account.logIn(result);
            } else {
                Account.continueGuest();
            }
        });
    }, [Account, auth]);

    useEffect(() => {
        onAuthStateChanged(auth, (result) => {
            if (result) {
                Account.continueSession(result);
            } else {
                Account.continueGuest();
            }
        });
    }, [account, Account, auth]);

    useEffect(() => {
        if (!account.isLogin) return;

        authenticate();
    }, [account.isLogin]);

    return (
        <>
            {account.isLoading ? <LinearProgress /> : null}

            {account.isLogin ? (
                <Logined />
            ) : (
                <Box sx={{ padding: '24px', textAlign: 'center' }}>
                    <Login />
                </Box>
            )}
        </>
    );
};

export default YouTube;
