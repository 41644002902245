import { Badge, Box, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import SortMenu from '../../feature/collection/SortMenu';
import { SearchOrderByType, SearchOrderType } from '../../../slice/searchSlice';

const NavRight = ({
    sort,
    isShowInput,
    setShowInput,
    handleClickSetting,
    setQuery,
    settingCount,
}: {
    sort?: {
        order: SearchOrderType;
        orderBy: SearchOrderByType;
        handleChangeSort: (order: SearchOrderType, orderNy: SearchOrderByType) => void;
    };
    isShowInput: boolean;
    setShowInput: (isShowInput: boolean) => void;
    handleClickSetting?: () => void;
    setQuery?: (value: string) => void;
    settingCount?: number;
}) => {
    if (setQuery || handleClickSetting) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '4px',
                }}
            >
                {!isShowInput && (
                    <IconButton
                        onClick={(e) => {
                            setShowInput(!isShowInput);
                        }}
                        // color={query ? 'primary' : 'default'}
                    >
                        <SearchIcon />
                    </IconButton>
                )}
                {handleClickSetting && (
                    <IconButton onClick={handleClickSetting}>
                        <Badge badgeContent={settingCount} color="primary">
                            <TuneIcon />
                        </Badge>
                    </IconButton>
                )}
                {sort && (
                    <SortMenu
                        order={sort.order}
                        orderBy={sort.orderBy}
                        handleChangeSort={sort.handleChangeSort}
                    />
                )}
            </Box>
        );
    } else {
        return <></>;
    }
};

export default NavRight;
