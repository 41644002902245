import { Box, useTheme } from '@mui/material';
import DateDisplay from '../../../../ui/DateDisplay';
import { useMemo } from 'react';
import YouTubeLinkButton from '../../../collection/detail/YouTubeLinkButton';
import useSong from '../../../collection/useSong';
import MagicSongChipGroup from '../../../collection/detail/MagicSongChipGroup';

const InfoTab = () => {
    const theme = useTheme();
    const Song = useSong();
    const song = Song.current.song;

    return useMemo(() => {
        return (
            <Box
                sx={{
                    padding: '16px',
                }}
            >
                {song &&
                    [
                        {
                            title: '曲名',
                            detail: song.title || undefined,
                        },
                        {
                            title: 'アーティスト',
                            detail: MagicSongChipGroup([song.artist], Song.navigateTo),
                        },
                        {
                            title: '歌唱タレント',
                            detail: MagicSongChipGroup(song.talent, Song.navigateTo),
                        },
                        {
                            title: '歌唱ゲスト',
                            detail: MagicSongChipGroup(song.guest, Song.navigateTo),
                        },
                        {
                            title: '作曲',
                            detail: MagicSongChipGroup(song.composer, Song.navigateTo),
                        },
                        {
                            title: '編曲',
                            detail: MagicSongChipGroup(song.arranger, Song.navigateTo),
                        },
                        {
                            title: '作詞',
                            detail: MagicSongChipGroup(song.lyricist, Song.navigateTo),
                        },
                        {
                            title: '音源公開日',
                            detail: song.published_YYYYMMDD ? (
                                <DateDisplay raw={song.published_YYYYMMDD} />
                            ) : undefined,
                        },
                        {
                            title: 'YouTube',
                            detail: (
                                <Box>
                                    <YouTubeLinkButton video_id={song.video_id.mv} label={'MV'} />
                                    <YouTubeLinkButton
                                        video_id={song.video_id.topic}
                                        label={'フル'}
                                    />
                                </Box>
                            ),
                        },
                    ].map(({ title, detail }: { title: any; detail: any }, index) => (
                        <Box sx={{ marginBottom: '32px' }} key={index}>
                            <Box
                                sx={{
                                    fontSize: '12px',
                                    color: theme.palette.text.secondary,
                                    marginBottom: '4px',
                                }}
                            >
                                {title}
                            </Box>
                            {detail ? (
                                <Box sx={{ fontSize: '18px' }}>{detail}</Box>
                            ) : (
                                <Box sx={{ color: theme.palette.text.secondary }}>(登録なし)</Box>
                            )}
                        </Box>
                    ))}
            </Box>
        );
    }, [song, theme.palette.text.secondary, Song]);
};
export default InfoTab;
