import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    addError,
    clearError,
    displayScore,
    exitQuiz,
    QuizErrorItemType,
    QuizModeType,
    QuizTalentScopeType,
    QuizSongItemType,
    QuizStartPositionLabelType,
    QuizStartPositionValueType,
    setFullscreen,
    setQuizMode,
    setQuizPlaylist,
    setScope,
    setStartPosition,
    startQuizBaseSongCollection,
    completeQuizBaseSongCollection,
    QuizModeTypeArray,
} from './quizSlice';
import { SongItemType, TalentItemType } from '../../type/hololive.types';
import { webWorkerAsync } from '../../lib/webWorker';
import useSong from '../../component/feature/collection/useSong';
import { prepareQuizBaseSongCollectionWebWorkerTask } from './prepareQuizBaseSongCollectionWebWorkerTask';
import useTalent from '../talent/useTalent';
import { shuffleArray } from '../../lib/misc';

export type QuizStatusType = {
    correct: QuizSongItemType[];
    incorrect: QuizSongItemType[];
    unanswered: QuizSongItemType[];
    answered: QuizSongItemType[];
    done: number;
    current: number;
    playlist: QuizSongItemType[];
};

const useQuiz = () => {
    const Song = useSong();
    const Talent = useTalent();
    const dispatch = useAppDispatch();
    const quiz = useAppSelector((state) => state.quiz);

    const {
        talentScope,
        done,
        current,
        quizBaseSongCollectionStatus,
        quizBaseSongCollection,
        startPosition,
    } = useAppSelector((state) => state.quiz);

    const AddError = (error: QuizErrorItemType): void => {
        dispatch(addError(error));
    };

    const ClearError = (): void => {
        dispatch(clearError());
    };

    const GetScopeTalent = (_scope: QuizTalentScopeType): TalentItemType | undefined => {
        return Talent.items.find((talent) => talent.en_kebab === _scope);
    };

    /**
     * 直前に回答した曲を取得
     */
    const GetBeforeQuiz = (): QuizSongItemType | null => {
        let i = 0;
        current.playlist.some((song, index) => {
            if (song.origin.id === current.song?.id) {
                i = index;
                return true;
            }
            return false;
        });

        if (i === 0) return null;
        return current.playlist[i - 1];
    };

    /**
     * クイズのベースとなる曲の配列を用意する
     * video_idがある曲にフィルタし、クイズに必要な情報を生成してstateに入れとく
     */
    const readyQuiz = () => {
        if (!Song.isLoaded) return;
        if (!Talent.isLoaded) return;
        if (quizBaseSongCollectionStatus === 'ready') {
            dispatch(startQuizBaseSongCollection());
            webWorkerAsync<SongItemType[], SongItemType[]>(
                prepareQuizBaseSongCollectionWebWorkerTask,
                Song.items,
            ).then((done) => {
                if (done.length > 0) {
                    dispatch(completeQuizBaseSongCollection(done));
                }
            });
        }
    };

    const isReadyQuiz = (): boolean => {
        return Song.isLoaded && Talent.isLoaded && quizBaseSongCollectionStatus === 'done';
    };

    const _getQuizPlayList = (_scope: QuizTalentScopeType): SongItemType[] => {
        let playlist = [...shuffleArray(quizBaseSongCollection)];
        if (_scope === 'all') {
        } else {
            const talent = GetScopeTalent(_scope);
            if (!talent) return [];
            playlist = [
                ...playlist.filter((song) => {
                    return song.talent?.some((item) => item.en_kebab === talent.en_kebab);
                }),
            ];
        }
        return playlist;
    };

    const _setQuizPlaylist = (playlist: SongItemType[]): void => {
        if (playlist.length > 0) {
            // ok
            ClearError();
            dispatch(
                setQuizPlaylist({
                    playlist,
                }),
            );
        } else {
            // ng
            AddError({
                id: 'Not Found Song',
                msg: '曲が見つかりませんでした',
            });
        }
    };

    const SetScope = (talentScope: QuizTalentScopeType): void => {
        dispatch(setScope(talentScope));
    };

    const GetScopeLabel = (): string => {
        if (talentScope === 'all') return 'すべて';
        const talent = GetScopeTalent(talentScope);
        if (talent) return talent.jp_formal;
        return 'エラー';
    };

    const SetMode = (mode: QuizModeType): void => {
        dispatch(setQuizMode(mode));
    };

    const GetModeLabel = (): string => {
        const label = QuizModeTypeArray.find((mode) => mode.value === quiz.current.mode)?.label;
        if (label) {
            return label;
        }
        return 'エラー';
    };

    const StartAenbienQuiz = (): void => {
        SetScope('all');
        const playlist = _getQuizPlayList('all');
        SetMode('aenbien');
        _setQuizPlaylist(playlist);
    };

    const StartYubiyubiQuiz = (): void => {
        SetScope('all');
        const playlist = _getQuizPlayList('all');
        SetMode('yubiyubi');
        _setQuizPlaylist(playlist);
    };

    const StartZenkouteiQuiz = (): void => {
        const playlist = _getQuizPlayList(talentScope);
        SetMode('zenkoutei');
        _setQuizPlaylist(playlist);
    };

    const ExitQuiz = (): void => {
        dispatch(exitQuiz());
    };

    const DoFullscreen = (): void => {
        dispatch(setFullscreen(true));
    };

    const DoExitFullscreen = (): void => {
        dispatch(setFullscreen(false));
    };

    const DisplayScore = (): void => {
        dispatch(displayScore());
    };

    /**
     * クイズが終了している
     * ただし、ユーザーが採点を押した場合をのぞく
     */
    const Finished = (): boolean => {
        const { incorrect } = GetQuizStatus();
        if (current.mode === 'aenbien') {
            return incorrect.length >= 1;
        }
        if (current.mode === 'yubiyubi') {
            return incorrect.length >= 5;
        }
        return done;
    };

    /**
     * クイズが終了している
     * ただし、ユーザーが採点を押した場合を含む
     */
    const ShownResult = (): boolean => {
        if (done) return true;

        return Finished();
    };

    const GetStartPosition = (): QuizStartPositionValueType => {
        return quiz.startPosition;
    };
    const GetStartPositionLabel = (): QuizStartPositionLabelType | 'エラー' => {
        const startPosition = GetStartPosition();
        if (startPosition === 'sabi') return 'サビ';
        if (startPosition === 'intro') return 'イントロ';
        if (startPosition === 'random') return 'ランダム';
        return 'エラー';
    };

    const SetStartPosition = (_val: string): void => {
        let val: QuizStartPositionValueType | undefined = undefined;
        if (_val === 'sabi') {
            val = _val;
        } else if (_val === 'intro') {
            val = _val;
        } else if (_val === 'random') {
            val = _val;
        } else {
            val = 'sabi';
        }
        dispatch(setStartPosition(val));
    };

    const GetQuizStatus = (): QuizStatusType => {
        const correct = current.playlist.filter((song) => song.correct === 'correct');
        const incorrect = current.playlist.filter((song) => song.correct === 'incorrect');
        const unanswered = current.playlist.filter((song) => song.correct === 'unanswered');
        const answered = current.playlist.filter((song) => song.correct !== 'unanswered');

        let done = 0;
        current.playlist.some((_s, index) => {
            if (_s.origin === current.song) {
                done = index;
                return true;
            }
            return false;
        });

        return {
            correct,
            incorrect,
            unanswered,
            answered,
            done,
            current: done + 1,
            playlist: current.playlist,
        };
    };

    return {
        SetScope,
        StartAenbienQuiz,
        StartYubiyubiQuiz,
        StartZenkouteiQuiz,
        ExitQuiz,
        DoFullscreen,
        DoExitFullscreen,
        DisplayScore,
        GetQuizStatus,
        Finished,
        ShownResult,
        GetStartPosition,
        SetStartPosition,
        GetStartPositionLabel,
        GetModeLabel,
        GetScopeLabel,
        GetBeforeQuiz,
        talentScope,
        hadPlayed: quiz.hadPlayed,
        current,
        readyQuiz,
        isReadyQuiz,
        startPosition,
    };
};
export default useQuiz;
