import { useLocation, useParams } from 'react-router-dom';
import { HOLOPLAYER_PATH } from '../../../route/route';
import SongCollection from '../../feature/collection/SongCollection';
import { Box } from '@mui/material';
import NavigationBar from '../../ui/NavigationBar/NavigationBar';
import { useEffect, useMemo } from 'react';
import { UnitItemType } from '../../../type/hololive.types';
import useUnit from '../../../slice/unit/useUnit';
import NoContent from '../../ui/NoContent';

const NotFoundUnit = () => {
    return (
        <Box>
            <NavigationBar title="ユニットの曲" to={HOLOPLAYER_PATH.LIBRARY_UNIT_COLLECTION} />
            <NoContent />
        </Box>
    );
};

const UnitSongPage = () => {
    const params = useParams();
    const { pathname } = useLocation();
    const Unit = useUnit();

    useEffect(() => {
        Unit.fetchUnit();
    }, [Unit]);

    const currentUnit = useMemo<'loading' | UnitItemType | 'notFound'>(() => {
        if (Unit.isLoaded) {
            if (!params.id) return 'notFound';
            const currentUnitId = parseInt(params.id);
            const unit = Unit.items.find((item) => item.id === currentUnitId);
            if (unit) {
                return unit;
            }
        } else {
            return 'loading';
        }
        return 'notFound';
    }, [Unit.isLoaded, Unit.items, params.id]);

    useEffect(() => {
        if (currentUnit !== 'loading' && currentUnit !== 'notFound') {
            Unit.fetchUnitSong(currentUnit);
        }
    }, [currentUnit, Unit]);

    if (currentUnit === 'notFound') return <NotFoundUnit />;

    if (
        currentUnit === 'loading' ||
        currentUnit?.songs?.status === 'ready' ||
        currentUnit?.songs?.status === 'loading'
    ) {
        return (
            <SongCollection
                collection={'loading'}
                navigation={{
                    title: '',
                    to: HOLOPLAYER_PATH.LIBRARY_TALENT_COLLECTION,
                }}
                searchId={pathname}
            />
        );
    }

    return (
        <SongCollection
            collection={currentUnit?.songs?.all || []}
            navigation={{
                title: currentUnit.name,
                to: HOLOPLAYER_PATH.LIBRARY_UNIT_COLLECTION,
            }}
            searchId={pathname}
        />
    );
};

export default UnitSongPage;
