import { Button, Typography, Box } from '@mui/material';

import FlashOnRoundedIcon from '@mui/icons-material/FlashOnRounded';
import RepeatIcon from '../../../../ui/RepeatIcon';

import usePlayer from '../../../player/usePlayer';
import { useAppSelector } from '../../../../../app/hooks';
import { useState } from 'react';
import CustomMenu from '../../../../ui/CustomMenu';
import {
    RepeatType,
    RepeatTypeAndLabelArray,
    SabiMedleyType,
    SabiMedleyTypeAndLabelArray,
} from '../../../../../slice/settingSlice';
import { useSwiper } from 'swiper/react';

const VerticalButton = (props: {
    icon: any;
    label: any;
    active?: boolean;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}) => {
    const { icon, label, active, onClick } = props;

    return (
        <Button
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexShrink: 0,
                flexGorw: 0,
                opacity: active ? 1 : 0.6,
            }}
            color={active ? 'primary' : 'inherit'}
            onClick={onClick}
        >
            <Box
                sx={{
                    display: 'flex',
                }}
            >
                {icon}
            </Box>
            <Typography
                variant="overline"
                sx={{
                    fontSize: 10,
                    fontWeight: active ? 'bold' : 'normal',
                }}
                color={active ? 'primary' : 'inherit'}
            >
                {label}
            </Typography>
        </Button>
    );
};

const ActionButtonLine = () => {
    const Player = usePlayer();
    const setting = useAppSelector((state) => state.setting);

    const swiper = useSwiper();

    const [isOpenRepeatMenu, setIsOpenRepeatMenu] = useState<boolean>(false);
    const [repeatMenuAnchorEl, setRepeatMenuAnchorEl] = useState<null | HTMLElement>(null);

    const [isOpenSabiMedleyMenu, setIsOpenSabiMedleyMenu] = useState<boolean>(false);
    const [sabiMedleyMenuAnchorEl, setSabiMedleyMenuAnchorEl] = useState<null | HTMLElement>(null);
    return (
        <Box
            sx={{
                display: 'flex',
                gap: '16px',
                flexWap: 'nowrap',
                overflowX: 'auto',
                padding: '12px 16px',
            }}
            onTouchStart={() => {
                swiper.disable();
            }}
            onTouchEnd={() => {
                swiper.enable();
            }}
        >
            <VerticalButton
                icon={<FlashOnRoundedIcon />}
                label="サビメドレー"
                active={setting.sabiMedley}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                    setIsOpenSabiMedleyMenu(!isOpenSabiMedleyMenu);
                    setSabiMedleyMenuAnchorEl(e.currentTarget);
                }}
            />
            <CustomMenu<SabiMedleyType>
                handleChange={(newValue) => {
                    Player.ChangeSabiMedley(newValue);
                }}
                anchorEl={sabiMedleyMenuAnchorEl}
                setAnchorEl={setSabiMedleyMenuAnchorEl}
                labelAndValueMap={SabiMedleyTypeAndLabelArray}
                value={setting.sabiMedley}
            />

            <VerticalButton
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                    setIsOpenRepeatMenu(!isOpenRepeatMenu);
                    setRepeatMenuAnchorEl(e.currentTarget);
                }}
                icon={<RepeatIcon />}
                label="リピート"
                active={setting.repeat === 'all' || setting.repeat === 'one'}
            />
            <CustomMenu<RepeatType>
                handleChange={(newValue) => {
                    Player.ChangeRepeat(newValue);
                }}
                anchorEl={repeatMenuAnchorEl}
                setAnchorEl={setRepeatMenuAnchorEl}
                labelAndValueMap={RepeatTypeAndLabelArray}
                value={setting.repeat}
            />
            {/* <VerticalButton
                icon={<FavoriteBorderRoundedIcon />}
                label="好き"
                onClick={() => dispatch(setIncompleteFunctionDialog(true))}
            />
            <VerticalButton
                icon={<LightbulbRoundedIcon />}
                label="覚えたい"
                onClick={() => dispatch(setIncompleteFunctionDialog(true))}
            />
            <VerticalButton
                icon={<CheckCircleOutlineRoundedIcon />}
                label="覚えた"
                onClick={() => dispatch(setIncompleteFunctionDialog(true))}
            /> */}
        </Box>
    );
};
export default ActionButtonLine;
