import React, { ReactNode } from 'react';
import { Box, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { completionLoad, setBrightness, setSeekSeconds } from '../../slice/settingSlice';
import { useEffect } from 'react';
import FireStoreUser from '../../app/rest';
import usePlayer from '../feature/player/usePlayer';

const SettingPage = () => {
    const dispatch = useAppDispatch();
    const setting = useAppSelector((state) => state.setting);
    const account = useAppSelector((state) => state.account);

    const Player = usePlayer();

    useEffect(() => {
        if (account?.uid) {
            FireStoreUser.getUser(account.uid).then((data) => {
                dispatch(completionLoad());
                if (data && data.brightness) {
                    dispatch(setBrightness(data.brightness));
                }
            });
        }
    }, [account.uid, dispatch]);

    useEffect(() => {
        if (account.uid) {
            FireStoreUser.setBrightness(account.uid, setting.brightness);
        }
    }, [setting.brightness, account.uid]);

    const handleChangeBrightness = (e: any, newValue: any) => {
        if (newValue) {
            dispatch(setBrightness(newValue));
        }
    };

    const PropertyName = (props: { children: ReactNode }) => {
        const { children } = props;
        return <Typography gutterBottom={true}>{children}</Typography>;
    };
    const Property = (props: { children: ReactNode }) => {
        const { children } = props;
        return <Box sx={{ marginBottom: '40px' }}>{children}</Box>;
    };
    const PropertyValueToggleButtonGroup = (props: {
        children?: ReactNode;
        choices: { value: string | number | boolean; label: string; icon?: ReactNode }[];
        onChange: (e: any, newValue: any) => void;
        value: any;
    }) => {
        const { choices, onChange, value } = props;
        return (
            <ToggleButtonGroup value={value} exclusive onChange={onChange} sx={{ width: 1 }}>
                {choices.map((choice, index) => (
                    <ToggleButton
                        key={index}
                        sx={{ width: 1, display: 'flex', gap: '8px' }}
                        value={choice.value}
                    >
                        {choice.icon && choice.icon} {choice.label}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        );
    };

    return (
        <Box sx={{ p: 2 }}>
            <Property>
                <PropertyName>ダークモード</PropertyName>
                <PropertyValueToggleButtonGroup
                    choices={[
                        {
                            value: 'system',
                            label: 'システム',
                            icon: <SettingsBrightnessIcon />,
                        },
                        {
                            value: 'light',
                            label: 'ライト',
                            icon: <WbSunnyIcon />,
                        },
                        {
                            value: 'dark',
                            label: 'ダーク',
                            icon: <DarkModeIcon />,
                        },
                    ]}
                    value={setting.brightness}
                    onChange={handleChangeBrightness}
                />
            </Property>
            <Property>
                <PropertyName>巻き戻し・早送り秒数</PropertyName>
                <PropertyValueToggleButtonGroup
                    choices={[
                        {
                            value: 5,
                            label: '5秒',
                        },
                        {
                            value: 10,
                            label: '10秒',
                        },
                        {
                            value: 30,
                            label: '30秒',
                        },
                    ]}
                    value={setting.seekSeconds}
                    onChange={(e, newValue) => {
                        dispatch(setSeekSeconds(newValue));
                    }}
                />
            </Property>
            <Property>
                <PropertyName>リピート</PropertyName>
                <PropertyValueToggleButtonGroup
                    choices={[
                        {
                            value: 'all',
                            label: '全曲',
                        },
                        {
                            value: 'one',
                            label: '1曲',
                        },
                        {
                            value: 'disabled',
                            label: 'しない',
                        },
                    ]}
                    value={setting.repeat}
                    onChange={(e, newValue) => {
                        Player.ChangeRepeat(newValue);
                    }}
                />
            </Property>
            <Property>
                <PropertyName>サビメドレー</PropertyName>
                <PropertyValueToggleButtonGroup
                    choices={[
                        {
                            value: true,
                            label: '有効',
                        },
                        {
                            value: false,
                            label: '無効',
                        },
                    ]}
                    value={setting.sabiMedley}
                    onChange={(e, newValue) => {
                        Player.ChangeSabiMedley(newValue);
                    }}
                />
            </Property>
            <Property>
                <PropertyName>YouTubeコントローラ</PropertyName>
                <PropertyValueToggleButtonGroup
                    choices={[
                        {
                            value: true,
                            label: '有効',
                        },
                        {
                            value: false,
                            label: '無効',
                        },
                    ]}
                    value={setting.playerControls}
                    onChange={(e, newValue) => {
                        Player.ChangePlayerControls(newValue);
                    }}
                />
            </Property>
        </Box>
    );
};

export default SettingPage;
