import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchAllSongFilterByCreator, fetchAllPerson } from '../../lib/fetch';
import { webWorkerAsync } from '../../lib/webWorker';
import { SongItemType, PersonItemType } from '../../type/hololive.types';
import {
    startLoadPerson,
    completeLoadPerson,
    startComputeSongCount,
    completecComputeSongCount,
    startFetchPeronSong,
    completeFetchPersonSong,
} from './personSlice';

const task = (
    message: MessageEvent<{
        persons: PersonItemType[];
        songs: SongItemType[];
    }>,
): PersonItemType[] => {
    const persons = message.data.persons;
    const songs = message.data.songs;
    return persons
        .map((person: any) => ({
            ...person,
            count:
                songs.filter((song: any) => {
                    return [song.composer, song.arranger, song.lyricist, song.guest]
                        .flat()
                        .some((p) => p?.id === person.id);
                }).length || 0,
        }))
        .sort((a: any, b: any) => {
            if (a.count < b.count) return 1;
            if (a.count > b.count) return -1;
            return 0;
        });
};

const usePerson = () => {
    const { isLoaded, items, computeStatus, loadStatus } = useAppSelector((state) => state.person);
    const SongState = useAppSelector((state) => state.song);
    const dispatch = useAppDispatch();

    const fetchPerson = () => {
        if (loadStatus === 'ready') {
            dispatch(startLoadPerson());
            fetchAllPerson().then((res) => {
                if (res) {
                    dispatch(completeLoadPerson(res));
                }
            });
        }
    };

    const computeSongCount = () => {
        if (!isLoaded) return;
        if (!SongState.isLoaded) return;
        if (computeStatus === 'ready') {
            dispatch(startComputeSongCount());
            webWorkerAsync<
                {
                    persons: PersonItemType[];
                    songs: SongItemType[];
                },
                PersonItemType[]
            >(task, { persons: items, songs: SongState.items }).then((done) => {
                if (done.length > 0) {
                    dispatch(completecComputeSongCount(done));
                }
            });
        }
    };

    const fetchPersonSong = (person: PersonItemType) => {
        if (person?.songs?.status === 'ready') {
            dispatch(startFetchPeronSong(person.id));

            fetchAllSongFilterByCreator(person.id).then((songs) => {
                dispatch(
                    completeFetchPersonSong({
                        person,
                        songs: {
                            ...songs,
                            status: 'loaded',
                        },
                    }),
                );
            });
        }
    };

    return {
        loadStatus,
        isLoaded,
        items,
        fetchPerson,
        computeSongCount,
        fetchPersonSong,
    };
};
export default usePerson;
