import dayjs from 'dayjs';

const DateDisplay = ({ raw }: { raw: string | undefined }) => {
    if (!raw) return <span></span>;
    const parsed = dayjs(raw);
    if (!parsed.isValid) {
        return <span></span>;
    }
    return <span>{parsed.format('YYYY年MM月DD日')}</span>;
};

export default DateDisplay;
