import { SongItemType } from '../../../type/hololive.types';

type CollectionPrevNextCoreType = {
    next: SongItemType | null;
    prev: SongItemType | null;
    current: SongItemType | null;
} | null;

const CollectionPrevNextCore = (
    song: SongItemType | undefined,
    _collection: SongItemType[],
    loop: boolean,
    filterSabi: boolean,
): CollectionPrevNextCoreType => {
    if (!song) return null;
    const collection = _collection.filter((_song) => {
        if (_song === song) return true;
        if (!filterSabi) return true;
        return _song.sabi?.start && _song?.sabi?.end;
    });

    let current_index: number = 0;
    let next: SongItemType | null = null;
    let prev: SongItemType | null = null;

    const has_song = collection.some((_song, _index) => {
        if (song.id === _song.id) {
            current_index = _index;
            return true;
        }
        return false;
    });

    if (!has_song) return null;

    const is_last = collection.length === current_index + 1;
    if (loop && is_last) {
        next = collection[0];
    } else if (is_last) {
        next = null;
    } else {
        next = collection[current_index + 1];
    }

    // プレイリストの最初だった場合は最後の曲を返す
    const is_first = current_index === 0;
    if (loop && is_first) {
        prev = collection[collection.length - 1];
    } else if (is_first) {
        prev = null;
    } else {
        prev = collection[current_index - 1];
    }

    return {
        current: song,
        prev,
        next,
    };
};

const getNextVideo = (
    song: SongItemType | undefined,
    _collection: SongItemType[],
    loop: boolean,
    filterSabi: boolean,
): SongItemType | null => {
    return CollectionPrevNextCore(song, _collection, loop, filterSabi)?.next || null;
};

const getPrevVideo = (
    song: SongItemType | undefined,
    _collection: SongItemType[],
    loop: boolean,
    filterSabi: boolean,
): SongItemType | null => {
    return CollectionPrevNextCore(song, _collection, loop, filterSabi)?.prev || null;
};

export { getNextVideo, getPrevVideo };
