import { Alert, Box, Button, Snackbar } from '@mui/material';
import React from 'react';
import { InfoType } from '../../../slice/infoSlice';

const CustomSnackbar = ({
    message,
    severity,
    variant = 'standard',
    open,
    handleClose,
    autoHideDuration = null,
    vertical = 'top',
    horizontal = 'center',
    link,
}: InfoType) => {
    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={autoHideDuration}
                anchorOrigin={{ vertical, horizontal }}
            >
                <Alert severity={severity} variant={variant} onClose={handleClose}>
                    {message}
                    {link && (
                        <Box sx={{ m: '8px 0' }}>
                            <Button
                                href={link.href}
                                target={link.target}
                                variant="contained"
                                size="small"
                            >
                                {link.label}
                            </Button>
                        </Box>
                    )}
                </Alert>
            </Snackbar>
        </>
    );
};

export default CustomSnackbar;
