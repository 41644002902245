import { Box, Button, Link, Typography } from '@mui/material';

const AboutPage = () => {
    return (
        <Box sx={{ m: 4 }}>
            <Box sx={{ margin: '40px 0' }}>
                <Typography variant="h5">holoPlayerとは</Typography>
                <Typography variant="body2" sx={{ margin: '12px 0' }}>
                    holoPlayer（ホロプレイヤー）は、ファンメイドの非公式Webサービスです。
                </Typography>
                <Typography variant="body2" sx={{ margin: '12px 0' }}>
                    ホロライブのオリジナル楽曲を独自にデータベース化し、YouTubeの本家動画をそのまま再生することができます。
                </Typography>
                <Typography variant="body2" sx={{ margin: '12px 0' }}>
                    開発は、リスナーの協力を得て、
                    <Link href="https://twitter.com/guguji_netaru" target="_blank" rel="noreferrer">
                        宮々寺ねたる
                    </Link>
                    が個人で行っています。
                </Typography>
            </Box>
            <Box>
                <Typography variant="h5">お問い合わせ</Typography>
                <Typography variant="body2" sx={{ margin: '12px 0' }}>
                    TwitterまたはGoole Formよりお問い合わせください。
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    <Button
                        variant="outlined"
                        href="https://twitter.com/holoplayerjp"
                        target="_blank"
                    >
                        Twitter
                    </Button>
                    <Button
                        variant="outlined"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSe-hB9taa4ZpMSkrnGWqNHiytaxbxELcnsED3F6eYn-Z5jp7A/viewform"
                        target="_blank"
                    >
                        お問い合わせフォーム
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
export default AboutPage;
