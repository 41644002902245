import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SongItemType, TalentItemType } from '../../type/hololive.types';

export type PlayerStatusType = {
    playing: boolean;
    light: boolean;
    volume: number;
    muted: boolean;
    loop: boolean;
    played: number;
    loaded: number;
    duration: number;
    playbackRate: number;
    pip: boolean;
    seeking?: boolean;
    ref?: any;
};

export type QuizTalentScopeType = TalentItemType['en_kebab'] | 'all';

export type QuizCorrectType = 'unanswered' | 'correct' | 'incorrect';
export type QuizSongItemType = {
    origin: SongItemType;
    correct: QuizCorrectType;
    answer: string | null;
};

export type QuizErrorItemType = {
    id: string;
    msg: string;
};

export const QuizModeTypeArray = [
    {
        value: 'aenbien',
        label: 'あえんびえん',
    },
    {
        value: 'yubiyubi',
        label: 'ゆびゆび',
    },
    {
        value: 'zenkoutei',
        label: '全肯定',
    },
] as const;
export type QuizModeType = typeof QuizModeTypeArray[number]['value'] | undefined;

export const QuizStartPositionTypeArray = [
    {
        value: 'sabi',
        label: 'サビ',
    },
    {
        value: 'intro',
        label: 'イントロ',
    },
    {
        value: 'random',
        label: 'ランダム',
    },
] as const;
export type QuizStartPositionValueType = typeof QuizStartPositionTypeArray[number]['value'];
export type QuizStartPositionLabelType = typeof QuizStartPositionTypeArray[number]['label'];

export type QuizState = {
    isLoaded: boolean;
    hadPlayed: boolean;
    talentScope: QuizTalentScopeType;
    startPosition: QuizStartPositionValueType;
    isFull: boolean;
    done: boolean;
    current: {
        song: SongItemType | undefined;
        playlist: QuizSongItemType[];
        mode: QuizModeType;
    };
    player: PlayerStatusType;
    errors: QuizErrorItemType[];
    quizBaseSongCollectionStatus: 'ready' | 'computing' | 'done' | 'error';
    quizBaseSongCollection: SongItemType[];
};

const initialState: QuizState = {
    isLoaded: false,
    hadPlayed: false,
    talentScope: 'all',
    startPosition: 'sabi',
    isFull: true,
    done: false,
    current: {
        song: undefined,
        playlist: [],
        mode: undefined,
    },
    player: {
        playing: false,
        light: false,
        volume: 1,
        muted: false,
        loop: false,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        pip: false,
        seeking: false,
        ref: undefined,
    },
    errors: [],
    quizBaseSongCollectionStatus: 'ready',
    quizBaseSongCollection: [],
};

const quizSlice = createSlice({
    name: 'quiz',
    initialState,
    reducers: {
        firstPlay: (state) => {
            state.hadPlayed = true;
        },
        setStartPosition: (state, action: PayloadAction<QuizStartPositionValueType>) => {
            state.startPosition = action.payload;
        },
        setScope: (state, action: PayloadAction<QuizTalentScopeType>) => {
            state.talentScope = action.payload;
        },
        setQuizPlaylist: (state, action: PayloadAction<{ playlist: SongItemType[] }>) => {
            const { playlist } = action.payload;
            state.current.playlist = [...playlist].map((origin) => {
                return {
                    origin,
                    correct: 'unanswered',
                    answer: null,
                };
            });
            state.current.song = playlist[0];
        },
        setQuizMode: (state, action: PayloadAction<QuizModeType>) => {
            state.current.mode = action.payload;
        },
        exitQuiz: (state) => {
            state.current.playlist = [];
            state.current.mode = undefined;
            state.current.song = undefined;
            state.done = false;
        },
        doPlay: (state) => {
            state.player.playing = true;
        },
        doPause: (state) => {
            state.player.playing = false;
        },
        setFullscreen: (state, action: PayloadAction<boolean>) => {
            state.isFull = action.payload;
        },
        addError: (state, action: PayloadAction<QuizErrorItemType>) => {
            state.errors = [...state.errors, action.payload];
        },
        clearError: (state) => {
            state.errors = [];
        },
        displayScore: (state) => {
            state.done = true;
        },
        answer: (
            state,
            action: PayloadAction<{ song: SongItemType; correct: QuizCorrectType; answer: string }>,
        ) => {
            // プレイリストから回答した曲を探し、
            // - 正誤を記録
            // - ユーザーの選択を記録
            // - 回答の次の曲のindexをindexとして格納
            let index = 0;
            state.current.playlist.find((item, _i) => {
                if (item.origin.id === action.payload.song.id) {
                    item.correct = action.payload.correct;
                    item.answer = action.payload.answer;
                    index = _i + 1;
                    return true;
                }
                return false;
            });

            if (state.current.playlist.length === index) {
                // 全問終了したら、再生停止＆完了フラグをtrueにする
                state.player.playing = false;
                state.done = true;
            } else if (state.current.playlist.length > index) {
                // まだ終わってなければ次の曲をセットする
                state.current.song = state.current.playlist[index].origin;
            }
        },
        startQuizBaseSongCollection: (state, action: PayloadAction) => {
            state.quizBaseSongCollectionStatus = 'computing';
        },
        completeQuizBaseSongCollection: (state, action: PayloadAction<SongItemType[]>) => {
            state.quizBaseSongCollectionStatus = 'done';
            state.quizBaseSongCollection = action.payload.filter((item) => {
                if (item?.quiz?.length && item.quiz.length === 3) {
                    return item.quiz[0] && item.quiz[1] && item.quiz[2];
                }
                return false;
            });
        },
    },
});

export const {
    setQuizPlaylist,
    setScope,
    exitQuiz,
    doPlay,
    doPause,
    answer,
    setFullscreen,
    addError,
    clearError,
    setQuizMode,
    displayScore,
    setStartPosition,
    firstPlay,
    startQuizBaseSongCollection,
    completeQuizBaseSongCollection,
} = quizSlice.actions;

export default quizSlice.reducer;
