import { Box, Divider } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import { HOLOPLAYER_ZINDEX, SWIPEABLE_SHEET_CHANGE_THRESHOLD } from '../../../constants';
import { useTheme } from '@mui/material';
import React, { ReactNode, useMemo, useState } from 'react';
import 'swiper/css';
import { useSwipeable } from 'react-swipeable';
import hexToRgba from 'hex-to-rgba';

const BottomSheet = (props: {
    children: ReactNode;
    isOpen: boolean;
    isFull: boolean;
    doClose: () => void;
    doFullscreen: () => void;
    doExitFullscreen: () => void;
    handle: ReactNode;
    draggable: boolean;
}) => {
    const { children, isOpen, isFull, doClose, doFullscreen, doExitFullscreen, handle, draggable } =
        props;
    const theme = useTheme();
    const setting = useAppSelector((state) => state.setting);

    const [top, setTop] = useState<number>(0);
    const playerHeight = useMemo(() => setting.playerHeight, [setting.playerHeight]);

    const handlers = useSwipeable({
        onSwiping: (e) => {
            if (!draggable) return;
            setTop(e.deltaY);
        },
        onSwiped: () => {
            if (!draggable) return;
            setTop(0);
        },
        onSwipedDown: (e) => {
            if (!draggable) return;
            if (e.absY > SWIPEABLE_SHEET_CHANGE_THRESHOLD) {
                if (isFull) {
                    doExitFullscreen();
                } else {
                    doClose();
                }
            }
            setTop(0);
        },
        onSwipedUp: (e) => {
            if (!draggable) return;
            if (e.absY > SWIPEABLE_SHEET_CHANGE_THRESHOLD) {
                doFullscreen();
            }
            setTop(0);
        },
    });

    return (
        <Box
            sx={{
                // backdropFilter: 'blur(64px) brightness(.6)',
                backgroundColor: hexToRgba(theme.palette.background.default, 1),
                position: 'fixed',
                top: isOpen ? (isFull ? 0 + top + 'px' : playerHeight + top + 'px') : '100%',
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: HOLOPLAYER_ZINDEX.DETAIL_SHEET,
                maxHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: theme.transitions.create(['top', 'backgroundColor'], {
                    duration: theme.transitions.duration.complex,
                }),
                borderRadius: '8px 8px 0 0 ',
                overflow: 'hidden',
            }}
        >
            <Box {...handlers}>{handle}</Box>
            <Divider />
            {children}
        </Box>
    );
};

export default BottomSheet;
