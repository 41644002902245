import { doc, setDoc, getDoc } from 'firebase/firestore';
// import { doc, setDoc, updateDoc, collection, getDoc, DocumentReference, DocumentData } from 'firebase/firestore';
import { db } from './firebase';

export type FirestoreUserType = {
    uid: string;
    brightness: 'system' | 'light' | 'dark';
};

// export const getUser = async (user_id: string): Promise<FireStoreUser | null> => {
//     const ref = doc(db, 'users', user_id);
//     const snap = await getDoc(ref);
//     if (snap.exists()) {
//         return snap.data() as FireStoreUser;
//     }
//     return null;
// };

// export const setBrightnessInFirestore = async (
//     user_id: string,
//     brightness: FireStoreUser['brightness']
// ): Promise<any> => {
//     const ref = doc(db, 'users', user_id);
//     return setDoc(ref, {
//         brightness
//     });
// };

export default class FirestoreUser {
    static async getUser(user_id: string): Promise<FirestoreUserType | null> {
        const ref = doc(db, 'users', user_id);
        const snap = await getDoc(ref);
        if (snap.exists()) {
            return snap.data() as FirestoreUserType;
        }
        return null;
    }

    static async setBrightness(user_id: string, brightness: FirestoreUserType['brightness']): Promise<any> {
        const ref = doc(db, 'users', user_id);
        return setDoc(ref, {
            brightness,
        });
    }
}

// export default class FirestoreUser {
//     public user_id?: string;
//     public ref?: DocumentReference;
//     public data?: DocumentData;

//     constructor() {}

//     async setUserId(user_id: string) {
//         this.user_id = user_id;
//         this.ref = doc(db, 'users', user_id);
//         const snap = await getDoc(this.ref);
//         if (snap.exists()) {
//             this.data = snap.data();
//             return this.data;
//         }
//         return;
//     }

//     setBrightness(brightness: FireStoreUser['brightness']) {
//         if (!this.ref) return;
//         const result = setDoc(this.ref, {
//             brightness
//         });
//         return result;
//     }

//     getBrightness(): FireStoreUser['brightness'] | undefined {
//         if (!this.data) return;
//         return this.data.brightness;
//     }
// }
