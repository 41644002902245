import { Box, FormControl, Typography, InputLabel, MenuItem, useTheme } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useAppSelector } from '../../../app/hooks';
import QuizSheet from './QuizSheet';
import NavigationBar from '../../ui/NavigationBar/NavigationBar';
import { QUIZ_SENTENCES } from '../../../constants';
import { HOLOPLAYER_PATH } from '../../../route/route';
import useQuiz from '../../../slice/quiz/useQuiz';
import { QuizStartPositionTypeArray } from '../../../slice/quiz/quizSlice';
import { ReactComponent as OnigiriIcon } from '../../../img/onigiri.svg';
import useTalent from '../../../slice/talent/useTalent';
import { useEffect } from 'react';
import CustomButton from '../../ui/CustomButton';

const Zenkoutei = () => {
    const theme = useTheme();
    const Quiz = useQuiz();
    const Talent = useTalent();

    const scope = useAppSelector((state) => state.quiz.talentScope);
    const errors = useAppSelector((state) => state.quiz.errors);
    const select_values = [
        {
            jp_formal: 'すべて',
            en_kebab: 'all',
        },
        ...Talent.items,
    ];

    useEffect(() => {
        Talent.fetchTalent();
    }, [Talent]);

    useEffect(() => {
        Quiz.readyQuiz();
    }, [Quiz]);

    const SelectBox = () => {
        return (
            <FormControl fullWidth size="small">
                <InputLabel>再生開始位置</InputLabel>
                <Select
                    value={Quiz.startPosition}
                    label="再生開始位置"
                    onChange={(e: SelectChangeEvent) =>
                        Quiz.SetStartPosition((e.target as HTMLInputElement).value)
                    }
                >
                    {QuizStartPositionTypeArray.map((item, key) => {
                        return (
                            <MenuItem key={key} value={item.value}>
                                {item.label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    };

    const SelectRange = () => {
        return (
            select_values && (
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">出題範囲</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={scope}
                        label="出題範囲"
                        onChange={(e: SelectChangeEvent) => {
                            Quiz.SetScope(e.target.value);
                        }}
                    >
                        {select_values &&
                            select_values.map((talent, index) => {
                                return (
                                    <MenuItem key={index} value={talent.en_kebab}>
                                        {talent.jp_formal}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
            )
        );
    };

    return (
        <Box>
            <NavigationBar
                title={QUIZ_SENTENCES.SELECT.NAVIGATION.ZENKOUTEI}
                to={HOLOPLAYER_PATH.QUIZ}
            />
            <Box sx={{ m: '32px 0', textAlign: 'center' }}>
                <OnigiriIcon width={48} height={48} fill={theme.palette.text.primary} />
                <Typography variant="h6">{QUIZ_SENTENCES.SELECT.H1.ZENKOUTEI}</Typography>
                <Typography variant="body2" color={theme.palette.text.secondary}>
                    {QUIZ_SENTENCES.SELECT.DESCRIPSION.ZENKOUTEI}
                </Typography>
            </Box>
            <Box sx={{ margin: 2, textAlign: 'center' }}>
                <Box sx={{ m: '24px 0', display: 'flex', gap: 2, alignItems: 'flex-end' }}>
                    <SelectRange />
                    <SelectBox />
                </Box>
                <CustomButton
                    fullWidth
                    variant="contained"
                    startIcon={<PlayArrowIcon />}
                    size="large"
                    onClick={() => {
                        Quiz.StartZenkouteiQuiz();
                    }}
                    loading={!Quiz.isReadyQuiz()}
                >
                    スタート
                </CustomButton>
                {errors.length > 0 &&
                    errors.map((error, index) => (
                        <Typography key={index} sx={{ m: 2 }}>
                            {error.msg}
                        </Typography>
                    ))}
            </Box>

            <QuizSheet />
        </Box>
    );
};
export default Zenkoutei;
