import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { ReactComponent as SplashLogo } from '../../img/splash-logo.svg';

const Wrap = styled.div`
    background: ${(props: any) => props.theme.palette.primary.main};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9999;
`;
const Subtitle = styled.div`
    font-family: 'Saira', san-serif;
    font-size: 14px;
    font-weight: 900;
    font-style: italic;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    margin-bottom: 32px;
`;
const LinnearWrap = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
`;

const Splash = () => {
    const theme = useTheme();

    return (
        <Wrap theme={theme}>
            <LinnearWrap>
                <LinearProgress />
            </LinnearWrap>
            <SplashLogo />
            <Subtitle>
                ホロライブ オリジナル楽曲
                <br />
                非公式プレイヤー
            </Subtitle>
        </Wrap>
    );
};
export default Splash;
