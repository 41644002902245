export const TRANSITION_DURATION = 250;
export const TRANSITION_EASING = 'easeIn';
export const BOTTOM_NAVIGATION_HEIGHT = 64;
export const SWIPEABLE_SHEET_CHANGE_THRESHOLD = 80;

export const HOLOPLAYER_EXTERNAL_URL = {
    ABOUT_HOLOPLAYER: {
        LABEL: 'holoplayerについて',
        URL: 'https://robust-family-49a.notion.site/holoplayer-a0ee77ebc94f4b91a72bf0902fadc561',
    },
};

export const HOLOPLAYER_ZINDEX = {
    NAVIGATION_BAR: 100,
    SHEET_BODY: 200,
    PLAYING_BAR: 250,
    DETAIL_SHEET: 300,
    SHEET_TAB: 400,
};

export const QUIZ_SENTENCES = {
    INDEX: {
        LEAD: '選択肢から楽曲を当てるクイズ！',
        TITLE: {
            AENBIEN: 'あえんびえんクイズ',
            YUBIYUBI: 'ゆびゆびクイズ',
            ZENKOUTEI: '全肯定クイズ',
        },
        DESCRIPSION: {
            AENBIEN: '１問でも間違えたら即終了！',
            YUBIYUBI: '残機は５！間違えたら ゆびゆび♪',
            ZENKOUTEI: '練習モード！間違えてもよくなーい？',
        },
    },
    SELECT: {
        NAVIGATION: {
            AENBIEN: 'あえんびえんクイズ',
            YUBIYUBI: 'ゆびゆびクイズ',
            ZENKOUTEI: '全肯定クイズ',
        },
        H1: {
            AENBIEN: 'あえんびえんクイズ',
            YUBIYUBI: 'ゆびゆびクイズ',
            ZENKOUTEI: '全肯定クイズ',
        },
        DESCRIPSION: {
            AENBIEN: '１問でも間違えたら即終了！',
            YUBIYUBI: '残機は５！間違えたら ゆびゆび♪',
            ZENKOUTEI: '練習モード！間違えてもよくなーい？',
        },
    },
    QUESTION_SHEET: {
        DESCRIPTION: {
            AENBIEN: '１問でも間違えたら即終了！',
            YUBIYUBI: '残機は５！間違えたら ゆびゆび♪',
            ZENKOUTEI: '練習モード！間違えてもよくなーい？',
        },
    },
};

export const YOUTUBE_URL = {
    WATCH_BASE: 'https://www.youtube.com/watch?v=',
};

// 読み込み中のスケルトンを何個表示するか
export const DEFAULT_LOADING_SKELETON_COUNT = 20;
