export const webWorkerAsync = <T, S>(task: (arg: MessageEvent<T>) => S, arg: T): Promise<S> => {
    return new Promise((resolve, reject) => {
        const taskString = `
            const task = ${task.toString()};
            this.onmessage = (arg) => {
                postMessage(task(arg));
            }
        `;

        const blob = new Blob([taskString], { type: 'application/javascript' });
        const worker = new Worker(URL.createObjectURL(blob));

        worker.addEventListener('message', (m: MessageEvent<S>) => {
            console.info('[Complete] Web Worker Task');
            resolve(m.data);
        });

        console.info('[Start] Web Worker Task');
        worker.postMessage(arg);
    });
};
