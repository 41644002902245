import { Box, IconButton, InputBase } from '@mui/material';
import { useRef } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

const NavSearchInput = ({
    value,
    setQuery,
    placeholder,
    setShowInput,
}: {
    value: string | undefined;
    setQuery: (value: string) => void;
    placeholder: string;
    setShowInput: (isShowInput: boolean) => void;
}) => {
    const inputRef = useRef<HTMLElement>(null);
    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                flexShrink: 1,
                alignItems: 'center',
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '8px',
                }}
            >
                <SearchIcon sx={{ color: '#aaa' }} />
            </Box>
            <InputBase
                sx={[
                    {
                        flexGrow: 1,
                        flexShrink: 1,
                    },
                    {
                        input: {
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        },
                    },
                ]}
                onChange={(e) => {
                    setQuery(e.target.value);
                }}
                value={value}
                placeholder={placeholder}
                ref={inputRef}
                autoFocus={true}
            />
            {value && (
                <IconButton
                    size="small"
                    onClick={() => {
                        setQuery('');
                        inputRef?.current?.querySelector('input')?.focus();
                    }}
                >
                    <CancelIcon sx={{ color: '#777', width: '20px' }} />
                </IconButton>
            )}
            <IconButton
                size="large"
                onClick={() => {
                    setShowInput(false);
                    setQuery('');
                }}
            >
                <CloseIcon sx={{}} />
            </IconButton>
            {/* <Button
                variant="text"
                size="small"
                sx={{
                    color: '#ccc',
                    marginRight: '8px',
                }}
                onClick={() => setShowInput(false)}
            >
                キャンセル
            </Button> */}
        </Box>
    );
};

export default NavSearchInput;
