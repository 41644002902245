import { SettingState } from '../slice/settingSlice';
import { ThemeOptions, TransitionsOptions } from '@mui/material';

export type GradientPalette = { start: string; end: string; main: string };
const gradientPallete: GradientPalette = {
    start: '#23ABF8',
    end: '#CE0D6A',
    main: 'linear-gradient(95.02deg, #23ABF8 0%, #CE0D6A 100%)',
};

declare module '@mui/material/styles' {
    interface Palette {
        gradient: GradientPalette;
        imageDivider: string;
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        gradient?: GradientPalette;
        imageDivider: string;
    }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        gradient: true;
    }
}

const transitions: TransitionsOptions = {
    duration: {
        complex: 200,
    },
};

const darkTheme: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#13B8FF',
        },
        background: {
            default: '#212121',
            paper: '#333',
        },
        divider: '#444',
        gradient: gradientPallete,
        imageDivider: 'rgba(255,255,255,0.1)',
    },
    transitions,
};
const lightTheme: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#13B8FF',
        },
        background: {
            default: '#fff',
            paper: '#f6f6ff',
        },
        gradient: gradientPallete,
        imageDivider: 'rgba(0,0,0,0.1)',
    },
    transitions,
};

const generateTheme = (
    brightnessSetting: SettingState['brightness'],
    isDarkMode: boolean,
): ThemeOptions => {
    if (brightnessSetting === 'system') {
        return isDarkMode ? darkTheme : lightTheme;
    } else if (brightnessSetting === 'light') {
        return lightTheme;
    }
    return darkTheme;
};
export default generateTheme;
