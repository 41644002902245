import { Box, Divider, List, Typography, useTheme } from '@mui/material';

import useQuiz from '../../../slice/quiz/useQuiz';
import ResultItem from './ResultItem';
import React, { useMemo } from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { MathFloorDigit } from '../../../lib/misc';
import { ReactComponent as TwitterLogo } from '../../../img/twitter.svg';
import { TwitterShareButton } from 'react-share';

const QuizDone = () => {
    const Quiz = useQuiz();
    const { correct, incorrect, playlist, unanswered } = Quiz.GetQuizStatus();
    const theme = useTheme();

    const ResultCount = ({
        label,
        count,
        size,
        icon,
    }: {
        label: string;
        count: number;
        size: number;
        icon?: React.ReactNode;
    }) => {
        return (
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                <Box
                    sx={{
                        fontSize: `${size}px`,
                        color: theme.palette.text.primary,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '8px',
                    }}
                >
                    {icon}
                    {count}
                </Box>
                <Box
                    sx={{
                        color: theme.palette.text.secondary,
                    }}
                >
                    {label}
                </Box>
            </Box>
        );
    };

    const { percent, integer, decimal } = useMemo(() => {
        let percent: number = 0;
        let integer: string = '0';
        let decimal: string = '';
        if (playlist.length > 0 && correct.length > 0) {
            const _p = (correct.length / playlist.length) * 100;
            percent = MathFloorDigit(_p, 3);

            const arr = String(percent).split('.');
            integer = arr[0];
            decimal = arr?.[1] || '';
        }
        return {
            percent,
            integer,
            decimal,
        };
    }, [playlist, correct]);

    const ResultInformation = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    borderColor: theme.palette.divider,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderRadius: '12px',
                    padding: '4px 24px 16px',
                    backgroundColor: theme.palette.success.dark,
                }}
            >
                <Box
                    sx={{
                        fontSize: '24px',
                        display: 'flex',
                        alignItems: 'baseline',
                        color: 'white',
                    }}
                >
                    <Box sx={{ fontSize: '48px' }}>{integer}</Box>
                    <Box>{decimal && `.${decimal}`}</Box>
                    <Box>%</Box>
                </Box>
                <Box
                    sx={{
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <CheckCircleRoundedIcon sx={{ color: 'white' }} />
                    正解率
                </Box>
            </Box>
        );
    };
    const BasicInformation = () => {
        const size = 18;
        return (
            <Box sx={{ display: 'flex', width: '100%', margin: '16px 0' }}>
                <ResultCount label="全問" count={playlist.length} size={size} />
                <ResultCount label="正解" count={correct.length} size={size} />
                <ResultCount label="不正解" count={incorrect.length} size={size} />
                <ResultCount label="未回答" count={unanswered.length} size={size} />
            </Box>
        );
    };

    return (
        <Box>
            <Box sx={{ m: 2 }}>
                <Typography variant="h6">
                    {Quiz.GetModeLabel()}クイズ({Quiz.GetStartPositionLabel()})
                </Typography>
                <Typography variant="subtitle2" sx={{ color: theme.palette.text.secondary }}>
                    {Quiz.GetScopeLabel()}の曲
                </Typography>
            </Box>

            <Box sx={{ m: 2 }}>
                <ResultInformation />
                <BasicInformation />
            </Box>

            <Divider sx={{ m: 2 }} />

            <Box sx={{ m: 2, textAlign: 'center' }}>
                <TwitterShareButton
                    url={`https://holoplayer.jp/quiz/${Quiz.current.mode}`}
                    title={`${Quiz.GetModeLabel()}クイズ: ${percent}%正解！`}
                    hashtags={[
                        'ホロライブ',
                        'ホロプレイヤー',
                        Quiz.GetModeLabel(),
                        Quiz.startPosition,
                    ]}
                >
                    <Box
                        sx={{
                            color: theme.palette.text.primary,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                            border: `solid 1px ${theme.palette.divider}`,
                            borderRadius: '12px',
                            p: '4px 12px',
                        }}
                    >
                        <TwitterLogo width="32px" fill={theme.palette.text.primary} />
                        シェア
                    </Box>
                </TwitterShareButton>
            </Box>

            <Divider sx={{ m: 2 }} />

            <Box sx={{ m: 2 }}>
                <Typography variant="h6">間違えた問題</Typography>
                <List>
                    {incorrect.length > 0
                        ? incorrect.reverse().map((item, index) => {
                              return (
                                  <ResultItem
                                      key={index}
                                      title={item.origin.title}
                                      answer={`あなたの回答: ${item.answer}`}
                                  />
                              );
                          })
                        : '間違えた問題はありません'}
                </List>
            </Box>
        </Box>
    );
};

export default QuizDone;
