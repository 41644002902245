import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { HOLOPLAYER_PATH } from '../../../route/route';
import SongCollection from '../../feature/collection/SongCollection';
import { Box } from '@mui/material';
import NavigationBar from '../../ui/NavigationBar/NavigationBar';
import { PersonItemType } from '../../../type/hololive.types';
import usePerson from '../../../slice/person/usePerson';
import NoContent from '../../ui/NoContent';

const NotFoundPerson = () => {
    return (
        <Box>
            <NavigationBar title="タレントの曲" to={HOLOPLAYER_PATH.LIBRARY_PERSON_COLLECTION} />
            <NoContent />
        </Box>
    );
};

const PersonSongPage = () => {
    const params = useParams();
    const { pathname } = useLocation();
    const Person = usePerson();

    useEffect(() => {
        Person.fetchPerson();
    }, [Person]);

    const currentPerson = useMemo<'loading' | PersonItemType | 'notFound'>(() => {
        if (Person.isLoaded) {
            if (!params.id) return 'notFound';
            const currentPersonId = parseInt(params.id);
            const person = Person.items.find((item) => item.id === currentPersonId);
            if (person) {
                return person;
            }
        } else {
            return 'loading';
        }
        return 'notFound';
    }, [Person.isLoaded, Person.items, params.id]);

    useEffect(() => {
        if (currentPerson !== 'loading' && currentPerson !== 'notFound') {
            Person.fetchPersonSong(currentPerson);
        }
    }, [currentPerson, Person]);

    if (currentPerson === 'notFound') return <NotFoundPerson />;

    if (
        currentPerson === 'loading' ||
        currentPerson?.songs?.status === 'ready' ||
        currentPerson?.songs?.status === 'loading'
    ) {
        return (
            <SongCollection
                collection={'loading'}
                roleCollection={{
                    composerCollection: 'loading',
                    arrangerCollection: 'loading',
                    lyricistCollection: 'loading',
                    talentCollection: 'loading',
                }}
                navigation={{
                    title: '',
                    to: HOLOPLAYER_PATH.LIBRARY_TALENT_COLLECTION,
                }}
                searchId={pathname}
            />
        );
    }

    return (
        <SongCollection
            collection={currentPerson?.songs?.all || []}
            roleCollection={{
                composerCollection: currentPerson?.songs?.composer,
                arrangerCollection: currentPerson?.songs?.arranger,
                lyricistCollection: currentPerson?.songs?.lyricist,
                talentCollection: currentPerson?.songs?.talent,
            }}
            navigation={{
                title: currentPerson.name,
                to: HOLOPLAYER_PATH.LIBRARY_PERSON_COLLECTION,
            }}
            searchId={pathname}
        />
    );
};

export default PersonSongPage;
