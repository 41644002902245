import { Typography, Slider, Box, IconButton, useTheme } from '@mui/material';
import { useAppSelector } from '../../../../../app/hooks';
import Duration from '../../../../ui/Duration';
import usePlayer from '../../../player/usePlayer';
import { useSwiper } from 'swiper/react';

import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import SkipNextRoundedIcon from '@mui/icons-material/SkipNextRounded';
import SkipPreviousRoundedIcon from '@mui/icons-material/SkipPreviousRounded';
import Replay5RoundedIcon from '@mui/icons-material/Replay5Rounded';
import Forward5RoundedIcon from '@mui/icons-material/Forward5Rounded';
import Replay10RoundedIcon from '@mui/icons-material/Replay10Rounded';
import Forward10RoundedIcon from '@mui/icons-material/Forward10Rounded';
import Replay30RoundedIcon from '@mui/icons-material/Replay30Rounded';
import Forward30RoundedIcon from '@mui/icons-material/Forward30Rounded';

const SliderArea = () => {
    const song = useAppSelector((state) => state.song.current.song);
    const theme = useTheme();

    const Player = usePlayer();
    const PlayerState = useAppSelector((state) => state.player);

    const swiper = useSwiper();

    const handleChangePlayed = (e: any, value: any) => {
        Player.HandleSeeking(e.target.value);
    };
    const handleChangeCommittedPlayed = (e: any, value: any) => {
        Player.handleChangeCommittedPlayed();
    };
    return (
        <>
            <Slider
                value={PlayerState.played}
                min={0}
                max={0.999999}
                step={0.001}
                onChange={handleChangePlayed}
                onChangeCommitted={handleChangeCommittedPlayed}
                onTouchStart={() => {
                    swiper.disable();
                }}
                onTouchEnd={() => {
                    swiper.enable();
                }}
                sx={[
                    {
                        '& .MuiSlider-rail': {
                            opacity: 0,
                        },
                    },
                ]}
            />
            <Slider
                min={0}
                max={PlayerState.duration}
                step={0.01}
                value={
                    song?.sabi?.start && song?.sabi?.end ? [song?.sabi?.start, song?.sabi?.end] : 0
                }
                disabled={true}
                sx={[
                    {
                        position: 'absolute',
                        top: '16px',
                        left: '16px',
                        right: '16px',
                        bottom: '16px',
                        zIndex: -1,
                        width: 'inherit',
                    },
                    {
                        '& .MuiSlider-thumb': {
                            display: 'none',
                        },
                    },
                    {
                        '& .MuiSlider-track': {
                            color: theme.palette.primary.light,
                            borderRadius: '4px',
                            opacity: song?.sabi?.start && song?.sabi?.end ? 1 : 0,
                        },
                    },
                ]}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    opacity: 0.6,
                    marginTop: '-16px',
                    // pointerEvents: 'none',
                }}
            >
                <Typography component="div" sx={{ fontSize: 14 }}>
                    <Duration seconds={PlayerState.played * PlayerState.duration} />
                </Typography>
                <Typography component="div" sx={{ fontSize: 14 }}>
                    -
                    <Duration
                        seconds={PlayerState.duration - PlayerState.played * PlayerState.duration}
                    />
                </Typography>
            </Box>
        </>
    );
};

const ControllSection = () => {
    const setting = useAppSelector((state) => state.setting);

    const Player = usePlayer();
    const playing = useAppSelector((state) => state.player.playing);

    const ForwardIcon = () => {
        const sx = { fontSize: 24, opacity: 0.6 };
        if (setting.seekSeconds === 5) {
            return <Forward5RoundedIcon sx={sx} />;
        } else if (setting.seekSeconds === 10) {
            return <Forward10RoundedIcon sx={sx} />;
        }
        return <Forward30RoundedIcon sx={sx} />;
    };

    const BackwardIcon = () => {
        const sx = { fontSize: 24, opacity: 0.6 };
        if (setting.seekSeconds === 5) {
            return <Replay5RoundedIcon sx={sx} />;
        } else if (setting.seekSeconds === 10) {
            return <Replay10RoundedIcon sx={sx} />;
        }
        return <Replay30RoundedIcon sx={sx} />;
    };

    return (
        <>
            <Box sx={{ padding: '16px 16px 0', position: 'relative' }}>
                <SliderArea />
            </Box>
            <Box
                sx={{
                    padding: '0 16px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <IconButton onClick={() => Player.SeekBackward()}>
                    <BackwardIcon />
                </IconButton>
                <IconButton onClick={() => Player.Prev()}>
                    <SkipPreviousRoundedIcon sx={{ fontSize: 40 }} />
                </IconButton>
                {playing ? (
                    <IconButton sx={{ fontSize: '16px' }} onClick={() => Player.Pause()}>
                        <PauseRoundedIcon sx={{ fontSize: 64 }} />
                    </IconButton>
                ) : (
                    <IconButton onClick={() => Player.HandleClickPlayButton()}>
                        <PlayArrowRoundedIcon sx={{ fontSize: 64 }} />
                    </IconButton>
                )}

                <IconButton onClick={() => Player.Next()}>
                    <SkipNextRoundedIcon sx={{ fontSize: 40 }} />
                </IconButton>
                <IconButton onClick={() => Player.SeekForward()}>
                    <ForwardIcon />
                </IconButton>
            </Box>
        </>
    );
};
export default ControllSection;
