import { useAppSelector } from '../../../../app/hooks';
import SongCollection from '../../collection/SongCollection';
const PlayListTab = () => {
    const songState = useAppSelector((state) => state.song);

    return (
        <>
            <SongCollection
                collection={songState.isLoaded ? songState.current.playlist : 'loading'}
                searchId="PlayListTab"
                isUseOriginalCollection={true}
            />
        </>
    );
};
export default PlayListTab;
