import React from 'react';
import { Button } from '@mui/material';
import { signInWithRedirect } from 'firebase/auth';
import { auth, googleAuthProvider } from '../../app/firebase';

const Login = () => {
    const LoginwithGoogle = async () => {
        return signInWithRedirect(auth, googleAuthProvider);
    };

    return (
        <Button variant="contained" onClick={LoginwithGoogle}>
            Googleアカウントでログイン
        </Button>
    );
};

export default Login;
