import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AccountState = {
    isLoading: boolean;
    isLogin: boolean | null;
    uid: string | null;
    photoURL: string | null;
    displayName: string | null;
    token: string | undefined;
};

const initialState: AccountState = {
    isLoading: true,
    isLogin: null,
    uid: null,
    photoURL: null,
    displayName: null,
    token: undefined,
};

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setLogIn: (
            state,
            action: PayloadAction<{
                uid: string | null;
                photoURL: string | null;
                displayName: string | null;
                token: string | undefined;
            }>,
        ) => {
            state.isLogin = true;
            state.uid = action.payload.uid;
            state.photoURL = action.payload.photoURL;
            state.displayName = action.payload.displayName;
            state.token = action.payload.token;
        },
        setLogOut: (state) => {
            state.isLogin = false;
            state.uid = null;
            state.photoURL = null;
        },
        loaded: (state) => {
            state.isLoading = false;
        },
        loadStart: (state) => {
            state.isLoading = true;
        },
    },
});

export const { setLogIn, setLogOut, loaded, loadStart } = accountSlice.actions;

// export const selectUid = (state) => state.user.uid;
// export const selectPhoto = (state) => state.user.photo;

export default accountSlice.reducer;
