import { useLocation, useParams } from 'react-router-dom';
import { HOLOPLAYER_PATH } from '../../../route/route';
import SongCollection from '../../feature/collection/SongCollection';
import { Box } from '@mui/material';
import NavigationBar from '../../ui/NavigationBar/NavigationBar';
import { useEffect, useMemo } from 'react';
import { GenerationItemType } from '../../../type/hololive.types';
import useGeneration from '../../../slice/generation/useGeneration';
import NoContent from '../../ui/NoContent';

const NotFoundGeneration = () => {
    return (
        <Box>
            <NavigationBar title="ユニットの曲" to={HOLOPLAYER_PATH.LIBRARY_UNIT_COLLECTION} />
            <NoContent />
        </Box>
    );
};

const GenerationSongPage = () => {
    const Generation = useGeneration();
    const params = useParams();
    const { pathname } = useLocation();

    useEffect(() => {
        Generation.fetchGeneration();
    }, [Generation]);

    const currentGeneration = useMemo<'loading' | GenerationItemType | 'notFound'>(() => {
        if (Generation.isLoaded) {
            if (!params.id) return 'notFound';
            const currentGenerationId = parseInt(params.id);
            const generation = Generation.items.find((item) => item.id === currentGenerationId);
            if (generation) {
                return generation;
            }
        } else {
            return 'loading';
        }
        return 'notFound';
    }, [Generation.isLoaded, Generation.items, params.id]);

    useEffect(() => {
        if (currentGeneration !== 'loading' && currentGeneration !== 'notFound') {
            Generation.fetchGenerationSong(currentGeneration);
        }
    }, [currentGeneration, Generation]);

    if (currentGeneration === 'notFound') return <NotFoundGeneration />;

    if (
        currentGeneration === 'loading' ||
        currentGeneration?.songs?.status === 'ready' ||
        currentGeneration?.songs?.status === 'loading'
    ) {
        return (
            <SongCollection
                collection={'loading'}
                navigation={{
                    title: '',
                    to: HOLOPLAYER_PATH.LIBRARY_TALENT_COLLECTION,
                }}
                searchId={pathname}
            />
        );
    }

    return (
        <SongCollection
            collection={currentGeneration?.songs?.all || []}
            navigation={{
                title: currentGeneration.name,
                to: HOLOPLAYER_PATH.LIBRARY_GENERATION_COLLECTION,
            }}
            searchId={pathname}
        />
    );
};

export default GenerationSongPage;
