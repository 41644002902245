import { ListItemIcon, Avatar, ListItemText, ListItem, ListItemButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { closeSheet, doFullscreen, exitFullscreen, openSheet } from '../../slice/sheetSlice';
import { SWIPEABLE_SHEET_CHANGE_THRESHOLD } from '../../constants';

import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import SkipNextRoundedIcon from '@mui/icons-material/SkipNextRounded';
import { useTheme } from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import usePlayer from '../feature/player/usePlayer';
import SongSheet from '../feature/songSheet/SongSheet';
import { useMemo } from 'react';

const ControllBar = () => {
    const dispatch = useAppDispatch();
    const song = useAppSelector((state) => state.song.current.song);
    const theme = useTheme();
    const Player = usePlayer();
    const sheet = useAppSelector((state) => state.sheet);
    const playing = useAppSelector((state) => state.player.playing);

    const ActionButton = ({ children, onClick }: { children: any; onClick?: any }) => {
        return (
            <ListItemButton
                onClick={onClick}
                sx={{
                    flexGrow: 0,
                    flexShrink: 0,
                }}
            >
                {children}
            </ListItemButton>
        );
    };

    const handlers = useSwipeable({
        onSwipedUp: (e) => {
            if (e.absY > SWIPEABLE_SHEET_CHANGE_THRESHOLD) {
                dispatch(openSheet());
            }
        },
    });

    const ControllBarMemo = useMemo(() => {
        const EllipsisListItemTextStyles = [
            {
                '& .MuiListItemText-primary, & .MuiListItemText-secondary': {
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                },
            },
        ];
        return (
            <ListItem
                component="div"
                dense
                disablePadding
                sx={{
                    alignItems: 'stretch',
                    backgroundColor: theme.palette.background.paper,
                }}
                {...handlers}
            >
                <ListItemButton
                    sx={{
                        flexGrow: 1,
                        flexShrink: 1,
                    }}
                    onClick={() => {
                        dispatch(openSheet());
                    }}
                >
                    <ListItemIcon>
                        <Avatar src={song?.thumbnail_url || ''} variant="rounded" />
                    </ListItemIcon>
                    <ListItemText
                        sx={EllipsisListItemTextStyles}
                        primary={song?.title}
                        secondary={song?.artist?.name}
                    />
                </ListItemButton>

                {playing ? (
                    <ActionButton onClick={() => Player.Pause()}>
                        <PauseRoundedIcon />
                    </ActionButton>
                ) : (
                    <ActionButton onClick={() => Player.HandleClickPlayButton()}>
                        <PlayArrowRoundedIcon />
                    </ActionButton>
                )}

                <ActionButton onClick={() => Player.Next()}>
                    <SkipNextRoundedIcon />
                </ActionButton>
                <SongSheet
                    isOpen={sheet.isOpen}
                    isFull={sheet.isFull}
                    doClose={() => dispatch(closeSheet())}
                    doFullscreen={() => dispatch(doFullscreen())}
                    doExitFullscreen={() => dispatch(exitFullscreen())}
                />
            </ListItem>
        );
        // React Hook useMemo has missing dependencies: 'Player', 'dispatch', 'handlers', and 'theme.palette.background.paper'.
        // eslint-disable-next-line
    }, [sheet, song, playing, theme.palette.background.paper]);

    return ControllBarMemo;
};

export default ControllBar;
