import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type PlayerStatusType = {
    playing: boolean;
    volume: number;
    muted: boolean;
    loop: boolean;
    played: number;
    loaded: number;
    duration: number;
    playbackRate: number;
    pip: boolean;
    seeking?: boolean;
    ref?: any;
};
const initialState: PlayerStatusType = {
    playing: false,
    volume: 1,
    muted: false,
    loop: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    pip: false,
    seeking: false,
    ref: undefined,
};

const songSlice = createSlice({
    name: 'player',
    initialState,
    reducers: {
        handleChangeSrc: (state, action: PayloadAction) => {
            state.playing = true;
            state.played = 0;
            state.loaded = 0;
            state.duration = 0;
        },
        doPlay: (state, action: PayloadAction) => {
            state.playing = true;
        },
        doPause: (state, action: PayloadAction) => {
            state.playing = false;
        },
        changeVolume: (state, action: PayloadAction<number>) => {
            const volume = action.payload;
            if (volume >= 0 && volume <= 1) {
                state.volume = volume;
            }
        },
        changeMuted: (state, action: PayloadAction<boolean>) => {
            state.muted = action.payload;
        },
        changeLoop: (state, action: PayloadAction<boolean>) => {
            state.loop = action.payload;
        },
        onProgress: (state, action: PayloadAction<{ played: number; loaded: number }>) => {
            const { played, loaded } = action.payload;

            if (played >= 0 && played <= 1) {
                state.played = played;
            } else {
                state.played = 0;
            }

            if (loaded >= 0 && loaded <= 1) {
                state.loaded = loaded;
            } else {
                state.loaded = 0;
            }
        },
        changePlayed: (state, action: PayloadAction<number>) => {
            const played = action.payload;
            if (played >= 0 && played <= 1) {
                state.played = played;
            } else {
                state.played = 0;
            }
        },
        changeLoaded: (state, action: PayloadAction<number>) => {
            const loaded = action.payload;
            if (loaded >= 0 && loaded <= 1) {
                state.loaded = loaded;
            }
        },
        changeDuration: (state, action: PayloadAction<number>) => {
            const duration = action.payload;
            state.duration = duration;
        },
        changePlaybackRate: (state, action: PayloadAction<number>) => {
            const playbackRate = action.payload;
            state.playbackRate = playbackRate;
        },
        changePip: (state, action: PayloadAction<boolean>) => {
            state.pip = action.payload;
        },
        changeSeeking: (state, action: PayloadAction<boolean>) => {
            state.seeking = action.payload;
        },
    },
});

export const {
    doPlay,
    doPause,
    changeVolume,
    changeMuted,
    changeLoop,
    changePlayed,
    changeLoaded,
    onProgress,
    changeDuration,
    changePlaybackRate,
    changePip,
    changeSeeking,
    handleChangeSrc,
} = songSlice.actions;

export default songSlice.reducer;
