import { Box, Chip, Divider } from '@mui/material';
import ControllSection from './DefaultSheetTab/ControllSection';

import { useAppSelector } from '../../../../app/hooks';
import usePlayer from '../../player/usePlayer';
import ActionButtonSection from './DefaultSheetTab/ActionButtonSection';
import InfoSection from './DefaultSheetTab/InfoSection';

const SrcChips = () => {
    const song = useAppSelector((state) => state.song.current.song);
    const Player = usePlayer();

    return (
        <Box sx={{ margin: '16px', display: 'flex', gap: '8px' }}>
            <Chip
                color="primary"
                variant={song?.video_id.active === song?.video_id.mv ? 'filled' : 'outlined'}
                disabled={!song?.video_id.mv}
                label="MV"
                onClick={() => Player.ChangeSrc('mv')}
            />
            <Chip
                color="primary"
                variant={song?.video_id.active === song?.video_id.topic ? 'filled' : 'outlined'}
                disabled={!song?.video_id.topic}
                label="フル"
                onClick={() => Player.ChangeSrc('topic')}
            />
        </Box>
    );
};

const DefaultSheetTab = () => {
    return (
        <Box
            sx={{
                padding: '0 0 120px',
            }}
        >
            <ControllSection />
            <Divider />
            <Box>
                <SrcChips />
            </Box>
            <Divider />
            <Box>
                <ActionButtonSection />
            </Box>
            <Divider />
            <InfoSection />
        </Box>
    );
};
export default DefaultSheetTab;
