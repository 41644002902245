import { useAppSelector } from '../../app/hooks';
import SongCollection from '../feature/collection/SongCollection';
import { FilterLatest30Songs } from '../../lib/songCollectionFilterAndSorter';
import { HOLOPLAYER_PATH } from '../../route/route';

const Latest30SongsPage = () => {
    const songState = useAppSelector((state) => state.song);

    return (
        <SongCollection
            collection={songState.isLoaded ? FilterLatest30Songs(songState.items) : 'loading'}
            navigation={{
                title: '最新30曲',
                to: HOLOPLAYER_PATH.LIBRARY,
            }}
            searchId="Latest30SongsPage"
        />
    );
};

export default Latest30SongsPage;
