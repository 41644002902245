import { useAppSelector } from '../../app/hooks';
import SongCollection from '../feature/collection/SongCollection';
import { FilterIn30days } from '../../lib/songCollectionFilterAndSorter';
import { HOLOPLAYER_PATH } from '../../route/route';

const In30DaysSongsPage = () => {
    const songState = useAppSelector((state) => state.song);

    return (
        <SongCollection
            collection={songState.isLoaded ? FilterIn30days(songState.items) : 'loading'}
            navigation={{
                title: '30日以内公開',
                to: HOLOPLAYER_PATH.LIBRARY,
            }}
            searchId="In30DaysSongPage"
        />
    );
};

export default In30DaysSongsPage;
