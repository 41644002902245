import { useLocation, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { HOLOPLAYER_PATH } from '../../../route/route';
import SongCollection from '../../feature/collection/SongCollection';
import { Box, Button, Typography } from '@mui/material';
import NavigationBar from '../../ui/NavigationBar/NavigationBar';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CustomAvator from '../../ui/CustomAvator';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import useAlbum from '../../../slice/album/useAlbum';
import NoContent from '../../ui/NoContent';

const NotFoundAlbum = () => {
    return (
        <Box>
            <NavigationBar title="アルバムの曲" to={HOLOPLAYER_PATH.LIBRARY_ALBUM_COLLECTION} />
            <NoContent />
        </Box>
    );
};

const AlbumSongPage = () => {
    const Album = useAlbum();
    useEffect(() => {
        Album.fetchAlbum();
    }, [Album]);

    const params = useParams();
    const { pathname } = useLocation();
    const albumState = useAppSelector((state) => state.album);
    const album = albumState.items.find(
        (item) => params?.id && item.id === parseInt(params?.id, 10),
    );

    if (!album) return <NotFoundAlbum />;

    const date = dayjs(album.published_raw, 'YYYYMMDD', 'Asia/Tokyo');

    const height = 240;

    const Lead = (): JSX.Element => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '8px',
                    height: `${height}px`,
                }}
            >
                <CustomAvator
                    src={`https://holoplayer-api.guguji-netaru.com/img${album.thumbnail}`}
                    width={120}
                    height={120}
                    alt={album.title}
                    variant="rounded"
                />
                <Typography variant="caption">{date.format('YYYY/MM/DD')}</Typography>
                <Button
                    variant="outlined"
                    target="_blank"
                    startIcon={<YouTubeIcon />}
                    href={`https://youtube.com/playlist?list=${album.youtube_playlist_id}`}
                    disabled={album.youtube_playlist_id ? false : true}
                    sx={{ flexShrink: 1 }}
                    size="small"
                >
                    YouTube プレイリスト
                </Button>
            </Box>
        );
    };

    return (
        <SongCollection
            collection={albumState.isLoaded ? album.song : 'loading'}
            navigation={{
                title: album.title,
                to: HOLOPLAYER_PATH.LIBRARY_ALBUM_COLLECTION,
            }}
            searchId={pathname}
            lead={{
                height: height,
                element: <Lead />,
            }}
            isAlbumSongPage={true}
        />
    );
};

export default AlbumSongPage;
