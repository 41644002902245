import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import accountSlice from '../slice/accountSlice';
import settingSlice from '../slice/settingSlice';
import songSlice from '../slice/songSlice';
import sheetSlice from '../slice/sheetSlice';
import quizSlice from '../slice/quiz/quizSlice';
import dialogSlice from '../slice/dialogSlice';
import personSlice from '../slice/person/personSlice';
import talentSlice from '../slice/talent/talentSlice';
import albumSlice from '../slice/album/albumSlice';
import infoSlice from '../slice/infoSlice';
import unitSlice from '../slice/unit/unitSlice';
import playerSlice from '../slice/playerSlice';
import generationSlice from '../slice/generation/generationSlice';
import searchSlice from '../slice/searchSlice';

export const store = configureStore({
    reducer: {
        account: accountSlice,
        setting: settingSlice,
        song: songSlice,
        player: playerSlice,
        sheet: sheetSlice,
        quiz: quizSlice,
        dialog: dialogSlice,
        person: personSlice,
        talent: talentSlice,
        album: albumSlice,
        info: infoSlice,
        unit: unitSlice,
        generation: generationSlice,
        search: searchSlice,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
