import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const SearchSortArray = [
    {
        order: 'publishedAt',
        orderBy: 'DESC',
        label: '新しい順',
    },
    {
        order: 'publishedAt',
        orderBy: 'ASC',
        label: '古い順',
    },
    {
        order: 'shuffle',
        orderBy: null,
        label: 'シャッフル',
    },
] as const;

export const SearchOrderTypeArray = SearchSortArray.map((sort) => sort.order);
export type SearchOrderType = typeof SearchOrderTypeArray[number];

export const SearchOrderByTypeArray = SearchSortArray.map((sort) => sort.orderBy);
export type SearchOrderByType = typeof SearchOrderByTypeArray[number];

type SeachStateItemType = {
    id: string;
    query: string;
    order: SearchOrderType;
    orderBy: SearchOrderByType;
};

type SearchStateType = {
    items: SeachStateItemType[];
};

const initialState: SearchStateType = {
    items: [],
};

const searchSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        addCondition: (state, action: PayloadAction<string>) => {
            if (state.items.find((item) => item.id === action.payload)) return;
            state.items = [
                ...state.items,
                {
                    id: action.payload,
                    query: '',
                    order: 'publishedAt',
                    orderBy: 'DESC',
                },
            ];
        },
        setQuery: (state, action: PayloadAction<{ id: string; query: string }>) => {
            const item = state.items.find((item) => item.id === action.payload.id);
            if (item) {
                item.query = action.payload.query;
            }
        },
        changeSort: (
            state,
            action: PayloadAction<{
                id: string;
                order: SearchOrderType;
                orderBy: SearchOrderByType;
            }>,
        ) => {
            const { id, order, orderBy } = action.payload;
            const item = state.items.find((item) => item.id === id);
            if (item) {
                item.order = order;
                item.orderBy = orderBy;
            }
        },
    },
});

export const { addCondition, setQuery, changeSort } = searchSlice.actions;

export default searchSlice.reducer;
