import { Box, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

const NavLeft = ({ to, isShowInput }: { to?: string; isShowInput: boolean }) => {
    if (to && !isShowInput) {
        return (
            <Box
                sx={{
                    width: '48px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <IconButton color="primary" component={Link} to={to}>
                    <ArrowBackIosNewRoundedIcon />
                </IconButton>
            </Box>
        );
    } else {
        return <></>;
    }
};

export default NavLeft;
