import { useAppDispatch } from '../../../app/hooks';
import { InfoType, addInfo, closeInfo } from '../../../slice/infoSlice';

const useInfo = () => {
    const dispatch = useAppDispatch();

    const Add = (info: InfoType) => {
        dispatch(addInfo(info));
    };

    const Close = (code: number) => {
        dispatch(closeInfo(code));
    };

    return {
        Add,
        Close,
    };
};
export default useInfo;
