import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchAllAlbum } from '../../lib/fetch';
import { webWorkerAsync } from '../../lib/webWorker';
import { AlbumItemType } from '../../type/hololive.types';
import {
    completeComputeSearchString,
    completeLoadAlbum,
    startComputeSearchString,
} from './albumSlice';

const task = (message: MessageEvent<AlbumItemType[]>): AlbumItemType[] => {
    const albums = message.data;
    return albums.map((album) => {
        return {
            ...album,
            search_all: album.song
                .map((song) => {
                    return [song.title, song.search_talent].join('\n');
                })
                .join('\n'),
        };
    });
};

const useAlbum = () => {
    const { isLoaded, items, searchStringStatus } = useAppSelector((state) => state.album);
    const dispatch = useAppDispatch();

    const fetchAlbum = () => {
        if (isLoaded) return;

        fetchAllAlbum().then((res) => {
            if (res) {
                dispatch(completeLoadAlbum(res));
            }
        });
    };

    const setSearchString = () => {
        if (!isLoaded) return;
        if (searchStringStatus === 'ready') {
            dispatch(startComputeSearchString());
            webWorkerAsync<AlbumItemType[], AlbumItemType[]>(task, items).then((done) => {
                dispatch(completeComputeSearchString(done));
            });
        }
    };

    return {
        isLoaded,
        items,
        fetchAlbum,
        setSearchString,
    };
};
export default useAlbum;
