import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded';
import RepeatOneRoundedIcon from '@mui/icons-material/RepeatOneRounded';
import { useAppSelector } from '../../app/hooks';

const RepeatIcon = () => {
    const setting = useAppSelector((state) => state.setting);
    if (setting.repeat === 'all') {
        return <RepeatRoundedIcon />;
    } else if (setting.repeat === 'one') {
        return <RepeatOneRoundedIcon />;
    }
    return <RepeatRoundedIcon />;
};
export default RepeatIcon;
