import { Box, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';

const Handle = (props: { children: ReactNode; draggable: boolean }) => {
    const { children, draggable } = props;
    const theme = useTheme();
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                flexGrow: 0,
                flexShrink: 0,
                background: theme.palette.background.paper,
            }}
        >
            <Box
                sx={{
                    width: 40,
                    height: 4,
                    backgroundColor: theme.palette.grey[600],
                    borderRadius: 3,
                    margin: '6px 0 6px',
                    flexGrow: 0,
                    flexShrink: 0,
                    opacity: draggable ? 1 : 0,
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    padding: '0 8px 0 16px',
                    height: '48px',
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default Handle;
