import { Skeleton, SxProps, Theme, useTheme } from '@mui/material';
import { useState } from 'react';
import BrokenImageRoundedIcon from '@mui/icons-material/BrokenImageRounded';
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded';

const CustomImage = ({
    alt,
    thumbnail,
    width = '100%',
    height = '100%',
    rounded = true,
    ...props
}: {
    alt: string;
    thumbnail: string | null;
    width?: string | number;
    sx?: SxProps<Theme>;
    rounded?: boolean;
} & JSX.IntrinsicElements['img']): JSX.Element => {
    const src = `https://holoplayer-api.guguji-netaru.com/img${thumbnail}`;
    const [loadStatus, setLoadStatus] = useState<'ready' | 'loading' | 'success' | 'error'>(
        'ready',
    );

    const roundValue = rounded ? '8px' : 0;
    const theme = useTheme();

    const img = new Image();
    if (thumbnail) {
        img.src = src;
        img.onload = (e) => {
            setLoadStatus('success');
        };
        img.onerror = (e) => {
            setLoadStatus('error');
        };
    } else {
        return (
            <LibraryMusicRoundedIcon
                sx={{
                    width,
                    height: 'auto',
                    aspectRatio: '1',
                    flexShrink: 1,
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: roundValue,
                    fill: theme.palette.divider,
                    p: 4,
                }}
            />
        );
    }

    if (loadStatus === 'error') {
        return (
            <BrokenImageRoundedIcon
                sx={{
                    width,
                    height: 'auto',
                    aspectRatio: '1',
                    flexShrink: 1,
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: roundValue,
                    fill: theme.palette.grey[500],
                }}
            />
        );
    }

    if (loadStatus === 'success') {
        return (
            <img
                src={src}
                alt={alt}
                {...props}
                style={{
                    width,
                    height: 'auto',
                    aspectRatio: '1',
                    borderRadius: roundValue,
                    border: `solid 0.5px ${theme.palette.imageDivider}`,
                }}
            />
        );
    }
    return (
        <Skeleton
            sx={{
                width,
                height: 'auto',
                aspectRatio: '1',
                transform: 'inherit',
            }}
        />
    );
};
export default CustomImage;
