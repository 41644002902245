import { Button, ButtonTypeMap } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';

const YouTubeLinkButton = ({
    video_id,
    label,
    size = 'small',
}: {
    props?: ButtonTypeMap;
    video_id: string | null;
    label: string;
    size?: 'small' | 'medium' | 'large';
}) => {
    return (
        <Button
            variant="outlined"
            target="_blank"
            startIcon={<YouTubeIcon />}
            href={`https://youtu.be/${video_id}`}
            disabled={video_id ? false : true}
            sx={{ margin: '0 8px 0 0' }}
            size={size}
        >
            {label}
        </Button>
    );
};
export default YouTubeLinkButton;
