import React, { useState } from 'react';
import { Box, Typography, Divider, useTheme } from '@mui/material';
import { HOLOPLAYER_ZINDEX } from '../../../constants';
import { SearchOrderByType, SearchOrderType } from '../../../slice/searchSlice';
import NavLeft from './NavLeft';
import NavRight from './NavRight';
import NavSearchInput from './NavSearchInput';

const NavigationBar = ({
    title,
    to,
    query,
    setQuery,
    handleClickSetting,
    settingCount = 0,
    placeholder = '曲名、タレント、作曲者、歌詞...',
    sort,
}: {
    title: string;
    to?: string;
    query?: string;
    setQuery?: (value: string) => void;
    handleClickSetting?: () => void;
    settingCount?: number;
    placeholder?: string;
    sort?: {
        order: SearchOrderType;
        orderBy: SearchOrderByType;
        handleChangeSort: (order: SearchOrderType, orderNy: SearchOrderByType) => void;
    };
}) => {
    const theme = useTheme();
    const [isShowInput, setShowInput] = useState<boolean>(query ? query.length > 0 : false);

    return (
        <Box
            sx={{
                height: '48px',
                display: 'flex',
                flexShrink: 0,
                flexDirection: 'column',
                justifyContent: 'center',
                position: 'sticky',
                top: 0,
                zIndex: HOLOPLAYER_ZINDEX.NAVIGATION_BAR,
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <Box sx={{ flexGrow: 1, display: 'flex', width: '100%' }}>
                <NavLeft to={to} isShowInput={isShowInput} />
                <Box
                    sx={{
                        flexGrow: 1,
                        flexShrink: 1,
                        overflow: 'hidden',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        marginLeft: '16px',
                    }}
                >
                    {isShowInput && setQuery ? (
                        <NavSearchInput
                            setShowInput={setShowInput}
                            value={query}
                            setQuery={setQuery}
                            placeholder={placeholder}
                        />
                    ) : (
                        <Typography
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {title}
                        </Typography>
                    )}
                </Box>
                <NavRight
                    sort={sort}
                    isShowInput={isShowInput}
                    setShowInput={setShowInput}
                    handleClickSetting={handleClickSetting}
                    setQuery={setQuery}
                    settingCount={settingCount}
                />
            </Box>
            <Divider sx={{ flexShrink: 0 }} />
        </Box>
    );
};

export default NavigationBar;
