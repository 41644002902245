import { changeSong, changeSrc } from '../../../slice/songSlice';
import {
    doPause,
    doPlay,
    onProgress,
    changeDuration,
    changePlaybackRate,
    changePlayed,
    changeSeeking,
    handleChangeSrc,
} from '../../../slice/playerSlice';

import {
    setRepeat,
    RepeatType,
    setRepeatNext,
    SabiMedleyType,
    setSabiMedley,
    toggleSabiMedley,
    PlayerControlsType,
    setPlayerControls,
} from '../../../slice/settingSlice';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { getNextVideo, getPrevVideo } from './player_misc';
import { SongItemType } from '../../../type/hololive.types';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../app/firebase';

const usePlayer = () => {
    const dispatch = useAppDispatch();
    const item = useAppSelector((state) => state.song.current.song);
    const collection = useAppSelector((state) => state.song.current.playlist);
    const setting = useAppSelector((state) => state.setting);

    /**
     * 再生リストの1つ次の曲にする
     */
    const Next = () => {
        if (!item) return false;
        const next_video = getNextVideo(
            item,
            collection,
            setting.repeat === 'all',
            setting.sabiMedley,
        );
        if (next_video) {
            return ChangeSong({
                item: next_video,
                collection,
            });
        } else {
            Pause();
        }
        return false;
    };

    /**
     * 再生リストの1つ前の曲にする
     */
    const Prev = () => {
        if (!item) return false;
        const prev_video = getPrevVideo(
            item,
            collection,
            setting.repeat === 'all',
            setting.sabiMedley,
        );
        if (prev_video) {
            return ChangeSong({
                item: prev_video,
                collection,
            });
        }
        return false;
    };

    /**
     * 停止状態にする
     */
    const Pause = () => {
        dispatch(doPause());
    };

    /**
     * 再生状態にする
     */
    const Play = () => {
        dispatch(doPlay());
    };

    /**
     * 明確に再生ボタンを押下するアクション
     */
    const HandleClickPlayButton = () => {
        if (setting.sabiMedley && !item?.sabi) {
            ChangeSabiMedley(false);
        }
        Play();
    };

    /**
     * 早送り
     */
    const SeekForwardEventName = 'PlayerSeekForwardEvent';
    const SeekForwardEvent = new CustomEvent(SeekForwardEventName);
    const ListenSeekForwardEvent = (callback: () => void) => {
        document.addEventListener(SeekForwardEventName, callback);
    };
    const SeekForward = () => {
        document.dispatchEvent(SeekForwardEvent);
    };

    /**
     * 巻き戻し
     */
    const SeekBackwardEventName = 'PlayerSeekBackwardEvent';
    const SeekBackwardEvent = new CustomEvent(SeekBackwardEventName);
    const ListenSeekBackwardEvent = (callback: () => void) => {
        document.addEventListener(SeekBackwardEventName, callback);
    };
    const SeekBackward = () => {
        document.dispatchEvent(SeekBackwardEvent);
    };

    /**
     * リピート設定の変更
     */
    const ChangeRepeat = (newValue?: RepeatType) => {
        if (newValue) {
            return dispatch(setRepeat(newValue));
        }
        return dispatch(setRepeatNext());
    };

    /**
     * サビメドレー設定の変更
     */
    const ChangeSabiMedley = (newValue?: SabiMedleyType) => {
        return dispatch(setSabiMedley(!!newValue));
    };
    const ToggleSabiMedley = () => {
        return dispatch(toggleSabiMedley());
    };

    /**
     * プレイヤーのコントローラ設定の変更
     */
    const ChangePlayerControls = (newValue: PlayerControlsType) => {
        return dispatch(setPlayerControls(newValue));
    };

    /**
     * 曲の変更
     */
    const ChangeSong = (props: { item: SongItemType; collection: SongItemType[] }) => {
        const { item, collection } = props;

        logEvent(analytics, 'changeSong', {
            id: item.id,
            title: item.title,
        });
        dispatch(handleChangeSrc());
        return dispatch(
            changeSong({
                item,
                collection,
            }),
        );
    };

    /**
     * 曲の変更: サビなしサビメドレー用
     */
    const HandleClickChangeSongButton = (props: {
        item: SongItemType;
        collection: SongItemType[];
    }) => {
        const { item, collection } = props;
        if (setting.sabiMedley && !item?.sabi) {
            ChangeSabiMedley(false);
        }
        return ChangeSong({
            item,
            collection,
        });
    };

    /**
     * 曲の映像ソースを変更
     */
    const ChangeSrc = (srcType: 'mv' | 'topic') => {
        if (setting.sabiMedley) {
            ChangeSabiMedley(false);
        }
        return dispatch(changeSrc(srcType));
    };

    /**
     * 動画の現在の再生位置が変更されたとき
     */
    const HandleProgress = (props: { played: number; loaded: number }) => {
        const { played, loaded } = props;
        return dispatch(onProgress({ played, loaded }));
    };

    /**
     * シーク中
     */
    const HandleSeeking = (played: number) => {
        dispatch(changeSeeking(true));
        return dispatch(changePlayed(played));
    };

    /**
     *  シークが終わった
     */
    const handleChangeCommittedPlayed = () => {
        return dispatch(changeSeeking(false));
    };

    /**
     * 再生長が変わったとき
     */
    const HandleChangeDuration = (seconds: number) => {
        return dispatch(changeDuration(seconds));
    };

    /**
     * 再生速度が変わったとき
     */
    const HandleChangePlaybackRate = (rate: number) => {
        return dispatch(changePlaybackRate(rate));
    };

    return {
        Next,
        Prev,
        Pause,
        Play,
        SeekBackward,
        SeekForward,
        ListenSeekForwardEvent,
        ListenSeekBackwardEvent,
        ChangeRepeat,
        ChangeSabiMedley,
        ToggleSabiMedley,
        ChangeSong,
        HandleClickChangeSongButton,
        HandleProgress,
        HandleChangeDuration,
        HandleChangePlaybackRate,
        ChangePlayerControls,
        ChangeSrc,
        HandleClickPlayButton,
        HandleSeeking,
        handleChangeCommittedPlayed,
    };
};

export default usePlayer;
