import React, { MouseEvent } from 'react';
import { Menu, MenuItem, ListItemIcon } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

const CustomMenu = <T,>({
    handleChange,
    anchorEl,
    setAnchorEl,
    labelAndValueMap,
    value,
}: {
    handleChange: (newValue: T) => void;
    anchorEl: HTMLElement | null;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    labelAndValueMap: { label: string; value: T }[];
    value: T;
}) => {
    const open = Boolean(anchorEl);

    return (
        <>
            <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
                {labelAndValueMap.map((item, index) => (
                    <MenuItem
                        key={index}
                        selected={item.value === value}
                        onClick={(e: MouseEvent<HTMLElement>) => {
                            setAnchorEl(null);
                            handleChange(item.value);
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                opacity: item.value === value ? 1 : 0.1,
                            }}
                        >
                            <CheckRoundedIcon
                                color={item.value === value ? 'primary' : 'inherit'}
                            />
                        </ListItemIcon>
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default CustomMenu;
