import {
    GenerationItemType,
    PersonItemType,
    SongItemType,
    TalentItemType,
} from '../type/hololive.types';
import { shuffleArray, katakanaToHiragana, hiraganaToKatakana, isAfterDays } from './misc';
import dayjs from 'dayjs';
import { SearchOrderByType, SearchOrderType } from '../slice/searchSlice';

/**
 * 公開日時降順で30曲返す
 * @param collection
 * @returns
 */
export const FilterLatest30Songs = (collection: SongItemType[]): SongItemType[] => {
    return SortPublishAtDesc(collection).slice(0, 30);
};

/**
 * 30日以内にリリースされた曲に絞り込む
 * @param collection
 * @returns
 */
export const FilterIn30days = (collection: SongItemType[]): SongItemType[] => {
    return collection.filter((song) => {
        if (!song?.published_unix) return false;
        return isAfterDays(song.published_unix, 30);
    });
};

/**
 * 指定のタレントIDで曲を絞り込む
 * @param collection
 * @returns
 */
export const FilterByTalentId = (
    collection: SongItemType[],
    talent: TalentItemType,
): SongItemType[] => {
    return collection.filter((song) => {
        return song.talent?.some((item) => talent.id === item.id);
    });
};

/**
 * 指定のパーソンIDで曲を絞り込む。重そう。
 */
export const FilterByPersonId = (
    collection: SongItemType[],
    person: PersonItemType,
): SongItemType[] => {
    return collection.filter((song) => {
        return [song.composer, song.arranger, song.lyricist, song.guest]
            .flat()
            .some((p) => p?.id === person.id);
    });
};

/**
 * 指定のジェネレーションIDで曲を絞り込む
 * @param collection
 * @returns
 */
export const FilterByGenerationId = (
    collection: SongItemType[],
    generation: GenerationItemType,
): SongItemType[] => {
    return collection.filter((song) => {
        return song.artist?.id === generation.id;
    });
};

/**
 * 公開日時降順にソート
 */
export const SortPublishAtDesc = (collection: SongItemType[]): SongItemType[] => {
    return [...collection].sort((a, b) => {
        if (a.published_unix === null || b.published_unix === null) return 0;

        const a_dayjs = dayjs(a.published_unix);
        const b_dayjs = dayjs(b.published_unix);

        if (!a_dayjs || !b_dayjs) return 0;

        const a_valid = a_dayjs.isValid();
        const b_valid = b_dayjs.isValid();

        if (!a_valid || !b_valid) return 0;

        const a_unix = a_dayjs.unix();
        const b_unix = b_dayjs.unix();

        if (a_unix < b_unix) return 1;
        if (a_unix > b_unix) return -1;
        return 0;
    });
};

/**
 * 公開日時昇順でソート
 * @param collection
 * @returns
 */
export const SortPublishAtAsc = (collection: SongItemType[]): SongItemType[] => {
    return SortPublishAtDesc(collection).reverse();
};

/**
 * ランダムにする
 */
export const Shuffle = (collection: SongItemType[]): SongItemType[] => {
    return shuffleArray(collection);
};

/**
 * 任意の文字列でフィルタする
 */
export const FilterByQuery = (collection: SongItemType[], query?: string): SongItemType[] => {
    if (!query) return collection;

    const hiragana = katakanaToHiragana(query);
    const katakana = hiraganaToKatakana(query);

    return collection.filter((song) => {
        if (!song.search_all) return false;
        return new RegExp(`(${hiragana}|${katakana})`, 'igms').test(song.search_all);
    });
};

export const SortSongCollection = (
    collection: SongItemType[],
    order: SearchOrderType,
    orderBy: SearchOrderByType,
): SongItemType[] => {
    if (order === 'shuffle') {
        return shuffleArray(collection);
    }
    if (order === 'publishedAt' && orderBy === 'ASC') {
        return SortPublishAtAsc(collection);
    }
    if (order === 'publishedAt' && orderBy === 'DESC') {
        return SortPublishAtDesc(collection);
    }

    return collection;
};
