import { Avatar, Box, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded';

const CustomAvator = ({
    width,
    height,
    src,
    alt,
    variant,
}: {
    width: number;
    height: number;
    src: string;
    alt: string;
    variant: 'circular' | 'rounded';
}) => {
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const [hasError, setError] = useState<boolean>(false);

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setLoaded(true);
        };
        img.onerror = () => {
            setError(true);
        };
        if (!src) {
            setError(true);
        }
    }, [src]);

    if (hasError) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    width,
                    height,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <MusicNoteRoundedIcon />
            </Box>
        );
    } else if (isLoaded) {
        return (
            <Avatar
                src={src}
                alt={alt}
                variant={variant}
                sx={{
                    width,
                    height,
                }}
            />
        );
    } else {
        return (
            <Skeleton
                variant={variant}
                sx={{
                    width,
                    height,
                }}
            />
        );
    }
};

export default CustomAvator;
