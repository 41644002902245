import { Box } from '@mui/material';
import { useAppSelector } from '../../../../app/hooks';
import WeatherHackers from './WeatherHackers';

const LyricsTab = () => {
    const song = useAppSelector((state) => state.song.current.song);

    if (song?.video_id.mv === 'khRDaLiyGdA') {
        return <WeatherHackers />;
    }

    return (
        <Box
            sx={{
                padding: '16px 16px 120px',
            }}
        >
            {song?.lyric?.length ? (
                song.lyric?.map((line, index) => {
                    return (
                        <Box key={index} sx={{ marginBottom: '4px', fontSize: '14px' }}>
                            {line}
                        </Box>
                    );
                })
            ) : (
                <Box>(未登録)</Box>
            )}
        </Box>
    );
};
export default LyricsTab;
