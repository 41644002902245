import { Box, Skeleton, Divider } from '@mui/material';

const SongItemSkeleton = () => {
    return (
        <Box sx={{ margin: '4px 0 0' }}>
            <Box
                sx={{
                    display: 'flex',
                    margin: '0 16px 0 16px',
                    flexShrink: 0,
                }}
            >
                <Skeleton
                    variant="rounded"
                    sx={{
                        width: 48,
                        height: 48,
                        marginTop: '2px',
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '12px',
                        justifyContent: 'top',
                    }}
                >
                    <Skeleton
                        variant="rounded"
                        width={200}
                        sx={{
                            margin: '6px 0 6px',
                        }}
                    />
                    <Skeleton
                        variant="rounded"
                        width={120}
                        sx={{
                            fontSize: '10px',
                        }}
                    />
                </Box>
            </Box>
            <Divider
                variant="inset"
                component="div"
                sx={{
                    margin: '8px 16px 6px 74px',
                    borderWidth: 0.5,
                }}
            />
        </Box>
    );
};

export default SongItemSkeleton;
