import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useCallback } from 'react';
import { closeSheet } from '../../../slice/sheetSlice';

const useSong = () => {
    const dispatch = useAppDispatch();
    const navigation = useNavigate();

    const navigateTo = useCallback(
        (to: string) => {
            if (to) {
                navigation(to);
                dispatch(closeSheet());
            }
        },
        [navigation, dispatch],
    );
    const songState = useAppSelector((state) => state.song);

    const current = songState.current;

    const items = songState.items;

    return {
        items,
        isLoaded: songState.isLoaded,
        current,
        navigateTo,
    };
};

export default useSong;
