import React from 'react';
import { Box, List } from '@mui/material';
import { HOLOPLAYER_PATH } from '../../route/route';
import NavigationBar from '../ui/NavigationBar/NavigationBar';

import CustomListItem from '../ui/CustomListItem';

const Library = () => {
    return (
        <Box>
            <NavigationBar title="ライブラリ" />
            <List sx={{}}>
                <CustomListItem
                    primary="タレント（ホロメン）"
                    to={HOLOPLAYER_PATH.LIBRARY_TALENT_COLLECTION}
                    isLoading={false}
                />
                <CustomListItem
                    primary="ユニット"
                    to={HOLOPLAYER_PATH.LIBRARY_UNIT_COLLECTION}
                    isLoading={false}
                />
                <CustomListItem
                    primary="ジェネレーション （期生）"
                    to={HOLOPLAYER_PATH.LIBRARY_GENERATION_COLLECTION}
                    isLoading={false}
                />
                <CustomListItem
                    primary="アルバム"
                    to={HOLOPLAYER_PATH.LIBRARY_ALBUM_COLLECTION}
                    isLoading={false}
                />
                <CustomListItem
                    primary="作詞/作曲/編曲/箱外歌唱"
                    to={HOLOPLAYER_PATH.LIBRARY_PERSON_COLLECTION}
                    isLoading={false}
                />
                <CustomListItem
                    primary="最新30曲"
                    to={HOLOPLAYER_PATH.LATEST_30_SONGS}
                    isLoading={false}
                />
                <CustomListItem
                    primary="30日以内リリース"
                    to={HOLOPLAYER_PATH.IN_30_DAYS_SONGS}
                    isLoading={false}
                />
                <CustomListItem
                    primary="データテーブル"
                    to={HOLOPLAYER_PATH.LIBRARY_DATA}
                    isLoading={false}
                />
            </List>
        </Box>
    );
};

export default Library;
