import { Avatar } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../app/hooks';
import Logout from '../ui/Logout';
import styled from '@emotion/styled';

const CustomDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
`;

const CustomAvatar = styled(Avatar)`
    display: inline-block;
`;

const DisplayName = styled.div`
    padding: 24px;
    font-size: 16px;
`;

const Ligined = () => {
    const account = useAppSelector((state) => state.account);
    return (
        <CustomDiv>
            <CustomAvatar src={account.photoURL || ''} sx={{ width: 64, height: 64 }} />
            <DisplayName>{account.displayName}</DisplayName>
            <Logout />
        </CustomDiv>
    );
};

export default Ligined;
