import { Box } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import { isDevEnv } from '../../../lib/misc';
import BottomSheet from '../../ui/BottomSheet/BottomSheet';
import Question from './Question';
import QuizDone from './QuizDone';
import QuizSheetHandle from './QuizSheetHandle';
import useQuiz from '../../../slice/quiz/useQuiz';

const QuizSheet = () => {
    const isFull = useAppSelector((state) => state.quiz.isFull);
    const quiz_playlist = useAppSelector((state) => state.quiz.current.playlist);
    const quiz = useAppSelector((state) => state.quiz);
    const Quiz = useQuiz();

    const is_dev_env = isDevEnv();
    const draggable = is_dev_env;

    const doClose = () => {
        Quiz.ExitQuiz();
    };
    const doFullscreen = () => {
        Quiz.DoFullscreen();
    };
    const doExitFullscreen = () => {
        Quiz.DoExitFullscreen();
    };

    return (
        <BottomSheet
            isOpen={quiz_playlist?.length > 0 && !!quiz.current.mode}
            isFull={isFull}
            doFullscreen={doFullscreen}
            doExitFullscreen={doExitFullscreen}
            doClose={doClose}
            handle={<QuizSheetHandle draggable={draggable} />}
            draggable={draggable}
        >
            <Box sx={{ overflow: 'auto' }}>{Quiz.ShownResult() ? <QuizDone /> : <Question />}</Box>
        </BottomSheet>
    );
};

export default QuizSheet;
