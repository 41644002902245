import styled from '@emotion/styled';
import { keyframes } from '@mui/material';
import { HOLOPLAYER_ZINDEX } from '../../constants';

const Rand1 = Math.random();
const Rand2 = Math.random();
const Rand3 = Math.random();
const Rand4 = Math.random();

const PlayingBar = (props: { size: number; selected: boolean; playing: boolean }) => {
    const { size, selected, playing } = props;

    const Pulse = keyframes`
        0% {
            height: 10%;
        }
        50% {
            height: 30%;
        }
        100% {
            height: 10%;
        }
    `;
    const Div = styled.div`
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: ${HOLOPLAYER_ZINDEX.PLAYING_BAR};
        width: ${size}px;
        height: ${size}px;
        display: ${selected ? 'flex' : 'none'};
        justify-content: center;
        align-items: center;
        gap: 2px;
        border-radius: 4px;
    `;
    const Bar = styled.div`
        background: white;
        width: 6%;
        height: 10%;
        content: '';
        animation-name: ${Pulse};
        animation-duration: 0.7s;
        animation-iteration-count: infinite;
        &:nth-of-type(1) {
            animation-delay: ${Rand1}s;
        }
        &:nth-of-type(2) {
            animation-delay: ${Rand2}s;
        }
        &:nth-of-type(3) {
            animation-delay: ${Rand3}s;
        }
        &:nth-of-type(4) {
            animation-delay: ${Rand4}s;
        }
        .paused & {
            animation-iteration-count: 0;
        }
    `;
    return (
        <Div className={playing ? 'playing' : 'paused'}>
            <Bar />
            <Bar />
            <Bar />
            <Bar />
        </Div>
    );
};
export default PlayingBar;
