import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import LocalStorageClient from '../lib/localStorageClient';

export const RepaeatTypeArray = ['all', 'one', 'disabled'] as const;
export type RepeatType = typeof RepaeatTypeArray[number];
export const RepeatLabelArray = ['全曲リピート', '1曲リピート', 'リピートなし'] as const;
export type RepeatLabelType = typeof RepeatLabelArray[number];
export const RepeatTypeAndLabelArray: {
    label: RepeatLabelType;
    value: RepeatType;
}[] = [
    {
        label: '全曲リピート',
        value: 'all',
    },
    {
        label: '1曲リピート',
        value: 'one',
    },
    {
        label: 'リピートなし',
        value: 'disabled',
    },
];

export const BrightnessTypeArray = ['system', 'light', 'dark'] as const;
export type BrightnessType = typeof BrightnessTypeArray[number];

export const SeekSecondsTypeArray = [5, 10, 30] as const;
export type SeekSecondsType = typeof SeekSecondsTypeArray[number];

export type SabiMedleyType = boolean;
export const SabiMedleyLabelArray = ['サビメドレーオン', 'サビメドレーオフ'];
export type SabiMedleyLabelType = typeof SabiMedleyLabelArray[number];
export const SabiMedleyTypeAndLabelArray: {
    label: SabiMedleyLabelType;
    value: SabiMedleyType;
}[] = [
    {
        label: 'サビメドレーオン',
        value: true,
    },
    {
        label: 'サビメドレーオフ',
        value: false,
    },
];

export type PlayerControlsType = boolean;

export type SettingState = {
    isLoaded: boolean;
    brightness: BrightnessType;
    playerHeight: number;
    seekSeconds: SeekSecondsType;
    repeat: RepeatType;
    sabiMedley: SabiMedleyType;
    playerControls: PlayerControlsType;
};

const RepeatLocalStorage = LocalStorageClient.StringUnion<RepeatType>('repeat', [
    ...RepaeatTypeArray,
]);
const BrightnessLocalStorage = LocalStorageClient.StringUnion<BrightnessType>('brightness', [
    ...BrightnessTypeArray,
]);
const SeekSecondsLocalStorage = LocalStorageClient.NumberUnion<SeekSecondsType>('seekSeconds', [
    ...SeekSecondsTypeArray,
]);
const SabiMedleyLocalStorage = LocalStorageClient.Boolean('sabiMedley');
const PlayerControlsLocalStorage = LocalStorageClient.Boolean('playerControls');

const initialState: SettingState = {
    isLoaded: false,
    brightness: BrightnessLocalStorage.load(),
    playerHeight: 211,
    seekSeconds: SeekSecondsLocalStorage.load(),
    repeat: RepeatLocalStorage.load(),
    sabiMedley: SabiMedleyLocalStorage.load(false),
    playerControls: PlayerControlsLocalStorage.load(true),
};

const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        completionLoad: (state, action: PayloadAction) => {
            state.isLoaded = true;
        },
        setBrightness: (state, action: PayloadAction<BrightnessType>) => {
            state.brightness = action.payload;
            BrightnessLocalStorage.set(action.payload);
        },
        setSeekSeconds: (state, action: PayloadAction<SettingState['seekSeconds']>) => {
            state.seekSeconds = action.payload;
            SeekSecondsLocalStorage.set(action.payload);
        },
        setRepeat: (state, action: PayloadAction<SettingState['repeat']>) => {
            state.repeat = action.payload;
            RepeatLocalStorage.set(action.payload);
        },
        setRepeatNext: (state) => {
            state.repeat =
                state.repeat === 'all' ? 'one' : state.repeat === 'one' ? 'disabled' : 'all';

            RepeatLocalStorage.set(state.repeat);
        },
        setSabiMedley: (state, action: PayloadAction<SabiMedleyType>) => {
            state.sabiMedley = action.payload;
            SabiMedleyLocalStorage.set(state.sabiMedley);
        },
        toggleSabiMedley: (state) => {
            state.sabiMedley = !state.sabiMedley;
            SabiMedleyLocalStorage.set(state.sabiMedley);
        },
        setPlayerControls: (state, action: PayloadAction<PlayerControlsType>) => {
            state.playerControls = action.payload;
            PlayerControlsLocalStorage.set(state.playerControls);
        },
    },
});

export const {
    setBrightness,
    completionLoad,
    setSeekSeconds,
    setRepeat,
    setRepeatNext,
    setSabiMedley,
    toggleSabiMedley,
    setPlayerControls,
} = settingSlice.actions;

export default settingSlice.reducer;
