import { logEvent } from 'firebase/analytics';
import { useMemo, useRef } from 'react';
import ReactPlayer from 'react-player';
import { analytics } from '../../../app/firebase';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { doPause, doPlay } from '../../../slice/quiz/quizSlice';
import useQuiz from '../../../slice/quiz/useQuiz';
import useInfo from '../info/useInfo';
import { YOUTUBE_URL } from '../../../constants';

const QuizPlayer = () => {
    const current_song = useAppSelector((state) => state.quiz.current.song);
    const player = useAppSelector((state) => state.quiz.player);
    const playerRef = useRef(null) as any;
    const setting = useAppSelector((state) => state.setting);
    const dispatch = useAppDispatch();
    const Quiz = useQuiz();
    const Info = useInfo();

    const PlayerSeekTo = (
        amount: number,
        type: 'seconds' | 'fraction' = 'fraction',
        no: number,
    ) => {
        return playerRef.current.seekTo(amount, type);
    };

    const finished = Quiz.Finished();

    const video_id = useMemo(() => {
        let id = current_song?.video_id.topic;
        if (!id) {
            id = current_song?.video_id.mv;
        }
        if (finished) {
            const before = Quiz.GetBeforeQuiz();
            if (before) {
                id = before.origin.video_id.topic;
            }
        }
        return id;
    }, [current_song?.video_id.topic, current_song?.video_id.mv, finished, Quiz]);

    return (
        <ReactPlayer
            ref={playerRef}
            url={'https://www.youtube.com/watch?v=' + video_id}
            width="100%"
            height={setting.playerHeight}
            pip={false}
            playing={player.playing}
            controls={true}
            loop={false}
            playbackRate={1}
            volume={player.volume}
            muted={player.muted}
            onReady={() => {
                const start = current_song?.sabi?.start;
                const startPosition = Quiz.startPosition;
                if (start && startPosition === 'sabi') {
                    PlayerSeekTo(start, 'seconds', 0);
                } else if (startPosition === 'random') {
                    PlayerSeekTo(Math.random() * 0.95, 'fraction', 1);
                } else {
                    PlayerSeekTo(0, 'seconds', 2);
                }
                if (Quiz.hadPlayed) {
                    dispatch(doPlay());
                }
            }}
            onError={(e) => {
                let error_code = '';
                if (Number.isInteger(parseInt(e, 10))) {
                    error_code = `(${e})`;
                }
                const title = current_song?.title;
                const message = `「${title}」(ID: ${video_id})の再生時にエラー${error_code}が発生しました。`;

                const err = {
                    video_id,
                    title,
                    message,
                };
                console.error(err);
                logEvent(analytics, 'handleIframePlayerError', err);

                Info.Add({
                    severity: 'error',
                    code: Date.now(),
                    message,
                    open: true,
                    link: {
                        label: 'YouTubeで再生',
                        href: `${YOUTUBE_URL.WATCH_BASE}${video_id}`,
                        target: '_blank',
                    },
                    variant: 'outlined',
                    vertical: 'bottom',
                });
            }}
            onEnded={() => {
                const start = current_song?.sabi?.start;
                if (start) {
                    PlayerSeekTo(start, 'seconds', 0);
                }
                dispatch(doPause());
            }}
            onPause={() => {
                dispatch(doPause());
            }}
        />
    );
};

export default QuizPlayer;
