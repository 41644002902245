import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type InfoType = {
    severity: 'info' | 'success' | 'warning' | 'error';
    code: number;
    message: string;
    open: boolean;

    link?: {
        label: string;
        href: string;
        target: undefined | '_blank';
    };
    variant?: 'filled' | 'outlined' | 'standard';
    autoHideDuration?: number | null;
    vertical?: 'top' | 'bottom';
    horizontal?: 'left' | 'center' | 'right';
    handleClose?: () => void;
};

const initialState: {
    items: InfoType[];
} = {
    items: [],
};

const infoSlice = createSlice({
    name: 'info',
    initialState,
    reducers: {
        addInfo: (state, action: PayloadAction<InfoType>) => {
            state.items = [...state.items, action.payload];
        },
        closeInfo: (state, action: PayloadAction<number>) => {
            const item = state.items.find((_item) => _item.code === action.payload);
            if (!item) return;
            item.open = false;
            state.items = [...state.items, item];
        },
    },
});

export const { addInfo, closeInfo } = infoSlice.actions;

export default infoSlice.reducer;
