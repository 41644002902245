import { Box, useTheme } from '@mui/material';
import { ReactComponent as YubiIcon } from '../../../img/yubi.svg';
import useQuiz from '../../../slice/quiz/useQuiz';

const YubiyubiDisplay = () => {
    const theme = useTheme();
    const Quiz = useQuiz();
    const { incorrect } = Quiz.GetQuizStatus();
    const life = 5 - incorrect.length;

    const size = '100%';
    const fill = theme.palette.info.light;
    const show = 'translateY(0)';
    const hide = 'translateY(-100vh)';
    const transition = theme.transitions.create(['transform'], {
        duration: '2500ms',
    });

    return (
        <Box sx={{ display: 'flex', m: '40px 0' }}>
            <Box sx={{ flexGrow: 1 }}>
                <Box
                    sx={{
                        transform: life >= 1 ? show : hide,
                        transition,
                    }}
                >
                    <YubiIcon width={size} height={size} fill={fill} />
                </Box>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <Box
                    sx={{
                        transform: life >= 2 ? show : hide,
                        transition,
                    }}
                >
                    <YubiIcon width={size} height={size} fill={fill} />
                </Box>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <Box
                    sx={{
                        transform: life >= 3 ? show : hide,
                        transition,
                    }}
                >
                    <YubiIcon width={size} height={size} fill={fill} />
                </Box>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <Box
                    sx={{
                        transform: life >= 4 ? show : hide,
                        transition,
                    }}
                >
                    <YubiIcon width={size} height={size} fill={fill} />
                </Box>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <Box
                    sx={{
                        transform: life >= 5 ? show : hide,
                        transition,
                    }}
                >
                    <YubiIcon width={size} height={size} fill={fill} />
                </Box>
            </Box>
        </Box>
    );
};
export default YubiyubiDisplay;
