import { FixedSizeList } from 'react-window';
import { SongItemType } from '../../../type/hololive.types';
import { forwardRef, useEffect, useState } from 'react';
import SongListItem from './SongItem';
import usePlayer from '../player/usePlayer';
import CreatorFilter, { CreatorFilterType } from './CreatorFilter';
import { Box } from '@mui/material';
import SongItemSkeleton from './SongItemSkeleton';
import NoContent from '../../ui/NoContent';
import { DEFAULT_LOADING_SKELETON_COUNT } from '../../../constants';

const CustomFixedSizeList = ({
    collection,
    height,
    creator_filter,
    lead,
}: {
    collection: 'loading' | SongItemType[];
    height: number;
    creator_filter?: {
        all: number;
        composer: number;
        arranger: number;
        lyricist: number;
        talent: number;
        selected: CreatorFilterType;
        setFilter: (filter: CreatorFilterType) => void;
    };
    lead?: {
        element: JSX.Element;
        height: number;
    };
}) => {
    const Player = usePlayer();
    const creator_filter_height = creator_filter ? 48 : 0;
    const margin_top = 8;
    const margin_bottom = 8;

    const [songs, setSongs] = useState<(null | SongItemType)[]>(
        Array(DEFAULT_LOADING_SKELETON_COUNT).fill(null),
    );

    useEffect(() => {
        if (collection !== 'loading') {
            setSongs(collection);
        }
    }, [collection]);

    const Row = ({ item }: { item?: SongItemType | null }) => {
        if (!item || collection === 'loading') {
            return <SongItemSkeleton />;
        }
        return (
            <SongListItem
                song={item}
                handleClick={() => {
                    Player.HandleClickChangeSongButton({
                        item,
                        collection,
                    });
                }}
            />
        );
    };

    if (lead && songs.length > 0) {
        return (
            <Box sx={{ height: '100%', overflowY: 'auto', paddingBottom: '120px' }}>
                {lead.element}
                {songs.map((song, index) => (
                    <Row key={index} item={song} />
                ))}
            </Box>
        );
    }

    if (songs.length > 0) {
        return (
            <FixedSizeList
                itemData={songs}
                itemCount={songs.length}
                itemSize={61}
                width="100%"
                height={height}
                innerElementType={forwardRef(({ style, ...rest }, ref) => {
                    return (
                        <div
                            style={{
                                ...style,
                                height: `${
                                    parseFloat(style.height) +
                                    margin_top +
                                    margin_bottom +
                                    creator_filter_height
                                }px`,
                            }}
                        >
                            {creator_filter && <CreatorFilter {...creator_filter} />}
                            <div ref={ref} {...rest} />
                        </div>
                    );
                })}
            >
                {({ index, style }) => {
                    return (
                        <div
                            style={{
                                ...style,
                                top: `${
                                    parseInt(String(style.top), 10) +
                                    margin_top +
                                    creator_filter_height
                                }px`,
                                paddingBottom: '120px',
                            }}
                        >
                            <Row item={songs[index]} />
                        </div>
                    );
                }}
            </FixedSizeList>
        );
    }

    return (
        <Box>
            {creator_filter && <CreatorFilter {...creator_filter} />}
            <NoContent />
        </Box>
    );
};

export default CustomFixedSizeList;
