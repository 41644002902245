import React, { useEffect } from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import MusicNoteIcon from '@mui/icons-material/MusicNote';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import SportsEsportsRoundedIcon from '@mui/icons-material/SportsEsportsRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
// import YouTubeIcon from '@mui/icons-material/YouTube';

import {
    BottomNavigationTabArray,
    BottomNavigationTabType,
} from '../../type/buttom-navigation-tab.types';
import { useTheme } from '@mui/material';

const getCurrentValueByPathname = (_v?: any): BottomNavigationTabType => {
    const default_tab = 'song';
    const unexpected = '';

    const pathname = _v ? _v : window.location.pathname;
    const arr = String(pathname).split('/');

    if (arr.length <= 1) return default_tab;

    if (arr[0]) return unexpected;

    if (arr[1]) {
        return BottomNavigationTabArray.find((val) => val === arr[1]) || unexpected;
    }

    return default_tab;
};

const CustomBottomNavigationAction = styled(BottomNavigationAction)`
    & .MuiBottomNavigationAction-label,
    & .Mui-selected.MuiBottomNavigationAction-label {
        font-size: 10px;
    }
`;

const BottomBar = () => {
    const [value, setValue] = React.useState('');
    const navigation = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        setValue(getCurrentValueByPathname());
    }, []);

    return (
        <BottomNavigation
            showLabels={true}
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                navigation(newValue);
            }}
            sx={{
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <CustomBottomNavigationAction
                label="曲"
                value="song"
                icon={<MusicNoteIcon fontSize="small" />}
            />

            <CustomBottomNavigationAction
                label="ライブラリ"
                value="library"
                icon={<LibraryMusicIcon fontSize="small" />}
            />

            {/* <CustomBottomNavigationAction
                label="YouTube"
                value="youtube"
                icon={<YouTubeIcon fontSize="small" />}
            /> */}

            <CustomBottomNavigationAction
                label="クイズ"
                value="quiz"
                icon={<SportsEsportsRoundedIcon fontSize="small" />}
            />

            <CustomBottomNavigationAction
                label="その他"
                value="other"
                icon={<MenuRoundedIcon fontSize="small" />}
            />
        </BottomNavigation>
    );
};

export default BottomBar;
