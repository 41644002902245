import { logEvent } from 'firebase/analytics';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { analytics } from '../../../app/firebase';
import { useAppSelector } from '../../../app/hooks';
import usePlayer from './usePlayer';
import useInfo from '../info/useInfo';
import { YOUTUBE_URL } from '../../../constants';

const CustomPlayer = () => {
    const current_song = useAppSelector((state) => state.song.current.song);
    const playerRef = useRef(null) as any;
    const setting = useAppSelector((state) => state.setting);
    const Info = useInfo();

    const Player = usePlayer();
    const PlayerState = useAppSelector((state) => state.player);

    const playerDebugger = (type: string, e?: any) => {
        if (type === 'Progress') return;
        if ([''].includes(type)) {
            console.log({
                type,
                e,
            });
        }
    };

    const PlayerSeekTo = (
        amount: number,
        type: 'seconds' | 'fraction' = 'fraction',
        no: number,
    ) => {
        playerDebugger('PlayerSeek', {
            amount,
            type,
            no,
        });
        return playerRef.current.seekTo(amount, type);
    };

    const handleReady = (e: any) => {
        playerDebugger('Ready', e);
        if (setting.sabiMedley && current_song?.sabi?.start) {
            PlayerSeekTo(current_song.sabi?.start, 'seconds', 1);
        } else {
            PlayerSeekTo(0, 'seconds', 2);
        }
    };
    const handleStart = (e?: any) => {
        playerDebugger('Start', e);
    };
    const handlePlay = (e?: any) => {
        playerDebugger('Play', e);
        Player.Play();
    };

    const SabiMedley = () => {
        if (!setting.sabiMedley) return;

        const start = current_song?.sabi?.start;
        const end = current_song?.sabi?.end;

        const is_valid_sabi = start && end && start < end;

        if (!is_valid_sabi) {
            playerDebugger('NoSabiDuration', 'サビメドレー中だがサビ指定なし');
            Player.Next();
            return;
        }

        const now = playerRef.current.getCurrentTime();
        const isBeforeSabi = now < start && now > 0;
        const isAfterSabi = end < now;

        if (isBeforeSabi) {
            playerDebugger('BeforeSabi', 'サビ前：サビ直後に移動');
            PlayerSeekTo(start, 'seconds', 3);
            console.log({ now, start });
        } else if (isAfterSabi) {
            if (setting.repeat === 'one') {
                playerDebugger('AfterSabi', 'サビ後/一曲リピート：サビ開始位置にシーク');
                PlayerSeekTo(start, 'seconds', 7);
            } else {
                playerDebugger('AfterSabi', 'サビ後：次の曲にする');
                Player.Next();
            }
        } else {
            playerDebugger('BetweenSabi', 'サビ中');
        }
    };
    const handleProgress = (e: any) => {
        playerDebugger('Progress', e);
        SabiMedley();
        if (!PlayerState.seeking) {
            Player.HandleProgress({ played: e.played, loaded: e.loaded });
        }
    };
    const handleDuration = (seconds: number) => {
        playerDebugger('Duration', seconds);
        Player.HandleChangeDuration(seconds);
    };
    const handlePause = (e?: any) => {
        playerDebugger('Pause', e);
        Player.Pause();
    };
    const handleBuffer = (e?: any) => {
        playerDebugger('Buffer', e);
    };
    const handleBufferEnd = (e?: any) => {
        playerDebugger('BufferEnd', e);
    };
    const handleSeek = (e: any) => {
        playerDebugger('Seek', e);
    };
    const handlePlaybackRateChange = (e: any) => {
        playerDebugger('PlaybackRateChange', e);
        Player.HandleChangePlaybackRate(e);
    };
    const handleEnded = (e?: any) => {
        playerDebugger('Ended', {
            repeat: setting.repeat,
        });
        if (setting.repeat === 'all') {
            Player.Next();
        } else if (setting.repeat === 'one') {
            PlayerSeekTo(0, 'seconds', 6);
        } else if (setting.repeat === 'disabled') {
            Player.Next();
        }
    };

    const handleError = (e: any) => {
        let error_code = '';
        if (Number.isInteger(parseInt(e, 10))) {
            error_code = `(${e})`;
        }
        const video_id = current_song?.video_id.active;
        const title = current_song?.title;
        const message = `「${title}」(ID: ${video_id})の再生時にエラー${error_code}が発生しました。埋め込み再生が許可されていない等、YouTubeでの再生はできる可能性があります。`;

        playerDebugger('Error', e);
        const err = {
            video_id,
            title,
            message,
        };
        console.error(err);
        logEvent(analytics, 'handleIframePlayerError', err);

        Info.Add({
            severity: 'error',
            code: Date.now(),
            message,
            open: true,
            link: {
                label: 'YouTubeで再生',
                href: `${YOUTUBE_URL.WATCH_BASE}${video_id}`,
                target: '_blank',
            },
            variant: 'outlined',
        });

        Player.Pause();
    };

    /**
     * 早送りイベント
     */
    const handleClickForward = useCallback(() => {
        if (!playerRef?.current) return;
        const seekSeconds = setting.seekSeconds;
        const playedSeconds = playerRef.current.getCurrentTime();
        const duration = playerRef.current.getDuration();
        let to: number = playedSeconds + seekSeconds;
        if (duration > 0 && to >= duration) {
            Player.Next();
            to = 0;
        }
        PlayerSeekTo(to, 'seconds', 7);
        playerDebugger('ClickForward', '早送り');

        // PlayerNext と PlayerSeekToを書けと怒られるから消す
        // eslint-disable-next-line
    }, [playerRef, setting.seekSeconds]);
    useEffect(() => {
        Player.ListenSeekForwardEvent(handleClickForward);
        // PlayerListenSeekForwardEvent と handleClickForwardを書けと怒られるから消す
        // eslint-disable-next-line
    }, []);

    /**
     * 巻き戻しイベント
     */
    const handleClickBackward = useCallback(() => {
        if (!playerRef?.current) return;
        const seekSeconds = setting.seekSeconds;
        const playedSeconds = playerRef.current.getCurrentTime();
        let to = playedSeconds - seekSeconds;
        if (to < 0) {
            to = 0;
        }
        PlayerSeekTo(to, 'seconds', 8);
        playerDebugger('ClickBackward', '巻き戻し');

        // PlayerNext と PlayerSeekToを書けと怒られるから消す
        // eslint-disable-next-line
    }, [playerRef, setting.seekSeconds]);
    useEffect(() => {
        Player.ListenSeekBackwardEvent(handleClickBackward);
        // PlayerListenSeekForwardEvent と handleClickForwardを書けと怒られるから消す
        // eslint-disable-next-line
    }, []);

    /**
     *  シーク操作が終わったら動画のシークを実行する
     */
    useEffect(() => {
        if (!PlayerState.seeking) {
            PlayerSeekTo(PlayerState.played, 'fraction', 10);
        }
        // シークバーからフォーカスが外れた時に再生秒数をプレイヤーにフィードバックする
        // player.playedも関係してるじゃないかと怒られるが
        // onProgressイベント経由での発火を防ぐため、「指定しない必要」があるので握りつぶす
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PlayerState.seeking]);

    /**
     * コントローラ有無が変更があった場合、iframeをreloadする必要があるため
     * keyを適当な値に変更してあげる
     */
    const [rand, setRand] = useState<number>(0);
    useEffect(() => {
        setRand(Math.random());
    }, [setting.playerControls]);

    return (
        <ReactPlayer
            key={rand}
            ref={playerRef}
            url={'https://www.youtube.com/watch?v=' + current_song?.video_id.active}
            width="100%"
            height={setting.playerHeight}
            pip={PlayerState.pip}
            playing={PlayerState.playing}
            controls={setting.playerControls}
            loop={PlayerState.loop}
            playbackRate={PlayerState.playbackRate}
            volume={PlayerState.volume}
            muted={PlayerState.muted}
            onReady={handleReady}
            onStart={handleStart}
            onPlay={handlePlay}
            onProgress={handleProgress}
            onDuration={handleDuration}
            onPause={handlePause}
            onBuffer={handleBuffer}
            onBufferEnd={handleBufferEnd}
            onSeek={handleSeek}
            onPlaybackRateChange={handlePlaybackRateChange}
            onEnded={handleEnded}
            onError={handleError}
        />
    );
};

export default CustomPlayer;
