import { Box } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import CustomSnackbar from './CustomSnackbar';
import useInfo from './useInfo';

const SnackbarStation = () => {
    const infoState = useAppSelector((state) => state.info);
    const infoCollection = infoState.items;
    const Info = useInfo();

    if (!infoCollection) return <Box />;

    return (
        <Box>
            {infoCollection.map((info, index) => {
                return (
                    <CustomSnackbar
                        key={index}
                        message={info.message}
                        severity={info.severity}
                        open={info.open}
                        handleClose={() => {
                            Info.Close(info.code);
                        }}
                        link={info.link}
                        code={info.code}
                        vertical={info.vertical}
                        horizontal={info.horizontal}
                    />
                );
            })}
        </Box>
    );
};

export default SnackbarStation;
