const pad = (string: number) => {
    return ('0' + string).slice(-2);
};
const format = (seconds: number) => {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = pad(date.getUTCSeconds());
    if (hh) {
        return `${hh}:${pad(mm)}:${ss}`;
    }
    return `${mm}:${ss}`;
};

const Duration = ({ seconds }: { seconds: any }) => {
    return <time dateTime={`P${Math.round(seconds)}S`}>{format(seconds)}</time>;
};

export default Duration;
