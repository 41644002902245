import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchAllGeneration, fetchAllSongFilterByGeneration } from '../../lib/fetch';
import { webWorkerAsync } from '../../lib/webWorker';
import { SongItemType, GenerationItemType } from '../../type/hololive.types';
import {
    startLoadGeneration,
    completeLoadGeneration,
    startComputeSongCount,
    completecComputeSongCount,
    startFetchPeronSong,
    completeFetchGenerationSong,
} from './generationSlice';

const task = (
    message: MessageEvent<{
        generations: GenerationItemType[];
        songs: SongItemType[];
    }>,
): GenerationItemType[] => {
    const generations = message.data.generations;
    const songs = message.data.songs;
    return generations.map((generation: any) => ({
        ...generation,
        count: songs.filter((song) => {
            return song.artist?.id === generation.id;
        }).length,
    }));
};

const useGeneration = () => {
    const { isLoaded, items, computeStatus, loadStatus } = useAppSelector(
        (state) => state.generation,
    );
    const SongState = useAppSelector((state) => state.song);
    const dispatch = useAppDispatch();

    const fetchGeneration = () => {
        if (loadStatus === 'ready') {
            dispatch(startLoadGeneration());
            fetchAllGeneration().then((res) => {
                if (res) {
                    dispatch(completeLoadGeneration(res));
                }
            });
        }
    };

    const computeSongCount = () => {
        if (!isLoaded) return;
        if (!SongState.isLoaded) return;
        if (computeStatus === 'ready') {
            dispatch(startComputeSongCount());
            webWorkerAsync<
                {
                    generations: GenerationItemType[];
                    songs: SongItemType[];
                },
                GenerationItemType[]
            >(task, { generations: items, songs: SongState.items }).then((done) => {
                if (done.length > 0) {
                    dispatch(completecComputeSongCount(done));
                }
            });
        }
    };

    const fetchGenerationSong = (generation: GenerationItemType) => {
        if (generation?.songs?.status === 'ready') {
            dispatch(startFetchPeronSong(generation.id));

            fetchAllSongFilterByGeneration(generation.id).then((songs) => {
                dispatch(
                    completeFetchGenerationSong({
                        generation,
                        songs: {
                            all: songs,
                            status: 'loaded',
                        },
                    }),
                );
            });
        }
    };

    return {
        loadStatus,
        isLoaded,
        items,
        fetchGeneration,
        computeSongCount,
        fetchGenerationSong,
    };
};
export default useGeneration;
