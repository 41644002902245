import React, { MouseEvent, useState } from 'react';
import { Divider, IconButton, Menu, MenuItem, ListItemIcon } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { SearchOrderByType, SearchOrderType, SearchSortArray } from '../../../slice/searchSlice';

const SortMenu = ({
    size = 40,
    order,
    orderBy,
    handleChangeSort,
}: {
    size?: number;
    order: SearchOrderType;
    orderBy: SearchOrderByType;
    handleChangeSort: (order: SearchOrderType, orderBy: SearchOrderByType) => void;
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    return (
        <>
            <IconButton
                sx={{ width: size, height: size }}
                onClick={(e: MouseEvent<HTMLElement>) => {
                    setAnchorEl(e.currentTarget);
                }}
            >
                <SwapVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
                {SearchSortArray.map((item, index) => {
                    if (item.order === 'shuffle') {
                        return null;
                    } else {
                        return (
                            <MenuItem
                                key={index}
                                selected={order === item.order && orderBy === item.orderBy}
                                onClick={(e: MouseEvent<HTMLElement>) => {
                                    setAnchorEl(null);
                                    handleChangeSort(item.order, item.orderBy);
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        opacity:
                                            order === item.order && orderBy === item.orderBy
                                                ? 1
                                                : 0.1,
                                    }}
                                >
                                    <CheckRoundedIcon
                                        color={
                                            order === item.order && orderBy === item.orderBy
                                                ? 'primary'
                                                : 'inherit'
                                        }
                                    />
                                </ListItemIcon>
                                {item.label}
                            </MenuItem>
                        );
                    }
                })}
                <Divider />
                <MenuItem
                    // selected={}
                    onClick={(e: MouseEvent<HTMLElement>) => {
                        setAnchorEl(null);
                        handleChangeSort('shuffle', null);
                    }}
                >
                    シャッフル
                </MenuItem>
            </Menu>
        </>
    );
};

export default SortMenu;
