import React from 'react';
import { HOLOPLAYER_PATH } from './route';

import SongCollection from '../component/page/SongCollectionTab';
import Library from '../component/page/Library';
import Quize from '../component/page/Quiz';
import Account from '../component/page/Account';
import Other from '../component/page/Other';
import Setting from '../component/page/Setting';
import Latest30SongsPage from '../component/page/Latest30SongsPage';
import In30DaysSongsPage from '../component/page/In30DaysSongPage';
import About from '../component/page/About';
import Aenbien from '../component/feature/quiz/Aenbien';
import Yubiyubi from '../component/feature/quiz/Yubiyubi';
import Zenkoutei from '../component/feature/quiz/Zenkoutei';
import TalentCollectionPage from '../component/page/talent/TalentCollectionPage';
import TalentSongPage from '../component/page/talent/TalentSongPage';
import PersonCollectionPage from '../component/page/person/PersonCollection';
import PersonSongPage from '../component/page/person/PersonSongPage';
import AlbumCollectionPage from '../component/page/album/AlbumCollection';
import AlbumSongPage from '../component/page/album/AlbumSongPage';
import UnitCollectionPage from '../component/page/unit/UnitCollection';
import UnitSongPage from '../component/page/unit/UnitSongPage';
import YouTube from '../component/page/youtube';
import DataPage from '../component/page/Data';
import GenerationCollectionPage from '../component/page/generation/GenerationCollection';
import GenerationSongPage from '../component/page/generation/GenerationSongPage';

export const routes = [
    {
        path: HOLOPLAYER_PATH.HOME,
        element: <SongCollection />,
    },
    {
        path: HOLOPLAYER_PATH.SONG,
        element: <SongCollection />,
    },
    {
        path: HOLOPLAYER_PATH.LIBRARY,
        element: <Library />,
    },
    {
        path: HOLOPLAYER_PATH.LATEST_30_SONGS,
        element: <Latest30SongsPage />,
    },
    {
        path: HOLOPLAYER_PATH.IN_30_DAYS_SONGS,
        element: <In30DaysSongsPage />,
    },
    {
        path: HOLOPLAYER_PATH.QUIZ,
        element: <Quize />,
    },
    {
        path: HOLOPLAYER_PATH.OTHER,
        element: <Other />,
    },
    {
        path: HOLOPLAYER_PATH.ACCOUNT,
        element: <Account />,
    },
    {
        path: HOLOPLAYER_PATH.SETTING,
        element: <Setting />,
    },
    {
        path: HOLOPLAYER_PATH.ABOUT,
        element: <About />,
    },
    {
        path: HOLOPLAYER_PATH.QUIZ_AENBIEN,
        element: <Aenbien />,
    },
    {
        path: HOLOPLAYER_PATH.QUIZ_YUBIYUBI,
        element: <Yubiyubi />,
    },
    {
        path: HOLOPLAYER_PATH.QUIZ_ZENKOUTEI,
        element: <Zenkoutei />,
    },
    {
        path: HOLOPLAYER_PATH.LIBRARY_TALENT_COLLECTION,
        element: <TalentCollectionPage />,
    },
    {
        path: HOLOPLAYER_PATH.LIBRARY_TALENT_ITEM,
        element: <TalentSongPage />,
    },
    {
        path: HOLOPLAYER_PATH.LIBRARY_PERSON_COLLECTION,
        element: <PersonCollectionPage />,
    },
    {
        path: HOLOPLAYER_PATH.LIBRARY_PERSON_ITEM,
        element: <PersonSongPage />,
    },
    {
        path: HOLOPLAYER_PATH.LIBRARY_ALBUM_COLLECTION,
        element: <AlbumCollectionPage />,
    },
    {
        path: HOLOPLAYER_PATH.LIBRARY_ALBUM_ITEM,
        element: <AlbumSongPage />,
    },
    {
        path: HOLOPLAYER_PATH.LIBRARY_UNIT_COLLECTION,
        element: <UnitCollectionPage />,
    },
    {
        path: HOLOPLAYER_PATH.LIBRARY_UNIT_ITEM,
        element: <UnitSongPage />,
    },
    {
        path: HOLOPLAYER_PATH.LIBRARY_GENERATION_COLLECTION,
        element: <GenerationCollectionPage />,
    },
    {
        path: HOLOPLAYER_PATH.LIBRARY_GENERATION_ITEM,
        element: <GenerationSongPage />,
    },
    {
        path: HOLOPLAYER_PATH.YOUTUBE,
        element: <YouTube />,
    },
    {
        path: HOLOPLAYER_PATH.LIBRARY_DATA,
        element: <DataPage />,
    },
];
