import { Box, FormControl, Typography, useTheme, InputLabel, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useAppSelector } from '../../../app/hooks';
import QuizSheet from './QuizSheet';
import NavigationBar from '../../ui/NavigationBar/NavigationBar';
import { QUIZ_SENTENCES } from '../../../constants';
import { HOLOPLAYER_PATH } from '../../../route/route';
import useQuiz from '../../../slice/quiz/useQuiz';
import React, { useEffect } from 'react';
import { QuizStartPositionTypeArray } from '../../../slice/quiz/quizSlice';
import { ReactComponent as SkullIcon } from '../../../img/skull.svg';
import CustomButton from '../../ui/CustomButton';
import useTalent from '../../../slice/talent/useTalent';

const Aenbien = () => {
    const theme = useTheme();
    const errors = useAppSelector((state) => state.quiz.errors);
    const Quiz = useQuiz();
    const Talent = useTalent();

    useEffect(() => {
        Talent.fetchTalent();
    }, [Talent]);

    useEffect(() => {
        Quiz.readyQuiz();
    }, [Quiz]);

    const SelectBox = () => {
        return (
            <FormControl fullWidth size="small">
                <InputLabel>再生開始位置</InputLabel>
                <Select
                    value={Quiz.startPosition}
                    label="再生開始位置"
                    onChange={(e: SelectChangeEvent) =>
                        Quiz.SetStartPosition((e.target as HTMLInputElement).value)
                    }
                >
                    {QuizStartPositionTypeArray.map((item, index) => {
                        return (
                            <MenuItem key={index} value={item.value}>
                                {item.label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    };
    return (
        <Box>
            <NavigationBar
                title={QUIZ_SENTENCES.SELECT.NAVIGATION.AENBIEN}
                to={HOLOPLAYER_PATH.QUIZ}
            />
            <Box sx={{ m: '32px 0', textAlign: 'center' }}>
                <SkullIcon width={48} height={48} fill={theme.palette.text.primary} />
                <Typography variant="h6">{QUIZ_SENTENCES.SELECT.H1.AENBIEN}</Typography>
                <Typography variant="body2" color={theme.palette.text.secondary}>
                    {QUIZ_SENTENCES.SELECT.DESCRIPSION.AENBIEN}
                </Typography>
            </Box>
            <Box sx={{ margin: 2, textAlign: 'center' }}>
                <Box sx={{ m: 0, display: 'flex', gap: 2, alignItems: 'flex-end' }}>
                    <SelectBox />
                    <CustomButton
                        fullWidth
                        variant="contained"
                        startIcon={<PlayArrowIcon />}
                        size="large"
                        onClick={() => {
                            Quiz.StartAenbienQuiz();
                        }}
                        loading={!Quiz.isReadyQuiz()}
                    >
                        スタート
                    </CustomButton>
                </Box>
                {errors.length > 0 &&
                    errors.map((error, index) => (
                        <Typography key={index} sx={{ m: 2 }}>
                            {error.msg}
                        </Typography>
                    ))}
            </Box>

            <QuizSheet />
        </Box>
    );
};
export default Aenbien;
