import { Box, Chip, List } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import { HOLOPLAYER_PATH } from '../../../route/route';
import NavigationBar from '../../ui/NavigationBar/NavigationBar';
import { useEffect, useMemo, useState } from 'react';
import { hiraganaToKatakana, katakanaToHiragana } from '../../../lib/misc';
import TalentCollectionListItem from './TalentCollectionListItem';
import useTalent from '../../../slice/talent/useTalent';
import useGeneration from '../../../slice/generation/useGeneration';
import NoContent from '../../ui/NoContent';
import { DEFAULT_LOADING_SKELETON_COUNT } from '../../../constants';

const TalentCollectionPage = () => {
    const Talent = useTalent();
    const Generation = useGeneration();
    const songState = useAppSelector((state) => state.song);
    const [selectedGen, setSelectedGen] = useState<number | 'all'>('all');
    const [countDisplay, setCountDisplay] = useState<string>('');

    useEffect(() => {
        Talent.fetchTalent();
    }, [Talent]);

    useEffect(() => {
        Generation.fetchGeneration();
    }, [Generation]);

    useEffect(() => {
        Talent.computeSongCount();
    }, [Talent, songState.isLoaded]);

    const [query, setQuery] = useState<string>('');

    const talents = useMemo(() => {
        let returnArray = Talent.items;
        if (selectedGen !== 'all') {
            returnArray = returnArray.filter((talent) =>
                talent.generation.some((t) => t.id === selectedGen),
            );
        }
        if (query) {
            const hiragana = katakanaToHiragana(query);
            const katakana = hiraganaToKatakana(query);
            returnArray = returnArray.filter((talent) => {
                return new RegExp(`(${hiragana}|${katakana})`, 'igms').test(talent.search);
            });
        }

        return returnArray;
    }, [query, Talent, selectedGen]);

    useEffect(() => {
        if (Talent.isLoaded) {
            setCountDisplay(` ( ${talents.length} )`);
        } else {
            setCountDisplay('');
        }
    }, [Talent.isLoaded, talents.length]);

    return (
        <Box>
            <NavigationBar
                title={`タレント${countDisplay}`}
                to={HOLOPLAYER_PATH.LIBRARY}
                query={query}
                setQuery={setQuery}
                placeholder="兎田、うさだ、ウサダ、usada..."
            />
            <Box
                sx={{
                    m: '16px 0 8px',
                    p: '0 16px',
                    display: 'flex',
                    gap: 1,
                    width: '100%',
                    overflowY: 'auto',
                }}
            >
                <Chip
                    size="small"
                    label="すべて"
                    variant={selectedGen === 'all' ? 'filled' : 'outlined'}
                    onClick={() => setSelectedGen('all')}
                />
                {Generation.isLoaded &&
                    Generation.items.map((generation) => (
                        <Chip
                            key={generation.id}
                            size="small"
                            label={generation.title}
                            variant={selectedGen === generation.id ? 'filled' : 'outlined'}
                            onClick={() => setSelectedGen(generation.id)}
                        />
                    ))}
            </Box>
            <List>
                {talents.length > 0 ? (
                    talents.map((talent, index) => {
                        return <TalentCollectionListItem key={index} talent={talent} />;
                    })
                ) : Talent.isLoaded ? (
                    <NoContent />
                ) : (
                    Array(DEFAULT_LOADING_SKELETON_COUNT)
                        .fill(null)
                        .map((talent, index) => {
                            return <TalentCollectionListItem key={index} talent={'loading'} />;
                        })
                )}
            </List>
        </Box>
    );
};

export default TalentCollectionPage;
