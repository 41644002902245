import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SongItemType, TalentItemType } from '../type/hololive.types';

export type SongState = {
    isLoaded: boolean;
    hasError: boolean;
    items: SongItemType[];
    talents: TalentItemType[];
    current: {
        song: SongItemType | undefined;
        playlist: SongItemType[];
    };
};

const initialState: SongState = {
    isLoaded: false,
    hasError: false,
    items: [],
    talents: [],
    current: {
        song: undefined,
        playlist: [],
    },
};

const songSlice = createSlice({
    name: 'song',
    initialState,
    reducers: {
        loadAllSong: (state, action: PayloadAction<SongItemType[]>) => {
            state.isLoaded = true;
            state.items = [...action.payload];
            state.current.song = state.items[0];
            state.current.playlist = [...action.payload];
        },
        loadedSpreadSheet: (
            state,
            action: PayloadAction<{ songs: SongItemType[]; talents: TalentItemType[] }>,
        ) => {
            state.isLoaded = true;
            state.items = [...action.payload.songs];
            state.current.song = state.items[0];
            state.current.playlist = [...action.payload.songs];
            state.talents = [...action.payload.talents];
        },
        loadedError: (state) => {
            state.isLoaded = true;
            state.hasError = true;
        },
        changeSong: (
            state,
            action: PayloadAction<{ item: SongItemType; collection: SongItemType[] }>,
        ) => {
            state.current.song = action.payload.item;
            state.current.playlist = [...action.payload.collection];
            // state.player.playing = true;
            // state.player.played = 0;
            // state.player.loaded = 0;
            // state.player.duration = 0;
        },
        changeSrc: (state, action: PayloadAction<'mv' | 'topic'>) => {
            const video_id = state.current.song?.video_id?.[action.payload];
            if (state.current.song && video_id) {
                state.current.song.video_id.active = video_id;
            }
        },
    },
});

export const { loadAllSong, loadedSpreadSheet, changeSong, changeSrc, loadedError } =
    songSlice.actions;

export default songSlice.reducer;
