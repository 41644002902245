import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { HOLOPLAYER_PATH } from '../../../route/route';
import SongCollection from '../../feature/collection/SongCollection';
import { Box } from '@mui/material';
import NavigationBar from '../../ui/NavigationBar/NavigationBar';
import { TalentItemType } from '../../../type/hololive.types';
import useTalent from '../../../slice/talent/useTalent';
import NoContent from '../../ui/NoContent';

const NotFoundTalent = () => {
    return (
        <Box>
            <NavigationBar title="タレントの曲" to={HOLOPLAYER_PATH.LIBRARY_TALENT_COLLECTION} />
            <NoContent />
        </Box>
    );
};

const TalentSongPage = () => {
    const Talent = useTalent();

    const params = useParams();
    const { pathname } = useLocation();

    useEffect(() => {
        Talent.fetchTalent();
    }, [Talent]);

    const currentTalent = useMemo<'loading' | TalentItemType | 'notFound'>(() => {
        if (Talent.isLoaded) {
            if (!params.id) return 'notFound';
            const currentTalentId = parseInt(params.id, 10);
            const talent = Talent.items.find((item) => item.id === currentTalentId);
            if (talent) {
                return talent;
            }
        } else {
            return 'loading';
        }

        return 'notFound';
    }, [Talent.isLoaded, Talent.items, params.id]);

    useEffect(() => {
        if (currentTalent !== 'loading' && currentTalent !== 'notFound') {
            Talent.fetchTalentSong(currentTalent);
        }
    }, [currentTalent, Talent]);

    if (currentTalent === 'notFound') return <NotFoundTalent />;

    if (
        currentTalent === 'loading' ||
        currentTalent?.songs?.status === 'ready' ||
        currentTalent?.songs?.status === 'loading'
    ) {
        return (
            <SongCollection
                collection={'loading'}
                roleCollection={{
                    composerCollection: 'loading',
                    arrangerCollection: 'loading',
                    lyricistCollection: 'loading',
                    talentCollection: 'loading',
                }}
                navigation={{
                    title: '',
                    to: HOLOPLAYER_PATH.LIBRARY_TALENT_COLLECTION,
                }}
                searchId={pathname}
            />
        );
    }

    return (
        <SongCollection
            collection={currentTalent?.songs?.all || []}
            roleCollection={{
                composerCollection: currentTalent?.songs?.composer,
                arrangerCollection: currentTalent?.songs?.arranger,
                lyricistCollection: currentTalent?.songs?.lyricist,
                talentCollection: currentTalent?.songs?.talent,
            }}
            navigation={{
                title: currentTalent.jp_formal,
                to: HOLOPLAYER_PATH.LIBRARY_TALENT_COLLECTION,
            }}
            searchId={pathname}
        />
    );
};

export default TalentSongPage;
