import { Box, Chip } from '@mui/material';

export type CreatorFilterType = 'all' | 'composer' | 'arranger' | 'lyricist' | 'talent';

const displayCounter = (label: string, count: number): string => {
    if (count > 0) {
        return `${label} : ${count}`;
    }
    return label;
};

const CreatorFilter = ({
    all,
    composer,
    arranger,
    lyricist,
    talent,
    selected,
    setFilter,
}: {
    all: number;
    composer: number;
    arranger: number;
    lyricist: number;
    talent: number;
    selected: CreatorFilterType;
    setFilter: (selected: CreatorFilterType) => void;
}) => {
    return (
        <Box sx={{ m: '16px 16px 8px', display: 'flex', gap: 1 }}>
            <Chip
                size="small"
                label={displayCounter('すべて', all)}
                disabled={!(all > 0)}
                variant={selected === 'all' ? 'filled' : 'outlined'}
                onClick={() => setFilter('all')}
            />
            <Chip
                size="small"
                label={displayCounter('歌唱', talent)}
                disabled={!(talent > 0)}
                variant={selected === 'talent' ? 'filled' : 'outlined'}
                onClick={() => setFilter('talent')}
            />
            <Chip
                size="small"
                label={displayCounter('作曲', composer)}
                disabled={!(composer > 0)}
                variant={selected === 'composer' ? 'filled' : 'outlined'}
                onClick={() => setFilter('composer')}
            />
            <Chip
                size="small"
                label={displayCounter('編曲', arranger)}
                disabled={!(arranger > 0)}
                variant={selected === 'arranger' ? 'filled' : 'outlined'}
                onClick={() => setFilter('arranger')}
            />
            <Chip
                size="small"
                label={displayCounter('作詞', lyricist)}
                disabled={!(lyricist > 0)}
                variant={selected === 'lyricist' ? 'filled' : 'outlined'}
                onClick={() => setFilter('lyricist')}
            />
        </Box>
    );
};
export default CreatorFilter;
