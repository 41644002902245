import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchAllSongFilterByUnit, fetchAllUnit } from '../../lib/fetch';
import { webWorkerAsync } from '../../lib/webWorker';
import { SongItemType, UnitItemType } from '../../type/hololive.types';
import {
    startLoadUnit,
    completeLoadUnit,
    startComputeSongCount,
    completecComputeSongCount,
    startFetchPeronSong,
    completeFetchUnitSong,
} from './unitSlice';

const task = (
    message: MessageEvent<{
        units: UnitItemType[];
        songs: SongItemType[];
    }>,
): UnitItemType[] => {
    const units = message.data.units;
    const songs = message.data.songs;
    return units
        .map((unit: any) => ({
            ...unit,
            count:
                songs.filter((song: any) => {
                    return song.artist.id === unit.id;
                }).length || 0,
        }))
        .sort((a: any, b: any) => {
            if (a.count < b.count) return 1;
            if (a.count > b.count) return -1;
            return 0;
        });
};

const useUnit = () => {
    const { isLoaded, items, computeStatus, loadStatus } = useAppSelector((state) => state.unit);
    const SongState = useAppSelector((state) => state.song);
    const dispatch = useAppDispatch();

    const fetchUnit = () => {
        if (loadStatus === 'ready') {
            dispatch(startLoadUnit());
            fetchAllUnit().then((res) => {
                if (res) {
                    dispatch(completeLoadUnit(res));
                }
            });
        }
    };

    const computeSongCount = () => {
        if (!isLoaded) return;
        if (!SongState.isLoaded) return;
        if (computeStatus === 'ready') {
            dispatch(startComputeSongCount());
            webWorkerAsync<
                {
                    units: UnitItemType[];
                    songs: SongItemType[];
                },
                UnitItemType[]
            >(task, { units: items, songs: SongState.items }).then((done) => {
                if (done.length > 0) {
                    dispatch(completecComputeSongCount(done));
                }
            });
        }
    };

    const fetchUnitSong = (unit: UnitItemType) => {
        if (unit?.songs?.status === 'ready') {
            dispatch(startFetchPeronSong(unit.id));

            fetchAllSongFilterByUnit(unit.id).then((songs) => {
                dispatch(
                    completeFetchUnitSong({
                        unit,
                        songs: {
                            all: songs,
                            status: 'loaded',
                        },
                    }),
                );
            });
        }
    };

    return {
        loadStatus,
        isLoaded,
        items,
        fetchUnit,
        computeSongCount,
        fetchUnitSong,
    };
};
export default useUnit;
