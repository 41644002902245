import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import DefaultSheetTab from '../component/feature/songSheet/tab/DefaultSheetTab';
import LyricsTab from '../component/feature/songSheet/tab/LyricsTab';
import PlayListTab from '../component/feature/songSheet/tab/PlayListTab';

export type SheetTabType = 'controll' | 'lyrics' | 'playlist';
export type SheetTabLabelType = '再生' | '歌詞' | '再生リスト';

export const tabs_map: {
    label: SheetTabLabelType;
    value: SheetTabType;
    component: () => JSX.Element;
}[] = [
    {
        label: '歌詞',
        value: 'lyrics',
        component: LyricsTab,
    },
    {
        label: '再生',
        value: 'controll',
        component: DefaultSheetTab,
    },
    {
        label: '再生リスト',
        value: 'playlist',
        component: PlayListTab,
    },
];

export type SheetStateType = {
    isOpen: boolean;
    isFull: boolean;
    tab: SheetTabType;
};

const initialState: SheetStateType = {
    isOpen: false,
    isFull: false,
    tab: 'controll',
};

const sheetSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        changeSheetTab: (state, action: PayloadAction<SheetTabType>) => {
            state.tab = action.payload;
        },
        openSheet: (state, action: PayloadAction) => {
            state.isOpen = true;
        },
        closeSheet: (state, action: PayloadAction) => {
            state.isOpen = false;
        },
        toggleOpen: (state, action: PayloadAction) => {
            state.isOpen = !state.isOpen;
        },
        doFullscreen: (state, action: PayloadAction) => {
            state.isFull = true;
        },
        exitFullscreen: (state, action: PayloadAction) => {
            state.isFull = false;
        },
    },
});

export const { toggleOpen, closeSheet, openSheet, changeSheetTab, doFullscreen, exitFullscreen } =
    sheetSlice.actions;

export default sheetSlice.reducer;
