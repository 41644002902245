import { Swiper, SwiperSlide } from 'swiper/react';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useEffect, useMemo, useState } from 'react';
import { isTouchDevice } from '../../../lib/misc';
import { changeSheetTab, tabs_map } from '../../../slice/sheetSlice';

const SongSheetBody = () => {
    const dispatch = useAppDispatch();
    const [swiper, setSwiper] = useState<any>(null);
    const isUseSwiper = useMemo(() => isTouchDevice(), []);
    const sheet = useAppSelector((state) => state.sheet);

    useEffect(() => {
        tabs_map.forEach((tab, index) => {
            if (tab.value === sheet.tab) {
                swiper?.slideTo(index);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sheet.tab]);

    return (
        <Box
            sx={[
                {
                    flexGrow: 1,
                    width: '100%',
                    overflowY: 'auto',
                },
                {
                    '& .swiper': {
                        height: '100%',
                    },
                },
                {
                    '& .swiper-slide': {
                        height: '100%',
                        overflowY: 'auto',
                    },
                },
            ]}
        >
            <Swiper
                onSwiper={(swiper) => {
                    setSwiper(swiper);
                    tabs_map.forEach((tab, index) => {
                        if (tab.value === sheet.tab) {
                            swiper?.slideTo(index);
                        }
                    });
                }}
                onSlideChange={({ activeIndex }) => {
                    const target = tabs_map?.[activeIndex].value;
                    dispatch(changeSheetTab(target));
                }}
                spaceBetween={0}
                slidesPerView={1}
                allowTouchMove={isUseSwiper}
                speed={250}
            >
                {tabs_map.map((tab, index) => (
                    <SwiperSlide key={index}>{tab.component}</SwiperSlide>
                ))}
            </Swiper>
        </Box>
    );
};
export default SongSheetBody;
