import { Box, Button } from '@mui/material';
import Handle from '../../../ui/BottomSheet/Handle';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const FilterSheetHandle = (props: {
    doFullscreen: () => void;
    doExitFullscreen: () => void;
    doClose: () => void;
    isFull: boolean;
    draggable: boolean;
}) => {
    const { doClose, draggable } = props;
    return (
        <Handle draggable={draggable}>
            <Box
                sx={[
                    {
                        width: '100px',
                        flexShrink: 0,
                        justifyContent: 'start',
                    },
                ]}
            >
                {false && (
                    <Button
                        sx={[
                            {
                                padding: 0,
                            },
                            {
                                '.MuiButton-startIcon': {
                                    margin: 0,
                                },
                            },
                        ]}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={doClose}
                    >
                        キャンセル
                    </Button>
                )}
            </Box>
            <Box
                sx={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    flexGrow: 1,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    textAlign: 'center',
                }}
            >
                フィルタ
            </Box>
            <Box
                sx={[
                    {
                        width: '100px',
                        flexShrink: 0,
                        padding: 0,
                        textAlign: 'right',
                        justifyContent: 'end',
                    },
                ]}
            >
                <Button onClick={doClose}>完了</Button>
            </Box>
        </Handle>
    );
};

export default FilterSheetHandle;
